import { ConcealingTechniqueSection } from "./ConcealingTechniqueSection";
import { WhatToUse } from "./WhatToUse";

export const ConcealingTechniqueSectionWrapper = () => {
  return (
    <>
      <WhatToUse />
      <ConcealingTechniqueSection />
    </>
  );
};
