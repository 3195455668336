import { gql } from "graphql-request";

export const LIST_PRODUCTS_FOR_PURCHASE_QUERY = gql`
  query listProductsForPurchase(
    $email: AWSEmail
    $purchasable: Boolean
    $code: String
    $turnstileToken: String!
  ) {
    listProductsForPurchase(
      email: $email
      purchasable: $purchasable
      code: $code
      turnstileToken: $turnstileToken
    ) {
      id
      productId
      amount
      strikeThrough
      currency
      countryCodes
      startDate
      endDate
      product {
        id
        entitlements {
          name
        }
        localizations {
          id
          name
          description
          languageCode
          version
        }
      }
      version
    }
  }
`;
