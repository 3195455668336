import { useState } from "react";
import { useCookies } from "react-cookie";
import type { FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import {
  authenticationErrorMessageMap,
  AuthenticationErrors,
  useAuthentication,
} from "@lib/authentication";
import useAnalytics from "@lib/hooks/useAnalytics";
import { doQueryWithApiKey } from "@lib/query";
import { AnalyticsEvent } from "@root/constants";

import { VERIFY_USER_EMAIL } from "./LoginForm";

export const useLoginForm = (onSuccess: () => void) => {
  const navigate = useNavigate();
  const { signIn, resetPassword } = useAuthentication();
  const [, setCookie] = useCookies(["rememberMe"]);
  const [errors, setErrors] = useState<Record<string, any>>({});
  const { t } = useTranslation();
  const { analytics } = useAnalytics();
  const params = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const login = async ({ email, password, rememberMe }: FieldValues) => {
    try {
      setIsSubmitting(true);
      setCookie("rememberMe", rememberMe ? "true" : "false");
      await signIn({ email: email, password });
      return onSuccess();
    } catch (error) {
      const errorEventProps = { ...params, error };

      if (error instanceof Error) {
        if (
          error.message === AuthenticationErrors.INVALID_CREDENTIALS ||
          error.message === AuthenticationErrors.LOCKED_ACCOUNT
        ) {
          const errorMessage = authenticationErrorMessageMap[error.message];
          return setErrors((prev) => ({
            ...prev,
            email: {
              type: "custom",
              message: t(errorMessage),
            },
          }));
        }
        errorEventProps.error = error.message;
      }

      analytics.track({
        event: AnalyticsEvent.AUTH_ERROR,
        props: errorEventProps,
      });

      setErrors((prev) => ({
        ...prev,
        submit: t("js.checkout.serverError"),
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const forgotPassword = async ({ email }: FieldValues) => {
    try {
      setIsSubmitting(true);
      await doQueryWithApiKey({
        query: VERIFY_USER_EMAIL,
        variables: {
          email,
        },
      });
      await resetPassword(email);
      return navigate(`/reset-password?email=${encodeURIComponent(email)}`, {
        state: { showResetPasswordEmailNotification: true },
      });
    } catch (error) {
      const errorProps = { ...params, error };

      if (error instanceof Error) {
        errorProps.error = error.message;
      }

      analytics.track({
        event: AnalyticsEvent.AUTH_ERROR,
        props: errorProps,
      });
      setErrors((prev) => ({
        ...prev,
        submit: t("js.checkout.serverError"),
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const loginByCode = async (credentials: string[]) => {
    if (credentials.length === 2) {
      await signIn({ email: credentials[0], password: credentials[1] });
      return onSuccess();
    }
  };

  return { loginByCode, login, errors, isSubmitting, forgotPassword };
};
