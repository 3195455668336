import { useEffect, useLayoutEffect } from "react";
import { useCookies } from "react-cookie";

import {
  useAuthenticatedUser,
  useAuthenticateUserOnLegacy,
} from "@lib/authentication";
import { useLocale } from "@lib/contexts/LocaleContext";

import { useNmh } from "../../lib/contexts/NmhContext";
import { CookiesService } from "../../services/CookiesService";

export const usePageLayout = () => {
  const [, setCookie, removeCookie] = useCookies();
  const { isAuthenticated, isLoading: isLoadingAuthenticatedUser } =
    useAuthenticatedUser();
  const { hasNmhAccess, isLoading: isLoadingNmh } = useNmh();
  const locale = useLocale();
  const { canRedirectToCollections } = useAuthenticateUserOnLegacy();

  const collectionsUrl = `${import.meta.env.VITE_OMGYES_SERVER_URL}${
    locale.length > 0 && !locale.startsWith("en") ? `/${locale}` : ""
  }/collections${location.search}`;

  // Clean up legacy storage
  useEffect(() => {
    CookiesService.cleanupLegacyStorage();
  }, []);

  // Handle CloudFront cookies
  useEffect(() => {
    if (isLoadingAuthenticatedUser || !isAuthenticated) {
      return;
    }

    CookiesService.setCloudFrontCookies(setCookie, removeCookie);
  }, [isAuthenticated, isLoadingAuthenticatedUser, setCookie, removeCookie]);

  // Smooth scroll to top on location change
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  // Handle redirections and welcome text
  useEffect(() => {
    if (isLoadingNmh) {
      return;
    }

    if (
      (!hasNmhAccess || (locale && !locale.startsWith("en"))) &&
      canRedirectToCollections
    ) {
      CookiesService.setSessionStartCookie(setCookie);
      window.location.href = collectionsUrl;
    } else if (hasNmhAccess) {
      removeCookie("showWelcomeText");
    }
  }, [hasNmhAccess, canRedirectToCollections, isLoadingNmh]);

  return {
    isLoading: isLoadingAuthenticatedUser || isLoadingNmh,
    hasNmhAccess,
  };
};
