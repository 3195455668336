import type {
  CellClassParams,
  CellStyle,
  ColDef,
  IRowNode,
  RowValueChangedEvent,
  ValueFormatterParams,
} from "ag-grid-community";
import _ from "lodash";

import type { Editable } from "@pages/admin/ProductCatalog/store.ts";

const asArray: <T>(x: T | T[]) => T[] = (maybeArray) =>
  Array.isArray(maybeArray) ? maybeArray : [maybeArray];

const intersection = <L extends Record<string, any>, R extends L>(
  l: L,
  r: R,
  fields?: (keyof L & keyof R)[],
): Record<keyof L & keyof R, any[]> => {
  if (!fields) {
    fields = _.intersection(Object.keys(l), Object.keys(r));
  }

  return Object.assign(
    {},
    ...fields.map((k) => ({
      [k]: _.intersection(asArray(l[k]), asArray(r[k])),
    })),
  );
};

const intersects = <L extends Record<string, any>, R extends L>(
  l: L,
  r: R,
  fields?: (keyof L & keyof R)[],
) => {
  return Object.values(intersection(l, r, fields)).every((arr) => arr.length);
};

export function getHighlightChangesCellStyler<TData extends Editable>(
  getById: (id: any) => TData,
) {
  return ({ node, colDef, value }: CellClassParams<TData>): CellStyle => {
    if (colDef.field === "id") return {};

    const row = getById(node.data?.originalId);
    if (!row) {
      return node.data?.id
        ? { backgroundColor: "aquamarine" }
        : { backgroundColor: "aliceblue" };
    }
    if (!node.data?.originalId) {
      return Object.values(node.data || {}).join("") !== "" &&
        colDef.field !== "id"
        ? { backgroundColor: "aquamarine" }
        : { backgroundColor: "aliceblue" };
    }

    // @ts-ignore -- To be shown with strike-out font styling
    const originalValue = row && row[colDef.field];
    if (_.isEqual(originalValue, value) || colDef.field === "id") {
      return {};
    }

    return {
      fontWeight: "550",
      color: "darkslategray",
      ...(!value && {
        textDecoration: "line-through crimson",
        textDecorationThickness: "0.15rem",
      }),
    };
  };
}

export function getHighlightChangesCellFormatter<TData extends Editable>(
  getById: (id: any) => TData,
  defaultValue?: string,
) {
  return ({ value, node, colDef }: ValueFormatterParams<TData>) => {
    if (value) {
      if (value instanceof Date) {
        return value.toLocaleDateString();
      }
      if (value instanceof Number) {
        return value.toFixed(2);
      }
      return value.toString();
    }
    if (!node?.id) {
      return defaultValue;
    }
    const row = getById(node?.data?.originalId);

    // @ts-ignore -- To be shown with strike-out font styling
    return row && row[colDef.field];
  };
}

export function getRowUpdateHandler<TData extends Editable>(
  getById: (id: string) => TData,
  keyFields: (keyof TData)[],
  createRow: () => TData,
  setRows: (rowsOrUpdater: TData[] | ((state: TData[]) => TData[])) => void,
  getId: (_: { data: TData }) => string | undefined = ({ data }) => data.id,
  newId: () => string | undefined = () => undefined,
  validations?: ((data: TData) => false | string | undefined)[],
) {
  return (event: RowValueChangedEvent<TData>) => {
    const { api, node, data } = event;
    if (!data) {
      return;
    }

    const oldRowId = node.id;
    // @ts-ignore
    const newRowId = getId({ data }) || newId();

    const constraintValidation = () => {
      let invalid: string | undefined | false; // to keep typescript happy
      api.forEachNode((rowNode: IRowNode) => {
        if (rowNode.rowIndex === node.rowIndex) {
          return;
        }
        if (
          getId(rowNode) === newRowId ||
          intersects(rowNode.data, data, keyFields) ||
          ("originalId" in rowNode.data && rowNode.data.originalId === newRowId)
        ) {
          invalid =
            "Constraint violation: A row with overlapping key fields already exists or is pending removal.";
        }
      });
      return invalid;
    };

    const invalidMessage = (validations || []).reduce(
      (p, validation) => p || validation(data),
      constraintValidation(),
    );

    if (invalidMessage) {
      alert(invalidMessage);
      const oldData = getById(oldRowId!);
      node.setData({
        ...data,
        ..._.pick(oldData || createRow(), ...keyFields),
      });
      const colDefs = api.getColumnDefs();
      if (colDefs?.length) {
        const colKey = (colDefs[1] as ColDef).field!;
        api.startEditingCell({ rowIndex: node.rowIndex!, colKey });
      }
    } else {
      const newRow = { ...data, id: newRowId };
      if (data.id === "") {
        setRows((state) => [...state.slice(0, -1), newRow, createRow()]);
      } else {
        setRows((state) =>
          state.map((row) =>
            getId({ data: row }) === oldRowId ? newRow : row,
          ),
        );
      }
      api.redrawRows({ rowNodes: [node] });
    }
  };
}
