import { Container } from "@components/ui/Container";
import { VideoPlayerProvider } from "@components/ui/VideoPlayer/VideoPlayer.context";

import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type {
  BasicVideoBlockFields,
  CopyBlockFields,
} from "@pages/collections/types/Page.types";

import { ContentCard } from "../../ContentCard/ContentCard";
import { VideoWithCaption } from "../../VideoWithCaption";

import {
  findComponentsByName,
  findComponentsByNameInSection,
} from "@/utils/componentUtils";

export const ConcealingTechniqueSectionBlockTwo = () => {
  const { sections } = useTechniquePagesContext();

  const techniquesBlockBlockTwoSection = findComponentsByName(
    sections,
    "techniquesBlockTwo",
  );

  const [copyBlock] = findComponentsByNameInSection<CopyBlockFields>(
    techniquesBlockBlockTwoSection,
    "techniquesBlockTwo",
    "copyBlock",
  );

  const basicVideoBlocks = findComponentsByNameInSection<BasicVideoBlockFields>(
    techniquesBlockBlockTwoSection,
    "techniquesBlockTwo",
    "basicVideoBlock",
  );

  return (
    <VideoPlayerProvider>
      <Container
        fullWidth
        id="techniques"
        className="sm:px-6 md:px-10 lg:px-14"
      >
        <ContentCard
          className="gap-7 sm:gap-7 sm:flex sm:flex-col"
          key={copyBlock.fields?.heading}
          heading={copyBlock.fields?.heading || ""}
          copy={copyBlock.fields?.copy || ""}
        >
          <div className="gap-7 sm:gap-7 sm:flex sm:flex-col md:grid md:grid-cols-2 md:gap-5">
            {basicVideoBlocks.map((basicVideoBlock) => (
              <VideoWithCaption
                key={basicVideoBlock.fields?.video.id}
                video={basicVideoBlock}
              />
            ))}
          </div>
        </ContentCard>
      </Container>
    </VideoPlayerProvider>
  );
};
