import { Navigate, Outlet, useLocation } from "react-router-dom";

import PageFooter from "@components/common/PageFooter";
import PageHeader from "@components/common/PageHeader";
import LoadingInterstitial from "@components/LoadingInterstitial";

import { useAuthenticatedUser } from "@lib/authentication";
import { useLocale } from "@lib/contexts/LocaleContext";

import { usePageLayout } from "./PageLayout.hooks";

type PageLayoutProps = {
  hideHeader?: boolean;
  hideFooter?: boolean;
};

export default function PageLayout({
  hideHeader,
  hideFooter,
}: PageLayoutProps) {
  const { isAuthenticated, isLoading: isLoadingAuthenticatedUser } =
    useAuthenticatedUser();
  const locale = useLocale();
  const location = useLocation();
  const { isLoading, hasNmhAccess } = usePageLayout();

  const showLoadingInterstitial = location.state?.fromLogin;

  if (isLoadingAuthenticatedUser || isLoading) {
    return <LoadingInterstitial />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!hasNmhAccess || (locale !== undefined && !locale.startsWith("en"))) {
    return showLoadingInterstitial ? <LoadingInterstitial /> : <></>;
  }

  return (
    <main className="flex grow flex-col flex-nowrap h-screen p-0 w-full">
      {!hideHeader && (
        <>
          <PageHeader />
          <div className="grow shrink basis-auto h-64px" />
        </>
      )}
      <div className="grow shrink basis-auto">
        <Outlet />
      </div>
      <div className="grow-0 shrink basis-auto">
        {!hideFooter && (
          <>
            <PageFooter />
          </>
        )}
      </div>
    </main>
  );
}
