import { Auth as AuthBase } from "../amplify/amplify";
import type { IsomorphicAuthObject } from "../amplify/amplify.types";

let instance: IsomorphicAuthObject;

export function setup() {
  instance = AuthBase;
}

setup();

export const getAuthInstance = () => instance;

export { instance as Auth };
