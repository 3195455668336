import React from "react";
import { useForm } from "react-hook-form";
import { t } from "i18next";

import ProgressBar from "@components/atoms/ProgressBar";
import { InformationCircle } from "@components/atoms/svg/icons/InformationCircle";
import { GiftPageCarousel } from "@components/GiftPageCarousel";

import { formatDateForDatePicker } from "@lib/utils.ts";
import { formatPrice } from "@pages/utils";

import type {
  GiftPageData,
  GiftProductCategory,
  GiftProductPricing,
} from "../../GiftPage.types";
import type { FormData } from "../../GiftPage.types";

import { FormDatePicker } from "./FormDatePicker";
import { FormInputWrapper } from "./FormInput";
import { FormRadio } from "./FormRadio";
import { FormSubmit } from "./FormSubmit";
import FormTextAreaWrapper from "./FormTextArea";

interface GiftPageFormProps {
  data: GiftPageData;
  productPricing?: GiftProductPricing;
  updateCardImage: (index: number) => void;
  onSubmit: (data: any) => void;
}

export const GiftPageForm = ({
  data,
  productPricing,
  updateCardImage,
  onSubmit,
}: GiftPageFormProps) => {
  const { watch, register, handleSubmit, getValues, setValue, setError } =
    useForm<FormData>({
      defaultValues: {
        gift: productPricing?.explore_more[0].id ?? "explore more",
        deliveryMethod: "Send as an email",
        deliveryDate: formatDateForDatePicker(new Date()),
      },
    });

  const isEmailDelivery =
    watch() && getValues("deliveryMethod") === "Send as an email";
  const isInPersonDelivery = getValues("deliveryMethod") === "Give in person";

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="gift-form">
      <div className="gift-content">
        <div className="gift-x-padding">
          <div className="gift-max1040-container">
            <div className="gift-page-section">
              <div className="gift-page-section-header">
                {data.selectCardText}
              </div>
              <div className="gift-page-section-card-selector">
                <GiftPageCarousel
                  showing={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                  onSelect={updateCardImage}
                >
                  {data.cardImages.map(({ fields }, index) => {
                    return (
                      <div
                        className="gift-page-card-image"
                        key={`${fields.title}-${index}`}
                      >
                        <img src={fields.file.url} width="100%" />
                      </div>
                    );
                  })}
                </GiftPageCarousel>
              </div>
            </div>
          </div>
        </div>

        <div className="gift-x-padding">
          <div className="gift-max1040-container">
            <div className="gift-page-layout">
              <div className="gift-page-section md:basis-1/2">
                <div className="gift-page-section-header">
                  {data.selectGiftText}
                </div>
                <FormRadio
                  className="gift-page-section-details-item"
                  inputClassName="gift-page-section-details-item-large-content"
                  optionClassName="gift-page-large-option"
                  optionSelectedClassName="gift-page-option-selected"
                  optionUnselectedClassName="gift-page-option-unselected"
                  register={() => register("gift", { required: true })}
                  onChange={() => setError("gift", {})}
                  value={watch() ? getValues("gift") : ""}
                  options={data.products.map(({ fields }) => {
                    return {
                      value:
                        productPricing &&
                        productPricing[fields.id as GiftProductCategory]
                          ? productPricing[fields.id as GiftProductCategory][0]
                              .id
                          : "",
                      boost: fields.boostText,
                      children: (
                        <div className="gift-page-gift-option-container">
                          <div className="gift-page-gift-option-product">
                            {React.createElement("div", {
                              dangerouslySetInnerHTML: {
                                __html: fields.headline,
                              },
                            })}
                          </div>
                          <div className="flex flex-row justify-left items-center gap-8px">
                            {!productPricing ||
                            !productPricing[
                              fields.id as GiftProductCategory
                            ] ? (
                              <div className="w-[120px]">
                                <ProgressBar />
                              </div>
                            ) : (
                              <>
                                <div className="gift-page-gift-option-price">
                                  {formatPrice({
                                    amount:
                                      productPricing[
                                        fields.id as GiftProductCategory
                                      ][0].amount,
                                    currency:
                                      productPricing[
                                        fields.id as GiftProductCategory
                                      ][0].currency,
                                  })}
                                </div>
                                {productPricing[
                                  fields.id as GiftProductCategory
                                ][0].strikeThrough && (
                                  <div className="gift-page-gift-option-altprice">
                                    {formatPrice({
                                      amount:
                                        productPricing[
                                          fields.id as GiftProductCategory
                                        ][0].strikeThrough ?? 0,
                                      currency:
                                        productPricing[
                                          fields.id as GiftProductCategory
                                        ][0].currency,
                                    })}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="gift-page-gift-option-otp">
                            {fields.paymentFrequency}
                          </div>
                        </div>
                      ),
                    };
                  })}
                />
              </div>

              <div className="gift-page-section md:basis-1/2">
                <div className="gift-page-section-header">
                  {data.addDetailsText}
                </div>
                <div className="gift-page-section-details-container">
                  <FormRadio
                    className="gift-page-section-details-item"
                    labelClassName="gift-page-section-details-item-header"
                    inputClassName="gift-page-section-details-item-content"
                    optionClassName="gift-page-delivery-option"
                    optionSelectedClassName="gift-page-delivery-option-selected"
                    optionUnselectedClassName="gift-page-delivery-option-unselected"
                    register={() =>
                      register("deliveryMethod", { required: true })
                    }
                    label={t(data.deliveryOptionsPromptText)}
                    onChange={() => {
                      setValue("recipientEmail", "");
                      setError("deliveryMethod", { type: "required" });
                    }}
                    value={watch() ? getValues("deliveryMethod") : ""}
                    options={data.deliveryOptions.map((option) => {
                      return {
                        value: option,
                        children: <div>{option}</div>,
                      };
                    })}
                  />
                  {isEmailDelivery && (
                    <FormInputWrapper
                      className="gift-page-section-details-item"
                      labelClassName="gift-page-section-details-item-header"
                      inputClassName="gift-page-section-details-item-content"
                      register={() =>
                        register("recipientEmail", { required: true })
                      }
                      label={t(data.recipientPromptText)}
                      placeholder={t("email@email.com")}
                      type="email"
                      onChange={() => setError("recipientEmail", {})}
                    />
                  )}
                  <div className={isInPersonDelivery ? "hidden" : ""}>
                    <FormDatePicker
                      className="gift-page-section-details-item"
                      labelClassName="gift-page-section-details-item-header"
                      inputClassName="gift-page-section-details-item-content"
                      register={() =>
                        register("deliveryDate", { required: true })
                      }
                      setValue={(x) => setValue("deliveryDate", x)}
                      label={t(data.deliveryDatePromptText)}
                      placeholder={"Pick a date"}
                      onChange={() => setError("deliveryDate", {})}
                    />
                  </div>
                  {isInPersonDelivery ? (
                    <div className={"gift-page-section-details-information"}>
                      <div>
                        <InformationCircle />
                      </div>
                      <div>{data.giveInPersonInstructions}</div>
                    </div>
                  ) : null}
                  <FormInputWrapper
                    className="gift-page-section-details-item"
                    labelClassName="gift-page-section-details-item-header"
                    inputClassName="gift-page-section-details-item-content"
                    register={() => register("senderName", { required: true })}
                    label={t(data.senderNamePromptText)}
                    placeholder={t(data.senderPromptPlaceholderText)}
                    type="text"
                    onChange={() => setError("senderName", {})}
                  />
                  <FormTextAreaWrapper
                    className="gift-page-section-details-item"
                    labelClassName="gift-page-section-details-item-header"
                    inputClassName="gift-page-section-details-item-content"
                    register={() => register("note", { required: false })}
                    label={t(data.addANotePromptText)}
                    placeholder={t(data.addANotePlaceholderText)}
                    onChange={() => setError("note", {})}
                  />
                </div>

                <div className={"gift-page-section gift-page-section-cta "}>
                  <FormSubmit
                    className="gift-page-checkout-cta"
                    value={t(data.checkoutCtaText)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
