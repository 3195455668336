import { Container } from "@components/ui/Container";
import VideoPlayer from "@components/ui/VideoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "@components/ui/VideoPlayer/VideoPlayer.context";

import useTailwindTheme from "@lib/hooks/useTailwindTheme";
import { cn } from "@lib/utils";
import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type {
  AnimationVideoBlockFields,
  BasicVideoBlockFields,
  CopyBlockFields,
} from "@pages/collections/types/Page.types";
import { TechniquePagesCommonSections } from "@pages/collections/types/Page.types";

import { ContentCard } from "../../ContentCard/ContentCard";
import { VideoWithCaption } from "../../VideoWithCaption";

import {
  findComponentsByName,
  findComponentsByNameInSection,
} from "@/utils/componentUtils";

export const WrappingGreatestHitsBlock = () => {
  const { isXl } = useTailwindTheme();
  const { sections } = useTechniquePagesContext();

  const greatestHitsSection = findComponentsByName(
    sections,
    TechniquePagesCommonSections.greatestHitsBlock,
  );

  const copyBlocks = findComponentsByNameInSection<CopyBlockFields>(
    greatestHitsSection,
    TechniquePagesCommonSections.greatestHitsBlock,
    "copyBlock",
  );

  const [animationVideoBlock] =
    findComponentsByNameInSection<AnimationVideoBlockFields>(
      greatestHitsSection,
      TechniquePagesCommonSections.greatestHitsBlock,
      "animationVideoBlock",
    );

  const basicVideoBlocks = findComponentsByNameInSection<BasicVideoBlockFields>(
    greatestHitsSection,
    TechniquePagesCommonSections.greatestHitsBlock,
    "basicVideoBlock",
  );

  return (
    <VideoPlayerProvider>
      <Container
        fullWidth
        className="flex flex-col gap-4 sm:px-6 md:px-10 lg:px-14"
      >
        {copyBlocks.map((copyBlock, index) => {
          return (
            <ContentCard
              className={cn(
                "sm:flex sm:flex-col",
                isXl && index !== 1 && "xl:grid xl:grid-cols-3",
              )}
              key={copyBlock.fields?.heading}
              heading={copyBlock.fields?.heading || ""}
              copy={copyBlock.fields?.copy || ""}
            >
              <div
                className={cn(
                  "flex flex-col",
                  isXl && index !== 1 && "xl:col-span-2 xl:-order-1",
                  isXl && index === 1 && "xl:flex-row xl:mt-4 xl:gap-5",
                )}
              >
                <div
                  className={cn(
                    "flex flex-col",
                    isXl && index === 1 && "xl:flex-1",
                  )}
                >
                  <VideoWithCaption video={basicVideoBlocks[index]} />
                </div>
                {/* Only the second item has animation */}
                {index === 1 && (
                  <div
                    className={cn("mt-4 md:mt-5 xl:mt-0", isXl && "xl:flex-1")}
                  >
                    <VideoPlayer
                      controls={false}
                      autoPlay
                      isAnimation
                      muted
                      id={animationVideoBlock.fields?.video.id || ""}
                      url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlock.fields?.video.url}`}
                      loop={animationVideoBlock.fields?.video.loop}
                    />
                  </div>
                )}
              </div>
            </ContentCard>
          );
        })}
      </Container>
    </VideoPlayerProvider>
  );
};
