import React from "react";

import { cn } from "@lib/utils";

interface FormLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
}

export const FormLabel = ({ className, ...props }: FormLabelProps) => (
  <label
    className={cn(
      "text-[14px] font-normal leading-[20px] tracking-[0.175px] text-ui-coal font-mabry-regular",
      className,
    )}
    {...props}
  />
);

export default FormLabel;
