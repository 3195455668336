import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { AlertTriangle, CheckCircle, Info, XCircle } from "lucide-react";

import { ExplicitTagIcon } from "./ExplicitTagIcon";

const tagVariants = cva(
  "px-2 py-1 rounded-full inline-flex items-center gap-1 font-mabry-pro-regular",
  {
    variants: {
      variant: {
        success: "bg-green-100 text-green-800",
        warning: "bg-yellow-100 text-yellow-800",
        error: "bg-red-100 text-red-800",
        info: "bg-blue-100 text-blue-800",
        explicit: "bg-[#F7BC5E] text-gray-800",
      },
      size: {
        small: "text-xs",
        medium: "text-sm",
      },
    },
    defaultVariants: {
      size: "medium",
      variant: "info",
    },
  },
);

const variantIcons = {
  success: CheckCircle,
  warning: AlertTriangle,
  error: XCircle,
  info: Info,
  explicit: ExplicitTagIcon,
} as const;

interface TagProps extends VariantProps<typeof tagVariants> {
  children: React.ReactNode;
  withIcon?: boolean;
}

export const Tag: React.FC<TagProps> = ({
  children,
  size,
  variant = "info",
  withIcon = true,
}) => {
  const IconComponent = variant ? variantIcons[variant] : null;

  return (
    <div className={tagVariants({ size, variant })}>
      {withIcon && IconComponent && <IconComponent className="w-4 h-4" />}
      {children}
    </div>
  );
};
