import React from "react";

import { Typography } from "@components/ui/Typography";
import VideoPlayer from "@components/video/VideoPlayer.tsx";

interface VideoInfo {
  id?: string;
  url?: string;
  label: string;
}

interface ComparisonAnimationSectionProps {
  leftVideo: VideoInfo;
  rightVideo: VideoInfo;
  isMobile: boolean;
  className?: string;
  animationVsText: string;
}

export const ComparisonAnimationSection: React.FC<
  ComparisonAnimationSectionProps
> = ({ leftVideo, rightVideo, className = "", animationVsText }) => {
  const videoProps = {
    controls: false,
    loop: true,
    autoPlay: true,
    active: true,
    muted: true,
    aspectRatio: "1x1" as const,
    className: "max-w-[275px]",
    isAnimation: true,
  };

  return (
    <div className={`w-full max-w-4xl mx-auto ${className}`}>
      <div className={`flex flex-col sm:flex-row rounded-lg overflow-hidden`}>
        {/* Left Video Container */}
        <div className="w-full sm:w-1/2 flex flex-col">
          {/* Video Background + Video */}
          <div className="relative flex-grow rounded-lg p-4 flex items-center justify-center">
            <VideoPlayer
              id={leftVideo.id || ""}
              url={leftVideo.url || ""}
              {...videoProps}
            />
          </div>

          {/* Caption */}
          <div className="p-4 text-center">
            <Typography>{leftVideo.label}</Typography>
          </div>
        </div>

        {/* VS Divider - Mobile (visible below sm breakpoint) */}
        <div className="flex items-center justify-center my-3 px-4 sm:hidden">
          <div className="h-[1px] bg-ui-onion flex-grow" />
          <Typography className="mx-4 !font-bold !text-[18px] uppercase text-ui-pepper">
            {animationVsText}
          </Typography>
          <div className="h-[1px] bg-ui-onion flex-grow" />
        </div>

        {/* VS Divider - Desktop (visible at sm breakpoint and above) */}
        <div className="hidden sm:flex flex-col">
          {/* Video area - Empty space with vertical line */}
          <div className="flex-grow flex justify-center">
            <div className="w-[1px] h-full bg-ui-onion" />
          </div>

          {/* Caption area - VS text aligned with other captions */}
          <div className="p-4 text-center">
            <Typography className="!font-bold !text-[18px] uppercase">
              {animationVsText}
            </Typography>
          </div>
        </div>

        {/* Right Video Container */}
        <div className="w-full sm:w-1/2 flex flex-col">
          {/* Video */}
          <div className="relative flex-grow p-4 flex items-center justify-center">
            <VideoPlayer
              id={rightVideo.id || ""}
              url={rightVideo.url || ""}
              {...videoProps}
            />
          </div>

          {/* Caption */}
          <div className="p-4 text-center">
            <Typography>{rightVideo.label}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
