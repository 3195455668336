import {
  AuthenticationErrors,
  IdentityProviderApiErrors,
} from "./amplify/identity-provider.enum";
import type { IdentityProviderError } from "./amplify/identity-provider.types";

const cognitoErrorMapper: Record<string, string> = {
  [IdentityProviderApiErrors.USER_NOT_FOUND]:
    AuthenticationErrors.INVALID_CREDENTIALS,
  [IdentityProviderApiErrors.PASSWORD_RESET_REQUIRED]:
    AuthenticationErrors.PASSWORD_RESET_REQUIRED,
  [IdentityProviderApiErrors.TOO_MANY_REQUESTS]:
    AuthenticationErrors.TOO_MANY_LOGIN_ATTEMPTS,
  [IdentityProviderApiErrors.ALIAS_EXISTS]:
    AuthenticationErrors.EMAIL_ALREADY_TAKEN,
  [IdentityProviderApiErrors.LIMIT_EXCEEDED_EXCEPTION]:
    AuthenticationErrors.LIMIT_EXCEEDED_EXCEPTION,
  [IdentityProviderApiErrors.EXPIRED_CODE]: AuthenticationErrors.CODE_EXPIRED,
  [IdentityProviderApiErrors.CODE_MISMATCH]: AuthenticationErrors.INVALID_CODE,
  [IdentityProviderApiErrors.INVALID_PARAMETER_EXCEPTION]:
    AuthenticationErrors.INVALID_PARAMETER,
  [IdentityProviderApiErrors.INVALID_PASSWORD_EXCEPTION]:
    AuthenticationErrors.INVALID_PASSWORD,
};

export function isIdentityProviderError(
  error: unknown,
): error is IdentityProviderError {
  const isObject = typeof error === "object" && error !== null;
  return isObject && "code" in error && "message" in error;
}

export const identityProviderMapError = (
  error: IdentityProviderError,
): Error => {
  const name = error?.name;

  if (name === (IdentityProviderApiErrors.NOT_AUTHORIZED as string)) {
    if (error.message === "User is disabled.") {
      return new Error(AuthenticationErrors.LOCKED_ACCOUNT);
    }
    if (error.message.includes("session is expired")) {
      return new Error(AuthenticationErrors.SESSION_EXPIRED);
    }
    return new Error(AuthenticationErrors.INVALID_CREDENTIALS);
  }

  if (error?.message === "Signout timeout fail") {
    return new Error(AuthenticationErrors.SIGN_OUT_TIMEOUT);
  }

  return cognitoErrorMapper[name] ? new Error(cognitoErrorMapper[name]) : error;
};
