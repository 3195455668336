import React, { useEffect, useState } from "react";
import { type FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { gql } from "graphql-request";
import { isEmpty } from "lodash";

import { Form, FormError, FormInput, FormSubmit } from "@components/forms";

import useAnalytics from "@lib/hooks/useAnalytics";
import { AnalyticsEvent } from "@root/constants";
import { ExclamationCircle } from "@svg/icons/ExclamationCircle";

import { useLoginForm } from "./useLoginForm";

const Config = {
  login: {
    title: "meta.pageName.login",
    action: "login.signinButton",
  },
  signup: {
    title: "checkoutPage.create",
    action: "checkoutPage.createAccount",
  },
  "forgot-password": {
    title: "password.forgot.title",
    action: "password.reset.submit",
    link: "login.forgotPassword",
  },
};

export const defaultErrorMessages: Record<string, string> = {
  email: "forgotPassword.invalidEmail",
  password: "password.reset.invalidPassword",
  confirmPassword: "password.reset.passwordMismatch",
  submit: "js.checkout.serverError",
  code: "password.reset.invalidCode",
  newPassword: "password.reset.emptyNewPassword",
};

export const defaultLoginErrorMessages: Record<string, string> = {
  email: "login.loginError",
  password: "login.loginError",
  submit: "js.checkout.serverError",
};

export const LoginFormModes = Object.keys(Config);

interface LoginFormProps {
  mode: "login" | "signup" | "forgot-password";
  onSuccess: () => void;
}

interface FormData {
  email: string;
  password: string;
  confirmPassword?: string;
  rememberMe?: boolean;
}

export const VERIFY_USER_EMAIL = gql`
  mutation verifyUser($email: String!) {
    verifyUser(email: $email)
  }
`;

async function processAndValidate(
  encryptedPayload: {
    vector: any;
    cipherData: any;
    signature: any;
  },
  keyToken: any,
  navigate: any,
) {
  const { vector, cipherData, signature } = encryptedPayload;
  const rawKeyToken = Uint8Array.from(atob(keyToken), (c) => c.charCodeAt(0));
  const decodedVector = Uint8Array.from(atob(vector), (c) => c.charCodeAt(0));
  const cipherArray = Uint8Array.from(
    cipherData.match(/.{1,2}/g).map((byte: string) => parseInt(byte, 16)),
  );
  const signatureArray = Uint8Array.from(
    signature.match(/.{1,2}/g).map((byte: string) => parseInt(byte, 16)),
  );

  // Import both AES and HMAC keys in parallel
  const [importedCryptoKey, hmacSecretKey] = await Promise.all([
    window.crypto.subtle.importKey(
      "raw",
      rawKeyToken,
      { name: "AES-GCM" },
      false,
      ["decrypt"],
    ),
    window.crypto.subtle.importKey(
      "raw",
      rawKeyToken,
      { name: "HMAC", hash: { name: "SHA-256" } },
      false,
      ["verify"],
    ),
  ]);

  // Verify HMAC signature
  const isAuthentic = await window.crypto.subtle.verify(
    "HMAC",
    hmacSecretKey,
    signatureArray,
    cipherArray,
  );

  if (!isAuthentic) {
    console.error("Signature verification failed!");
    navigate("/login");
  }

  // Decrypt the data
  const decryptedData = await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: decodedVector,
    },
    importedCryptoKey,
    cipherArray,
  );

  // Convert decrypted data back to a readable string
  const decoder = new TextDecoder();
  return decoder.decode(decryptedData);
}

const LoginForm = ({ onSuccess, mode }: LoginFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useForm<FormData>({
    shouldUnregister: true,
  });
  const [searchParams] = useSearchParams();
  const [hideLoginUI, setHideLoginUI] = useState(false);
  const { analytics } = useAnalytics();
  const params = useParams();
  const { login, errors, isSubmitting, forgotPassword, loginByCode } =
    useLoginForm(onSuccess);

  useEffect(() => {
    if (localStorage.getItem("si")) {
      setHideLoginUI(true);
    }
  }, []);

  const handleSignIn = async (
    data: FieldValues,
    event: React.BaseSyntheticEvent | undefined,
  ) => {
    event?.preventDefault();
    if (mode === "login") {
      return await login(data);
    }
    return await forgotPassword(data);
  };

  useEffect(() => {
    const processEncryptedData = async () => {
      if (localStorage.getItem("si")) {
        const sessionData = JSON.parse(localStorage.getItem("si") ?? "{}");
        const checkoutKey = JSON.parse(localStorage.getItem("ck") ?? "{}");

        try {
          const processedData = await processAndValidate(
            sessionData,
            checkoutKey,
            navigate,
          );
          const credentials = processedData.split(":");

          if (credentials.length !== 2) {
            const error = new Error(
              "Could not log in user via encrypted local storage",
            );
            throw error;
          }

          await loginByCode(credentials);
        } catch (error) {
          Sentry.captureException(error);

          const errorMessage =
            error instanceof Error
              ? error.message
              : "Could not decrypt user details";

          const errorDetails = {
            code: "decryption_error",
            message: errorMessage,
            sessionData,
            checkoutKey,
          };

          analytics.track({
            event: AnalyticsEvent.AUTH_ERROR,
            props: { ...params, ...errorDetails },
          });

          // Clean up local storage
          localStorage.removeItem("si");
          localStorage.removeItem("ck");

          // Redirect to logout
          navigate("/logout");
        }
      }
    };

    if (localStorage.getItem("si")) {
      processEncryptedData();
    }
  }, [searchParams, localStorage]);

  if (
    t(Config[mode].title) === Config[mode].title ||
    hideLoginUI ||
    localStorage.getItem("si")
  )
    return <></>;

  return (
    <Form onSubmit={handleSubmit(handleSignIn)} className="w-full">
      <div className="w-full font-mabry-pro-bold text-32px tracking-tight">
        {t(Config[mode].title)}
      </div>
      {mode !== "login" && (
        <FormError hidden={isEmpty(errors)}>
          <ul>
            {["email", "password", "submit"].map(
              (f: string) =>
                errors[f] && (
                  <li key={f} className="flex">
                    <ExclamationCircle className="mr-4px inline min-h-[21px] min-w-14px" />
                    <div>{errors[f].message || t(defaultErrorMessages[f])}</div>
                  </li>
                ),
            )}
          </ul>
        </FormError>
      )}
      {mode === "login" && (
        <FormError hidden={isEmpty(errors)}>
          <ul>
            <li className="flex">
              <ExclamationCircle className="mr-4px inline min-h-[21px] min-w-14px" />
              {(() => {
                const firstKey = Object.keys(errors)[0];
                const error = errors[firstKey];
                if (error && typeof error === "object" && error.message) {
                  return error.message;
                }
                return t(defaultLoginErrorMessages[firstKey]);
              })()}
            </li>
          </ul>
        </FormError>
      )}
      <div className="my-32px w-full">
        {/* Email input */}
        <FormInput
          className="mb-4 w-full"
          register={() => register("email", { required: true })}
          label={t("login.usernameField")}
          error={errors.email}
          errorClassName={"hidden"}
          onChange={(e) => {
            setValue("email", e.target.value);
          }}
        />
        {/* Password input */}
        {mode !== "forgot-password" && (
          <FormInput
            className="mb-4 w-full"
            register={() => register("password", { required: true })}
            label={t("login.passwordField")}
            error={errors.password}
            errorClassName={"hidden"}
            type="password"
            onChange={(e) => {
              setValue("password", e.target.value);
            }}
          />
        )}

        {mode === "login" && (
          <div className="flex flex-row justify-between w-full">
            <Link
              className="hover:text-cta-hover text-cta text-14px font-mabry-pro-regular cursor-pointer"
              to="/forgot-password"
            >
              {t(Config["forgot-password"].link)}
            </Link>
          </div>
        )}
      </div>
      <FormSubmit
        disabled={isSubmitting}
        value={t(Config[mode!].action)}
        className="h-[40px] !pl-16px !pr-16px sm:!pl-24px sm:!pr-24px"
      />
    </Form>
  );
};

export default LoginForm;
