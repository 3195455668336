import type { ReactNode } from "react";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { InView } from "react-intersection-observer";
import clsx from "clsx";
import _ from "lodash";

import { useScreen } from "@lib/contexts/ScreenProvider.tsx";
import { getScreenWidth } from "@lib/screen.ts";

export const ArticleCarousel = forwardRef(
  (
    {
      children,
      showing,
      maxWidthClasses,
      scrollIconTopMargin,
      dark,
      onChange,
    }: {
      children: ReactNode[];
      showing: { xs: number; sm: number; md: number; lg: number };
      scrollIconTopMargin?: string;
      dark?: boolean;
      maxWidthClasses?: string;
      onChange?: (currentItem: number) => void;
    },
    ref,
  ) => {
    const carouselRef = useRef<HTMLDivElement>(null);
    const [currentItem, setCurrentItem] = useState<number>(0);
    const refs = useRef<(null | HTMLDivElement)[]>([]);

    const { width } = useScreen();

    useImperativeHandle(ref, () => ({
      setActiveItem: (item: number) => {
        setCurrentItem(item);
        scrollItThere(item);
      },
    }));

    const scrollItThere = (atPage: number) => {
      carouselRef?.current?.scrollTo({
        left: atPage * (refs.current[atPage]?.scrollWidth || 0) + 8 * atPage,
        behavior: "smooth",
      });
    };

    useEffect(() => {
      currentItem > -1 && scrollItThere(currentItem);
    }, [currentItem]);

    const handleScroll = (_step: number) => {
      const step =
        width >= getScreenWidth("lg")
          ? _step * (showing.lg - 1)
          : width >= getScreenWidth("md")
            ? _step * (showing.md - 1)
            : _step;
      const current: number = _.max([currentItem, 0]) || 0;
      const target =
        _.max([0, _.min([children.length - 1, current + step])]) || 0;
      setCurrentItem(target);
      if (onChange) {
        onChange(target);
      }
    };

    const moreTilesThanShown: boolean =
      width >= getScreenWidth("lg")
        ? children.length > showing.lg
        : width >= getScreenWidth("md")
          ? children.length > showing.md
          : false;
    const lastOnScreen: boolean =
      width >= getScreenWidth("lg")
        ? currentItem + showing.lg >= children.length
        : width >= getScreenWidth("md")
          ? currentItem + showing.md >= children.length
          : false;

    return (
      <div className="article-carousel-container">
        <div className="article-carousel-padding">
          <div className="article-tiles-with-scrollers">
            <div className="article-selector-tile-scroll">
              <div className="article-selector-tile-scroll-content">
                {moreTilesThanShown && currentItem > 0 ? (
                  <div
                    className={clsx(
                      "article-selector-tile-scroll-arrow-wrapper",
                      "hover:cursor-pointer",
                      scrollIconTopMargin || "",
                      dark
                        ? "bg-white bg-opacity-5 text-ui-sugar"
                        : "bg-ui-garlic",
                    )}
                    onClick={() => handleScroll(-1)}
                  >
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.2361 16.027C13.5046 15.7693 13.5046 15.3516 13.2361 15.094L8.22227 10.2827L13.2361 5.47143C13.5046 5.21379 13.5046 4.79608 13.2361 4.53844C12.9677 4.28081 12.5323 4.28081 12.2639 4.53844L6.76386 9.81622C6.49538 10.0739 6.49538 10.4916 6.76386 10.7492L12.2639 16.027C12.5324 16.2846 12.9677 16.2846 13.2361 16.027Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                ) : (
                  <>
                    {moreTilesThanShown && (
                      <div
                        className={clsx(
                          "article-selector-tile-scroll-arrow-wrapper-disabled",
                          scrollIconTopMargin || "",
                          dark ? "bg-white bg-opacity-5 text-ui-sugar" : "",
                        )}
                      >
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.2361 16.027C13.5046 15.7693 13.5046 15.3516 13.2361 15.094L8.22227 10.2827L13.2361 5.47143C13.5046 5.21379 13.5046 4.79608 13.2361 4.53844C12.9677 4.28081 12.5323 4.28081 12.2639 4.53844L6.76386 9.81622C6.49538 10.0739 6.49538 10.4916 6.76386 10.7492L12.2639 16.027C12.5324 16.2846 12.9677 16.2846 13.2361 16.027Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={"article-carousel-tile-overflow"}>
              <div
                className={"article-carousel-tile-container"}
                ref={carouselRef}
              >
                {children.map((child, index) => (
                  <div
                    key={index}
                    ref={(i) => (refs.current[index] = i)}
                    className={clsx(
                      "article-carousel-tile",
                      "h-full",
                      showing.xs === 1 ? "w-[calc(100vw-46px)]" : "",
                      maxWidthClasses || "",
                      showing.sm === 1.8 ? "sm:w-[calc((100vw-88px)/1.8)]" : "",
                      showing.sm === 1.9 ? "sm:w-[calc((100vw-88px)/1.9)]" : "",
                      showing.sm === 2 ? "sm:w-[calc((100vw-88px)/2)]" : "",
                      showing.md === 2
                        ? "md:w-[calc((100vw-112px)/2)] md:max-w-[calc(760px/2)]"
                        : "",
                      showing.md === 3
                        ? "md:w-[calc((100vw-112px)/3)] md:max-w-[calc(754px/3)]"
                        : "",
                      showing.lg === 2
                        ? "lg:w-[calc((100vw-112px)/2)] lg:max-w-[calc(760px/2)]"
                        : "",
                      showing.lg === 3
                        ? "lg:w-[calc((100vw-120px)/3)] lg:max-w-[calc(754px/3)]"
                        : "",
                    )}
                  >
                    <InView
                      as={"div"}
                      className={"h-full"}
                      threshold={1}
                      onChange={(inView) => {
                        if (inView && onChange) {
                          onChange(index);
                        }
                      }}
                    >
                      {child}
                    </InView>
                  </div>
                ))}
              </div>
            </div>
            <div className={"article-selector-tile-scroll"}>
              <div className={"article-selector-tile-scroll-content"}>
                {moreTilesThanShown && !lastOnScreen ? (
                  <div
                    className={clsx(
                      "article-selector-tile-scroll-arrow-wrapper",
                      "hover:cursor-pointer",
                      scrollIconTopMargin || "",
                      dark
                        ? "bg-white bg-opacity-5 text-ui-sugar"
                        : "bg-ui-garlic",
                    )}
                    onClick={() => handleScroll(1)}
                  >
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.01386 4.53844C7.74538 4.79608 7.74538 5.21379 8.01386 5.47143L13.0277 10.2827L8.01386 15.094C7.74538 15.3516 7.74538 15.7693 8.01386 16.027C8.28235 16.2846 8.71765 16.2846 8.98614 16.027L14.4861 10.7492C14.7546 10.4916 14.7546 10.0739 14.4861 9.81622L8.98614 4.53844C8.71765 4.28081 8.28235 4.28081 8.01386 4.53844Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                ) : (
                  <>
                    {moreTilesThanShown && (
                      <div
                        className={clsx(
                          "article-selector-tile-scroll-arrow-wrapper-disabled",
                          scrollIconTopMargin || "",
                          dark ? "bg-white bg-opacity-5 text-ui-sugar" : "",
                        )}
                      >
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.01386 4.53844C7.74538 4.79608 7.74538 5.21379 8.01386 5.47143L13.0277 10.2827L8.01386 15.094C7.74538 15.3516 7.74538 15.7693 8.01386 16.027C8.28235 16.2846 8.71765 16.2846 8.98614 16.027L14.4861 10.7492C14.7546 10.4916 14.7546 10.0739 14.4861 9.81622L8.98614 4.53844C8.71765 4.28081 8.28235 4.28081 8.01386 4.53844Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

ArticleCarousel.displayName = "ArticleCarousel";
