import type { BedroomBlockType } from "../components/BedroomBlock/BedroomBlock.types";
import type { TopBlockType } from "../components/TopBlock/TopBlock";

export enum TechniquePage {
  chilling = "chilling",
  compressing = "compressing",
  concealing = "concealing",
  grinding = "grinding",
  girthing = "girthing",
  levering = "levering",
  "mode-play" = "mode-play",
  outstroking = "outstroking",
  rippling = "rippling",
  streaming = "streaming",
  transferring = "transferring",
  wrapping = "wrapping",
}

export enum TechniquePagesCommonSections {
  topBlock = "topBlock",
  topAnimation = "topAnimation",
  pageHeading = "pageHeading",
  quoteSliderBanner = "quoteSliderBanner",
  greatestHitsBlock = "greatestHitsBlock",
  bedroomBlockCommon = "bedroomBlockCommon",
  techniquesBlock = "techniquesBlock",
}

export type PageHeadingFields = {
  icon: TechniquePage;
  subtitle: string;
  title: string;
};

export type PageHeadingType = {
  component: TechniquePagesCommonSections.pageHeading;
  fields: PageHeadingFields;
};

export type QuoteSliderBannerFields = {
  image: { path: string };
  heading: string;
  quotes: string[];
};

export type QuoteSliderBannerType = {
  component: TechniquePagesCommonSections.quoteSliderBanner;
  fields: QuoteSliderBannerFields;
};

export type Sections = Array<{
  component: TechniquePagesCommonSections;
  fields: Record<string, any>;
}>;

export type TechniquePageSections = {
  heading: {
    [TechniquePagesCommonSections.pageHeading]: PageHeadingType;
    [TechniquePagesCommonSections.topBlock]: TopBlockType;
  };
  [TechniquePagesCommonSections.quoteSliderBanner]: QuoteSliderBannerType[];
  [TechniquePagesCommonSections.bedroomBlockCommon]: BedroomBlockType;
};

export type CopyBlockFields = {
  heading: string;
  copy: string;
};

export type VideoFields = {
  video: {
    captionLocales: string;
    captionSource: string;
    coverImage: string;
    id: string;
    videoUrl: string;
  };
  subContent: { name: string; copy: string };
  explicit: {
    isExplicit: boolean;
    label: string;
    overlayCopy: string;
    letsGo: string;
    okGotIt: string;
  };
};

export type BasicVideoBlockFields = Omit<VideoFields, "subContent"> & {
  subContent: { participantName: string; caption: string };
};

export type AnimationVideoBlockFields = {
  video: {
    id: string;
    srcName: string;
    url: string;
    autoplay: boolean;
    loop: boolean;
    playerElement: string;
  };
};
