import { Container } from "@components/ui/Container";
import { Typography } from "@components/ui/Typography";

import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import { TechniquePagesCommonSections } from "@pages/collections/types/Page.types";

import { ContentCard } from "../../../ContentCard/ContentCard";
import SectionHeading from "../../../SectionHeading/SectionHeading";

import "./ModePlayTechniqueSection.styles.css";

import {
  findComponentsByName,
  findComponentsByProperty,
} from "@/utils/componentUtils";

export const ModePlayTechniqueSection = () => {
  const { sections } = useTechniquePagesContext();

  const techniquesBlockSection = findComponentsByName(
    sections,
    TechniquePagesCommonSections.techniquesBlock,
  );

  const [gettingUsedSession] = findComponentsByProperty(
    techniquesBlockSection,
    "cssClass",
    ["grid-container", "outline", "used"],
  );

  const [gettingUsedSessionCopyBlock] = findComponentsByName(
    gettingUsedSession,
    "copyBlock",
  );

  const gettingUsedSessionParagraphs = findComponentsByProperty(
    gettingUsedSession,
    "cssClass",
    "copy",
  );

  const [thingsToConsiderSession] = findComponentsByProperty(
    techniquesBlockSection,
    "cssClass",
    ["grid-container", "outline", "consider"],
  );

  const [thingsToConsiderCopyBlock] = findComponentsByName(
    thingsToConsiderSession,
    "copyBlock",
  );

  return (
    <Container fullWidth>
      <div className="global-section-padding-x">
        <SectionHeading title="Techniques" />
      </div>

      <div className="flex flex-col gap-4">
        <ContentCard
          className="flex flex-col gap-4 md:gap-5"
          heading={gettingUsedSessionCopyBlock.fields?.heading}
          copy={gettingUsedSessionCopyBlock.fields?.copy}
        >
          {gettingUsedSessionParagraphs.map((paragraph) => {
            return (
              <Typography key={paragraph.fields?.copy}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: paragraph.text,
                  }}
                />
              </Typography>
            );
          })}
        </ContentCard>

        <ContentCard
          className="flex flex-col gap-4 md:gap-5"
          heading={thingsToConsiderCopyBlock.fields?.heading}
          copy={thingsToConsiderCopyBlock.fields?.copy}
        />
      </div>
    </Container>
  );
};
