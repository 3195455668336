import { useEffect, useMemo } from "react";
import type FilePlayer from "react-player/file";
import type { FileConfig } from "react-player/file";
import ReactPlayer from "react-player/file";

import type { IVideoDisplay } from "@components/types";

import { cn } from "@lib/utils";
import { LanguageCode } from "@root/constants";

const ReactPlayerContainer = ({
  playerRef,
  url,
  style,
  subtitles = [],
  subtitlesEnabled = false,
  subtitleTrack = LanguageCode.EN,
  width = "100%",
  height = "100%",
  volume = 1,
  onClick,
  onReady,
  onMuteToggle,
  onSubtitleChange,
  aspectRatio,
  ...props
}: IVideoDisplay<FilePlayer>) => {
  const handleVolumeChange = (evt: Event) => {
    onMuteToggle?.((evt.target as HTMLVideoElement).muted);
  };

  const config = useMemo<FileConfig>(
    () => ({
      file: {
        hlsOptions: {
          startLevel: 8,
          xhrSetup: function (xhr: any) {
            xhr.withCredentials = url?.includes("omgyes.com") ? true : false; // send cookies
          },
        },
      },
      attributes: url?.includes("omgyes.com")
        ? {
            crossOrigin: "use-credentials",
          }
        : {},
      tracks:
        subtitles &&
        Object.values(subtitles).map(
          ({ src, srcLang, label, default: active }) => ({
            kind: "subtitles",
            src: src || "",
            srcLang: srcLang || "",
            label: label || "",
            default: active || srcLang === LanguageCode.EN,
          }),
        ),
    }),
    [subtitles],
  );

  useEffect(() => {
    if (!playerRef) return;

    const video = playerRef.current?.getInternalPlayer() as HTMLVideoElement;
    if (!video) return;

    video.addEventListener("volumechange", handleVolumeChange);

    return () => {
      video.removeEventListener("volumechange", handleVolumeChange);
    };
  }, [playerRef, onMuteToggle]);

  useEffect(() => {
    if (!subtitleTrack || !playerRef) return;

    const video = playerRef.current?.getInternalPlayer() as HTMLVideoElement;
    if (!video) return;

    for (let i = 0; i < video.textTracks.length; i++) {
      const { language } = video.textTracks[i];
      video.textTracks[i].mode =
        language === subtitleTrack && subtitlesEnabled ? "showing" : "disabled";
    }

    const handleTrackChange = () => {
      onSubtitleChange?.(video.textTracks);
    };

    video.textTracks.addEventListener("change", handleTrackChange);
    return () => {
      video.textTracks.removeEventListener("change", handleTrackChange);
    };
  }, [playerRef?.current, subtitlesEnabled, subtitleTrack]);

  return (
    <div className={cn("relative aspect-video", aspectRatio)} onClick={onClick}>
      <ReactPlayer
        playsinline
        stopOnUnmount
        url={url}
        ref={playerRef}
        width={width}
        height={height}
        config={config}
        volume={volume}
        onReady={onReady}
        onError={(err, errorData) => {
          console.log("ReactPlayerContainer :: err -", err);
          console.log("ReactPlayerContainer :: err data -", errorData);
        }}
        style={{ ...style }}
        {...props}
      />
    </div>
  );
};

export default ReactPlayerContainer;
