import { Container } from "@components/ui/Container";
import { Typography } from "@components/ui/Typography";
import VideoPlayer from "@components/ui/VideoPlayer/VideoPlayer";

import useTailwindTheme from "@lib/hooks/useTailwindTheme";
import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type {
  AnimationVideoBlockFields,
  BasicVideoBlockFields,
  CopyBlockFields,
} from "@pages/collections/types/Page.types";

import {
  findComponentsByNameInSection,
  findComponentsByProperty,
} from "@/utils/componentUtils";

const sectionName = "errandsBlock";

const ErrandsBlockVideo = () => {
  const { sections } = useTechniquePagesContext();

  const basicVideoBlock = findComponentsByNameInSection<BasicVideoBlockFields>(
    sections,
    sectionName,
    "basicVideoBlock",
  );
  return (
    <Container className="flex flex-col gap-2 sm:col-span-2" fullWidth>
      <VideoPlayer
        className="rounded-xl"
        captionLocales={basicVideoBlock[0].fields?.video.captionLocales}
        captionSource={basicVideoBlock[0].fields?.video.captionSource}
        poster={`${import.meta.env.VITE_IMAGE_URL}${basicVideoBlock[0].fields?.video.coverImage}`}
        explicit={basicVideoBlock[0].fields?.explicit.isExplicit}
        id={basicVideoBlock[0].fields?.video.id || ""}
        url={`${import.meta.env.VITE_VIDEO_URL}${basicVideoBlock[0].fields?.video.videoUrl}`}
      />
      <figcaption className="flex flex-col gap-1">
        <Typography variant="video-caption-title">
          {basicVideoBlock[0].fields?.subContent.participantName}
        </Typography>

        <Typography variant="video-caption-text">
          {basicVideoBlock[0].fields?.subContent.caption}
        </Typography>
      </figcaption>
    </Container>
  );
};

export const ErrandsBlock = () => {
  const { sections } = useTechniquePagesContext();
  const { isXl } = useTailwindTheme();

  const [fabricBetween] = findComponentsByNameInSection<CopyBlockFields>(
    sections,
    sectionName,
    "copyBlock",
  );

  const animationVideoBlock =
    findComponentsByNameInSection<AnimationVideoBlockFields>(
      sections,
      sectionName,
      "animationVideoBlock",
    );

  const animationsTitle = findComponentsByProperty<{ text: string }>(
    sections,
    "cssClass",
    "animation-title",
  );

  return (
    <Container
      fullWidth
      className="flex flex-col px-4 sm:grid sm:grid-cols-2 sm:px-6 md:px-10 lg:px-14 gap-4 md:gap-5 xl:flex xl:flex-row"
    >
      <Container className="xl:flex xl:flex-col xl:gap-8" fullWidth>
        <div className="flex flex-col gap-1">
          <Typography variant="paragraph-title">
            {fabricBetween.fields?.heading}
          </Typography>
          <Typography variant="paragraph">
            <div
              className="flex flex-col gap-4"
              dangerouslySetInnerHTML={{
                __html: fabricBetween.fields?.copy || "",
              }}
            />
          </Typography>
        </div>
        {isXl && <ErrandsBlockVideo />}
      </Container>

      <Container className="flex flex-col gap-4" fullWidth>
        <VideoPlayer
          className="aspect-auto"
          autoPlay
          muted
          isAnimation
          height="auto"
          loop={animationVideoBlock[0].fields?.video.loop}
          id={animationVideoBlock[0].fields?.video.id || ""}
          url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlock[0].fields?.video.url}`}
        />
        <Typography variant="animation-title" className="text-center">
          <span dangerouslySetInnerHTML={{ __html: animationsTitle[0].text }} />
        </Typography>
      </Container>

      {!isXl && <ErrandsBlockVideo />}
    </Container>
  );
};
