import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import ButtonCta from "@atoms/ButtonCta.tsx";

import { useLocale } from "@lib/contexts/LocaleContext";

export default function PageHeader() {
  const { t } = useTranslation();
  const [mobileNavActive, setMobileNavActive] = useState<boolean>(false);
  const [primary, setPrimary] = useState<boolean>(true);
  const locale = useLocale();
  //TODO@WAR emabledMarketingLangs should be an ENV var
  const enabledMarketingLangs = ["en", "fr"];

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setPrimary(false);
      } else {
        setPrimary(true);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (localStorage.getItem("si")) {
    return <></>;
  }

  return (
    <header
      className={clsx(
        "w-screen h-[64px] flex justify-between items-center fixed px-6 sm:px-10",
        !primary && "bg-white",
      )}
    >
      <div
        className={`fixed top-0 left-full z-30 w-full h-full transition-all ease-out duration-500 ${
          mobileNavActive ? "!left-0" : ""
        }`}
      >
        <div className="text-black bg-white h-full flex flex-col justify-start  duration-0">
          <div>
            <div className="p-20px bg-white flex flex-row justify-between items-center border-b border-b-ui-sea-salt">
              <div className="relative pb-20px">
                <svg
                  className="omgyes-logo h-16px sm:h-20px fill-ui-pepper"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 230 39"
                >
                  <path
                    className="omg"
                    d="M106.407 38.9158C113.157 38.9158 117.456 35.83 120.851 29.3329V38.3779H124V19.2687H102.095V22.7366H119.673C119.318 28.2004 114.608 35.6177 107.064 35.4762C97.9051 35.4762 92.4561 28.8799 92.4561 19.4669C92.4561 10.0538 98.8498 3.4434 106.64 3.4434C113.978 3.4434 118.318 6.74155 120.331 14.0455L123.726 12.7432C122.001 4.74567 116.142 0.00375538 106.763 0.00375538C96.6587 -0.20857 88.4991 8.60998 88.6496 19.4952C88.4991 30.6351 96.5081 39.1848 106.448 38.9724L106.407 38.9158ZM83.5842 38.4345V0.485019H76.8343L63.1432 35.7168L49.4522 0.485019H42.7024V38.3779H46.3717V2.32515L60.4461 38.3779H65.7856L79.8741 2.32515V38.3779L83.5842 38.4345ZM19.058 35.5045C10.6243 35.5045 4.24424 29.1771 4.24424 19.5376C4.24424 9.89805 10.4189 3.47174 19.058 3.47174C27.697 3.47174 33.8306 9.74235 33.8306 19.5093C33.8306 29.2762 27.6012 35.5186 19.0169 35.5186L19.058 35.5045ZM19.0169 0.0179242C15.2814 -0.0157337 11.6204 1.09872 8.49858 3.2199C5.37674 5.34108 2.93481 8.3733 1.48267 11.9318C0.0305393 15.4903 -0.366324 19.4147 0.342448 23.2068C1.05122 26.9988 2.83368 30.4877 5.4636 33.2307C8.09353 35.9736 11.4524 37.8469 15.1138 38.6128C18.7753 39.3788 22.5743 39.0028 26.0287 37.5328C29.4831 36.0626 32.4373 33.5646 34.5162 30.3556C36.5951 27.1467 37.7051 23.3716 37.7053 19.5093C37.7606 16.9483 37.3163 14.4021 36.3989 12.0226C35.4814 9.64302 34.1095 7.47904 32.3652 5.65979C30.6209 3.84054 28.54 2.40333 26.2466 1.43398C23.9532 0.464636 21.4943 -0.0169475 19.0169 0.0179242Z"
                  />
                  <path
                    className="yes"
                    d="M214.317 6.50223C218.494 6.50223 221.155 8.66962 222.302 13.0044L228.403 9.32131C226.369 3.30072 221.197 7.50151e-09 214.754 7.50151e-09C206.878 7.50151e-09 201.555 4.71733 201.555 10.5113C201.555 17.056 205.882 21.0225 214.494 22.3683C220.76 23.4024 222.848 24.9748 222.848 27.7797C222.848 30.5846 219.982 32.3412 215.586 32.3412C210.222 32.3412 206.4 29.253 204.94 23.4024L198.689 27.4681C199.774 31.214 202.175 34.3998 205.418 36.3927C208.49 38.1324 211.937 39.0295 215.437 38.9992C224.309 38.9992 230 34.1261 230 27.5106C230 20.895 226.028 17.9343 217.101 16.1777C211.888 15.1578 208.598 13.5853 208.598 10.5113C208.598 8.17386 210.837 6.4456 214.345 6.4456L214.317 6.50223ZM166.572 0.481642V38.4043H194.54V31.6329H173.547V22.2549H192.383V15.4835H173.547V7.25304H193.953V0.481642H166.572ZM136.353 0.481642H128L141.99 23.3457V38.4043H149.033V23.4024L162.969 0.538319H155.134L145.484 16.5743L136.353 0.481642Z"
                  />
                </svg>
              </div>
              <div onClick={() => setMobileNavActive(!mobileNavActive)}>
                <div className="w-32px h-32px p-4px">
                  <svg
                    viewBox="0 0 64 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="!fill-black"
                    width="100%"
                    height="100%"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.1021 14.1113C14.9048 13.3086 16.2063 13.3086 17.0091 14.1113L32 29.1023L46.9909 14.1113C47.7937 13.3086 49.0952 13.3086 49.8979 14.1113C50.7007 14.9141 50.7007 16.2156 49.8979 17.0183L34.907 32.0093L49.8979 47.0002C50.7007 47.803 50.7007 49.1045 49.8979 49.9072C49.0952 50.71 47.7937 50.71 46.9909 49.9072L32 34.9163L17.0091 49.9072C16.2063 50.71 14.9048 50.71 14.1021 49.9072C13.2993 49.1045 13.2993 47.803 14.1021 47.0002L29.093 32.0093L14.1021 17.0183C13.2993 16.2156 13.2993 14.9141 14.1021 14.1113Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center w-full px-20px py-20px">
            <p>Already have an account?</p>
            <ButtonCta className="!w-144px mt-16px">
              <a href="/login">Login</a>
            </ButtonCta>
          </div>
        </div>
      </div>

      {/* -- Main Nav Container (v2) -- */}
      <div className="w-full h-nav max-w-screen-xl mx-auto flex flex-row items-center justify-between overflow-hidden relative">
        {/* -- Logo -- */}
        <div className="grow shrink flex flex-row items-center">
          <a
            href={`${import.meta.env.VITE_OMGYES_MARKETING_SITE_URL}${
              !enabledMarketingLangs.includes(locale)
                ? `/${locale}/`
                : locale === "en"
                  ? "/join"
                  : `/${locale}/join`
            }`}
            aria-label="OMGYES"
            className="inline-block"
          >
            <svg
              className="omgyes-logo h-16px sm:h-20px fill-ui-pepper"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 230 39"
            >
              <path
                className="omg"
                d="M106.407 38.9158C113.157 38.9158 117.456 35.83 120.851 29.3329V38.3779H124V19.2687H102.095V22.7366H119.673C119.318 28.2004 114.608 35.6177 107.064 35.4762C97.9051 35.4762 92.4561 28.8799 92.4561 19.4669C92.4561 10.0538 98.8498 3.4434 106.64 3.4434C113.978 3.4434 118.318 6.74155 120.331 14.0455L123.726 12.7432C122.001 4.74567 116.142 0.00375538 106.763 0.00375538C96.6587 -0.20857 88.4991 8.60998 88.6496 19.4952C88.4991 30.6351 96.5081 39.1848 106.448 38.9724L106.407 38.9158ZM83.5842 38.4345V0.485019H76.8343L63.1432 35.7168L49.4522 0.485019H42.7024V38.3779H46.3717V2.32515L60.4461 38.3779H65.7856L79.8741 2.32515V38.3779L83.5842 38.4345ZM19.058 35.5045C10.6243 35.5045 4.24424 29.1771 4.24424 19.5376C4.24424 9.89805 10.4189 3.47174 19.058 3.47174C27.697 3.47174 33.8306 9.74235 33.8306 19.5093C33.8306 29.2762 27.6012 35.5186 19.0169 35.5186L19.058 35.5045ZM19.0169 0.0179242C15.2814 -0.0157337 11.6204 1.09872 8.49858 3.2199C5.37674 5.34108 2.93481 8.3733 1.48267 11.9318C0.0305393 15.4903 -0.366324 19.4147 0.342448 23.2068C1.05122 26.9988 2.83368 30.4877 5.4636 33.2307C8.09353 35.9736 11.4524 37.8469 15.1138 38.6128C18.7753 39.3788 22.5743 39.0028 26.0287 37.5328C29.4831 36.0626 32.4373 33.5646 34.5162 30.3556C36.5951 27.1467 37.7051 23.3716 37.7053 19.5093C37.7606 16.9483 37.3163 14.4021 36.3989 12.0226C35.4814 9.64302 34.1095 7.47904 32.3652 5.65979C30.6209 3.84054 28.54 2.40333 26.2466 1.43398C23.9532 0.464636 21.4943 -0.0169475 19.0169 0.0179242Z"
              />
              <path
                className="yes"
                d="M214.317 6.50223C218.494 6.50223 221.155 8.66962 222.302 13.0044L228.403 9.32131C226.369 3.30072 221.197 7.50151e-09 214.754 7.50151e-09C206.878 7.50151e-09 201.555 4.71733 201.555 10.5113C201.555 17.056 205.882 21.0225 214.494 22.3683C220.76 23.4024 222.848 24.9748 222.848 27.7797C222.848 30.5846 219.982 32.3412 215.586 32.3412C210.222 32.3412 206.4 29.253 204.94 23.4024L198.689 27.4681C199.774 31.214 202.175 34.3998 205.418 36.3927C208.49 38.1324 211.937 39.0295 215.437 38.9992C224.309 38.9992 230 34.1261 230 27.5106C230 20.895 226.028 17.9343 217.101 16.1777C211.888 15.1578 208.598 13.5853 208.598 10.5113C208.598 8.17386 210.837 6.4456 214.345 6.4456L214.317 6.50223ZM166.572 0.481642V38.4043H194.54V31.6329H173.547V22.2549H192.383V15.4835H173.547V7.25304H193.953V0.481642H166.572ZM136.353 0.481642H128L141.99 23.3457V38.4043H149.033V23.4024L162.969 0.538319H155.134L145.484 16.5743L136.353 0.481642Z"
              />
            </svg>
          </a>
        </div>
        {/* -- Get it Now / Login Buttons -- */}
        <div className="shrink flex flex-row justify-end items-center">
          <ButtonCta className="h-32px md:h-40px !pl-16px !pr-16px sm:!pl-24px sm:!pr-24px">
            <a
              href={`${import.meta.env.VITE_OMGYES_MARKETING_SITE_URL}${!enabledMarketingLangs.includes(locale) ? `/${locale}/` : `/${locale}/join/pricing`}`}
            >
              {t("series2Landing.seeWhatYouGet.header")}
            </a>
          </ButtonCta>
        </div>
      </div>
    </header>
  );
}
