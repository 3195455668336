import type { SVGProps } from "react";

function IconPosterPlay(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.15511 5.38831C6.77434 5.15561 6.28571 5.42965 6.28571 5.8759V10.1238C6.28571 10.5701 6.77434 10.8441 7.15511 10.6114L10.6307 8.48745C10.9953 8.26464 10.9953 7.73509 10.6307 7.51227L7.15511 5.38831Z"
      />
    </svg>
  );
}
export default IconPosterPlay;
