export const ArrowIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_31_823)">
        <path
          d="M5.21875 2.35156C5.125 5.35678 3.55 7.13069 1 7.36026L1 9.34287C3.55 9.57243 5.125 11.3463 5.21875 14.3516L7.375 13.8298C7.1875 11.7637 5.78125 9.84373 4.28125 9.34287L16 9.34287L16 7.36026L4.28125 7.36026C5.78125 6.85939 7.1875 4.93939 7.375 2.8733L5.21875 2.35156Z"
          fill="#2973DB"
        />
      </g>
      <defs>
        <clipPath id="clip0_31_823">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.351562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
