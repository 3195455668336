import { useCallback, useEffect, useMemo, useState } from "react";
import { TfiCheck, TfiClose, TfiReload, TfiTrash } from "react-icons/tfi";
import type { ActionMeta, MultiValue } from "react-select";
import Select from "react-select";
import type { ColDef } from "ag-grid-community";
import type {
  CustomCellEditorProps,
  CustomCellRendererProps,
} from "ag-grid-react";
import { AgGridReact } from "ag-grid-react";
import _, { intersection } from "lodash";
import {
  atomFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { v4 as uuid } from "uuid";

import type { IconProviderStatus } from "@components/table/ActionCellRenderer.tsx";
import ActionCellRenderer, {
  getActionCellClickHandler,
} from "@components/table/ActionCellRenderer.tsx";

import {
  COUNTRY_CODES,
  CURRENCY_CODES,
  getCountryName,
  getCurrencyCountries,
  getCurrencyName,
} from "@lib/countries.ts";
import {
  getHighlightChangesCellFormatter,
  getHighlightChangesCellStyler,
  getRowUpdateHandler,
} from "@lib/grid.ts";
import type { EditablePricing } from "@pages/admin/ProductCatalog/store.ts";
import {
  editProductPricingsState,
  productDetails,
  resetEdits,
  selectedProductState,
} from "@pages/admin/ProductCatalog/store.ts";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const createNewPricing = (): EditablePricing => ({
  id: "",
  originalId: "",
  amount: 0,
  strikeThrough: 0,
  currency: "",
  countryCodes: [],
  version: "",
});

const SELECT_ALL = "__select_all__";
const CLEAR = "__clear__";

interface EditContext {
  currency: string;
}

export default function PricingsList() {
  const reset = useRecoilValue(resetEdits);
  const productId = useRecoilValue(selectedProductState);
  const product = useRecoilValue(productDetails(productId));
  const [rows, setRows] = useRecoilState(editProductPricingsState);

  const byPricingId = useMemo(() => {
    const withId = (product?.pricings || []).map((data) => ({
      ...data,
      amount: parseFloat(`${data.amount}`) || 0,
      strikeThrough: parseFloat(`${data.strikeThrough}`) || 0,
      originalId: data.id,
    }));
    return _.keyBy(withId, "id");
  }, [product, reset]);

  const lookupById: (id: string) => EditablePricing = useCallback(
    (id: string) => byPricingId[id],
    [byPricingId],
  );

  const defaultEditingContext = useCallback(
    (id: string): EditContext => ({
      currency: lookupById(id)?.currency || "USD",
    }),
    [lookupById],
  );

  const editingContext = useMemo(
    () =>
      atomFamily<EditContext, string>({
        key: "editingContext",
        default: defaultEditingContext,
      }),
    [productId],
  );

  useEffect(() => {
    setRows([...Object.values(byPricingId), createNewPricing()]);
  }, [byPricingId]);

  const highlightChangesCellStyler = useCallback(
    getHighlightChangesCellStyler<EditablePricing>(lookupById),
    [lookupById],
  );

  const highlightChangesCellFormatter = useCallback(
    getHighlightChangesCellFormatter(lookupById, "-"),
    [lookupById],
  );

  const actionHandler = useMemo(
    () => getActionCellClickHandler(lookupById, createNewPricing, setRows),
    [lookupById],
  );

  const removeCountryFromPricing = useCallback(
    (data: EditablePricing, countryCode: string) => {
      if (data.countryCodes.includes(countryCode)) {
        data = {
          ...data,
          countryCodes: data.countryCodes.filter((cc) => cc !== countryCode),
        };

        setRows((pricings) =>
          pricings.map((p) => (p.id === data?.id ? data : p)),
        );
      }
      return data;
    },
    [],
  );

  const columnDefs: ColDef<EditablePricing>[] = useMemo(
    () => [
      {
        headerName: "",
        field: "id",
        editable: false,
        cellRenderer: ActionCellRenderer,
        cellRendererParams: {
          icons: (data: EditablePricing, { isEditing }: IconProviderStatus) =>
            _.merge(
              data.id && { delete: TfiTrash },
              data.originalId &&
                !_.isEqual(data, lookupById(data.originalId)) && {
                  revert: TfiReload,
                },
              isEditing && { done: TfiCheck },
            ),
          onClick: actionHandler,
        },
        cellStyle: highlightChangesCellStyler,
      },
      {
        headerName: "Country",
        field: "countryCodes",
        filter: "agTextColumnFilter",
        autoHeight: true,
        wrapText: true,
        cellEditorPopup: true,
        cellRenderer({ node, data }: CustomCellRendererProps<EditablePricing>) {
          const existingCountryCodes =
            lookupById(data?.id || "")?.countryCodes || [];
          const toFormat = (data?.countryCodes as string[]) || [];
          return (
            <div style={{ lineHeight: "normal" }}>
              {toFormat.map((countryCode) => {
                const countryName = getCountryName(countryCode);
                return (
                  <div key={countryCode}>
                    <TfiClose
                      style={{
                        display: "inline-block",
                        margin: "10px 5px 10px 0px",
                        fontSize: "x-small",
                      }}
                      onClick={() =>
                        node.updateData(
                          removeCountryFromPricing(data!, countryCode),
                        )
                      }
                    />
                    {countryName
                      ? `${countryName} (${countryCode})`
                      : countryCode}
                  </div>
                );
              })}
              {_.without(existingCountryCodes, ...toFormat).map(
                (countryCode) => {
                  const countryName = getCountryName(countryCode);
                  return (
                    <div
                      key={countryCode}
                      className="my-[5px] line-through decoration-red-500 decoration-2"
                    >
                      {countryName
                        ? `${countryName} (${countryCode})`
                        : countryCode}
                    </div>
                  );
                },
              )}
            </div>
          );
        },
        editable: true,
        cellEditor: ({
          node,
          value: values,
          onValueChange,
          eGridCell,
        }: CustomCellEditorProps<EditablePricing, string[]>) => {
          const context = useRecoilValue(editingContext(node.id!));
          const currency = context.currency;

          const countryOptions = useMemo(
            () =>
              getCurrencyCountries(currency)?.map((value) => ({
                value,
                label: `${getCountryName(value)} (${value})`,
              })) || [],
            [currency],
          );

          const defaultValue =
            values?.map((value: string) => ({
              value,
              label: `${getCountryName(value)} (${value})`,
            })) || countryOptions;
          const [selectedValue, setSelectedValue] = useState(defaultValue);

          useEffect(() => {
            onValueChange(_.map(selectedValue, "value"));
          }, [selectedValue]);

          const changeCallback = useCallback(
            (v: MultiValue<any>, action: ActionMeta<{ value: string }>) => {
              switch (action.option?.value) {
                case SELECT_ALL:
                  return setSelectedValue([...countryOptions]);
                case CLEAR:
                  return setSelectedValue([]);
                default:
                  return setSelectedValue([...v]);
              }
            },
            [],
          );

          return (
            <Select
              value={selectedValue}
              isMulti
              styles={{
                container: () => ({
                  width: eGridCell.style.width,
                }),
              }}
              classNames={{
                control: () => "w-full",
              }}
              options={[
                {
                  label: "",
                  options: [
                    { value: "__clear__", label: "Clear" },
                    { value: "__select_all__", label: "Select All" },
                  ],
                },
                {
                  label: "Country",
                  options: countryOptions,
                },
              ]}
              onChange={changeCallback}
            />
          );
        },
        cellEditorParams: {
          values: COUNTRY_CODES,
        },
        suppressKeyboardEvent: (params) => {
          return params.editing && params.event.key === "Enter";
        },
        cellStyle: highlightChangesCellStyler,
      },
      {
        headerName: "Amount",
        field: "amount",
        filter: "agNumberColumnFilter",
        cellDataType: "number",
        editable: true,
        cellEditor: "agNumberCellEditor",
        cellStyle: highlightChangesCellStyler,
        valueFormatter: highlightChangesCellFormatter,
      },
      {
        headerName: "Strike Through",
        field: "strikeThrough",
        filter: "agNumberColumnFilter",
        cellDataType: "number",
        editable: true,
        cellEditor: "agNumberCellEditor",
        cellStyle: highlightChangesCellStyler,
        valueFormatter: highlightChangesCellFormatter,
      },
      {
        headerName: "Currency",
        field: "currency",
        filter: "agTextColumnFilter",
        valueFormatter: (param) => {
          const maybeValue = highlightChangesCellFormatter(param) || "";
          const currencyName = getCurrencyName(maybeValue);
          return currencyName ? `${currencyName} (${maybeValue})` : maybeValue;
        },
        editable: true,
        autoHeight: true,
        wrapText: true,
        cellEditor: ({
          eGridCell,
          value,
          onValueChange,
          node,
        }: CustomCellEditorProps<EditablePricing, string>) => {
          const updateContext = useSetRecoilState(editingContext(node.id!));

          value = value || "USD";
          const defaultValue = {
            value,
            label: `${getCurrencyName(value)} (${value})`,
          };
          const [selectedValue, setSelectedValue] = useState(defaultValue);

          useEffect(() => {
            updateContext((ctx) => ({ ...ctx, currency: selectedValue.value }));
            onValueChange(selectedValue.value);
          }, [selectedValue]);

          return (
            <Select
              value={selectedValue}
              styles={{
                container: () => ({
                  width: eGridCell.style.width,
                }),
              }}
              options={CURRENCY_CODES.map((value) => ({
                value,
                label: `${getCurrencyName(value)} (${value})`,
              }))}
              onChange={(v) => setSelectedValue({ ...v! })}
            />
          );
        },
        cellEditorParams: {
          values: CURRENCY_CODES,
        },
        cellEditorPopup: true,
        cellStyle: highlightChangesCellStyler,
      },
      {
        headerName: "Valid From",
        field: "startDate",
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "agDateCellEditor",
        cellStyle: highlightChangesCellStyler,
        valueFormatter: highlightChangesCellFormatter,
      },
      {
        headerName: "Valid Until",
        field: "endDate",
        filter: "agDateColumnFilter",
        editable: true,
        cellEditor: "agDateCellEditor",
        cellStyle: highlightChangesCellStyler,
        valueFormatter: highlightChangesCellFormatter,
      },
      {
        headerName: "Exp. Version",
        field: "version",
        filter: "agTextColumnFilter",
        editable: true,
        cellStyle: highlightChangesCellStyler,
        valueFormatter: highlightChangesCellFormatter,
      },
    ],
    [
      lookupById,
      actionHandler,
      highlightChangesCellStyler,
      highlightChangesCellFormatter,
    ],
  );

  //TODO how do we validate countryCodes and currency?
  const onRowEdit = useMemo(
    () =>
      getRowUpdateHandler<EditablePricing>(
        lookupById,
        ["countryCodes", "currency", "startDate", "endDate", "version"],
        createNewPricing,
        setRows,
        ({ data }) => data.id,
        uuid,
        [
          ({ originalId, countryCodes }: EditablePricing) =>
            !countryCodes.length &&
            originalId &&
            "Pricing must have at least one country",
          ({ currency, countryCodes }: EditablePricing) =>
            intersection(countryCodes, getCurrencyCountries(currency))
              .length !== countryCodes.length &&
            "Pricing must only be allowed in countries that accept the selected currency",
        ],
      ),
    [],
  );

  return (
    <div className="ag-theme-quartz w-full h-full">
      {productId ? (
        <AgGridReact<EditablePricing>
          reactiveCustomComponents={true}
          getRowId={({ data }) => data.id}
          autoSizeStrategy={{ type: "fitCellContents" }}
          editType="fullRow"
          onRowEditingStarted={({ api, node }) => {
            api.refreshCells({
              rowNodes: [node],
              columns: ["id", "currency", "countryCodes"],
              force: true,
            });
          }}
          popupParent={document.querySelector("body")}
          // @ts-ignore
          columnDefs={columnDefs}
          rowData={rows.map((row) => ({ ...row }))}
          onRowValueChanged={onRowEdit}
        />
      ) : (
        "Select a product in the product list"
      )}
    </div>
  );
}
