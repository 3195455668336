import { useQueryAuthenticatedUser } from "../../queries/use-query-authenticated-user";

export const useAuthenticatedSession = () => {
  const { data, isLoading } = useQueryAuthenticatedUser({
    select: ({ accessToken, idToken }) => ({ accessToken, idToken }),
  });

  return {
    isLoading,
    idToken: data?.idToken?.jwt,
    accessToken: data?.accessToken?.jwt,
  };
};
