// TestimonialSection.tsx
import React from "react";

import { Container } from "@components/ui/Container";

import { TestimonialCarousel } from "@pages/collections/components/TestimonialCarousel/TestimonialCarousel.tsx";

interface QuoteSliderData {
  fields?: {
    heading?: string;
    quotes?: string[];
    image?: {
      path?: string;
    };
  };
}

interface TestimonialSectionProps {
  index: number;
  quoteSliderData: QuoteSliderData[];
  defaultTitle?: string;
  technique?: string;
}

export const TestimonialSection: React.FC<TestimonialSectionProps> = ({
  index,
  quoteSliderData,
  defaultTitle = "Perspectives",
  technique,
}) => {
  const bannerData = quoteSliderData[index];

  if (!bannerData?.fields?.quotes?.length) {
    return null; // Don't render if no data for this index
  }

  // Transform the quotes format to match what TestimonialCarousel expects
  const testimonials = bannerData.fields.quotes.map((quote, quoteIndex) => ({
    id: quoteIndex,
    quote,
  }));

  return (
    <Container fullWidth className="testimonial-section">
      <div className="relative w-full overflow-hidden">
        {/* Left color extension, colors can be mapped in testimonial-section-background-colors.ts */}
        <div
          className={`absolute top-0 bottom-0 left-0 h-full w-1/2 bg-${technique}-testimonial${index}-left left-1/2`}
        />

        {/* Right color extension, colors can be mapped in testimonial-section-background-colors.ts */}
        <div
          className={`absolute top-0 bottom-0 left-0 h-full w-1/2 bg-${technique}-testimonial${index}-right right-1/2`}
        />

        <div className="max-w-[1040px] mx-auto relative">
          <TestimonialCarousel
            testimonials={testimonials}
            title={bannerData.fields.heading || defaultTitle}
            image={`${import.meta.env.VITE_IMAGE_URL}${bannerData.fields.image?.path || ""}`}
          />
        </div>
      </div>
    </Container>
  );
};
