import React from "react";
import type { T } from "vitest/dist/chunks/environment.d8YfPkTm.js";

import { cn } from "@lib/utils";

import FormError from "./FormError";
import FormLabel from "./FormLabel";

export interface FormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({ className }, ref) => (
    <input
      ref={ref}
      className={cn(
        "block w-full px-4 py-3 rounded-lg border-0 bg-[#F3F3F3]",
        className,
      )}
    />
  ),
);

FormInput.displayName = "FormInput";

export interface FormInputWrapperProps<T> extends React.InputHTMLAttributes<T> {
  error?: { message?: string };
  defaultErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  register: () => Record<string, any>;
  name?: string;
  placeholder?: string;
  label?: string;
}

export const FormInputWrapper = React.forwardRef<
  HTMLInputElement,
  FormInputWrapperProps<T>
>(
  (
    {
      error,
      defaultErrorMessage,
      className,
      labelClassName,
      inputClassName,
      errorClassName,
      register,
      ...props
    }: FormInputWrapperProps<any>,
    ref,
  ) => (
    <div className={className || "w-full"} ref={ref}>
      <FormLabel className={labelClassName} htmlFor={props.name}>
        {props.label}
      </FormLabel>

      <FormInput
        className={inputClassName}
        aria-invalid={!!error}
        {...props}
        {...register()}
      />

      <FormError
        className={errorClassName}
        hidden={!(error?.message || defaultErrorMessage)}
      >
        {error?.message || defaultErrorMessage || ""}
      </FormError>
    </div>
  ),
);

FormInputWrapper.displayName = "FormInputWrapper";
