import type React from "react";

import { NavigationCard } from "./NavigationCard";

interface NavigationGroupProps {
  prevTitle: string;
  nextTitle: string;
  prevDescription: string;
  nextDescription: string;
  prevIcon: React.ReactNode;
  nextIcon: React.ReactNode;
  onPrevious?: () => void;
  onNext?: () => void;
}

export const NavigationGroup: React.FC<NavigationGroupProps> = ({
  prevTitle,
  nextTitle,
  prevDescription,
  nextDescription,
  prevIcon,
  nextIcon,
  onPrevious,
  onNext,
}) => {
  return (
    <div className="grid grid-cols-2 gap-4 mt-14 px-4 md:px-0">
      <NavigationCard
        direction="prev"
        title={prevTitle}
        description={prevDescription}
        icon={prevIcon}
        onClick={onPrevious}
      />

      <NavigationCard
        direction="next"
        title={nextTitle}
        description={nextDescription}
        icon={nextIcon}
        onClick={onNext}
      />
    </div>
  );
};
