export function LeveringIcon({ className }: { className?: string }) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 62.6667C48.9367 62.6667 62.6667 48.9367 62.6667 32C62.6667 15.0633 48.9367 1.33333 32 1.33333C15.0633 1.33333 1.33333 15.0633 1.33333 32C1.33333 48.9367 15.0633 62.6667 32 62.6667ZM32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="#D8591C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6668 12.8C13.188 12.8 12.8001 13.1881 12.8001 13.6667C12.8001 14.1453 13.188 14.5334 13.6668 14.5334H37.9334C44.1558 14.5334 49.2001 19.5776 49.2001 25.8V50.0667C49.2001 50.5453 49.588 50.9334 50.0668 50.9334C50.5454 50.9334 50.9334 50.5453 50.9334 50.0667V25.8C50.9334 18.6203 45.1131 12.8 37.9334 12.8H13.6668ZM12.8001 22.3334C12.8001 21.8547 13.1882 21.4667 13.6668 21.4667H24.0668C34.1184 21.4667 42.2668 29.6151 42.2668 39.6667V50.0667C42.2668 50.5453 41.8787 50.9334 41.4001 50.9334C40.9215 50.9334 40.5334 50.5453 40.5334 50.0667V39.6667C40.5334 30.5724 33.1611 23.2 24.0668 23.2H13.6668C13.1882 23.2 12.8001 22.812 12.8001 22.3334ZM16.3906 31.8667C15.5119 31.8667 14.6509 31.9415 13.8131 32.085C13.3414 32.1658 12.8934 31.8489 12.8126 31.3771C12.7317 30.9054 13.0486 30.4574 13.5204 30.3766C14.4544 30.2166 15.4133 30.1334 16.3906 30.1334C25.9122 30.1334 33.6001 38.011 33.6001 47.6899C33.6001 48.5371 33.5412 49.3706 33.4274 50.1865C33.3612 50.6606 32.9232 50.9912 32.4492 50.925C31.9751 50.8589 31.6445 50.4209 31.7107 49.9469C31.8135 49.2102 31.8668 48.4567 31.8668 47.6899C31.8668 38.9338 24.9208 31.8667 16.3906 31.8667Z"
        fill="#D8591C"
      />
    </svg>
  );
}
