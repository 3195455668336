import {
  COOKIE_EXPIRATION,
  COOKIE_NAMES,
  COOKIE_VALUES,
  DEFAULT_COOKIE_OPTIONS,
} from "@constants/cookies";
import type { CookieSetOptions } from "universal-cookie";

import { doQuery } from "@lib/query";
import { GET_CLOUDFRONT_COOKIES } from "@pages/cloudfrontGql";

export class CookiesService {
  static async setCloudFrontCookies(
    setCookie: (
      name: string,
      value: string,
      options?: CookieSetOptions,
    ) => void,
    removeCookie: (name: string) => void,
  ): Promise<void> {
    try {
      const data = await doQuery({
        query: GET_CLOUDFRONT_COOKIES,
        transformer: "getCloudfrontCookies",
      });

      (data || []).forEach((cookie: string) => {
        const [name, ...valueParts] = cookie.split("=");
        removeCookie(name);
        setCookie(name, valueParts.join("="), DEFAULT_COOKIE_OPTIONS);
      });
    } catch (error) {
      console.error("Error setting CloudFront cookies:", error);
    }
  }

  static setSessionStartCookie(
    setCookie: (
      name: string,
      value: string,
      options?: CookieSetOptions,
    ) => void,
  ): void {
    setCookie(
      COOKIE_NAMES.SESSION_START,
      new Date().toISOString(),
      DEFAULT_COOKIE_OPTIONS,
    );
  }

  static setNmhPromoCookie(
    setCookie: (
      name: string,
      value: string,
      options?: CookieSetOptions,
    ) => void,
  ): void {
    setCookie(COOKIE_NAMES.NMH_PROMOTION, COOKIE_VALUES.NMH_PROMOTION_ENABLED, {
      ...DEFAULT_COOKIE_OPTIONS,
      expires: COOKIE_EXPIRATION.NEVER,
    });
  }

  static setExplicitMaterialCookie(
    setCookie: (name: string, value: any, options?: CookieSetOptions) => void,
  ): void {
    setCookie(
      COOKIE_NAMES.EXPLICIT_MATERIAL,
      COOKIE_VALUES.EXPLICIT_MATERIAL_ENABLED,
      DEFAULT_COOKIE_OPTIONS,
    );
  }

  static setLocaleCookie(
    setCookie: (
      name: string,
      value: string,
      options?: CookieSetOptions,
    ) => void,
    locale: string,
  ): void {
    setCookie(COOKIE_NAMES.LOCALE, locale, {
      ...DEFAULT_COOKIE_OPTIONS,
      expires: COOKIE_EXPIRATION.NEVER,
    });
  }

  static setExperimentCookie(
    setCookie: (
      name: string,
      value: string,
      options?: CookieSetOptions,
    ) => void,
    experimentId: string,
    variantId: string,
  ): void {
    setCookie(
      COOKIE_NAMES.EXPERIMENT,
      `${experimentId}.${variantId}`,
      DEFAULT_COOKIE_OPTIONS,
    );
  }

  static setRememberMeCookie(
    setCookie: (
      name: string,
      value: string,
      options?: CookieSetOptions,
    ) => void,
    value: boolean,
  ): void {
    setCookie(COOKIE_NAMES.REMEMBER_ME, value.toString(), {
      ...DEFAULT_COOKIE_OPTIONS,
      expires: value ? COOKIE_EXPIRATION.NEVER : COOKIE_EXPIRATION.SESSION,
    });
  }

  static cleanupLegacyStorage(): void {
    localStorage.removeItem("si");
    localStorage.removeItem("ck");
  }
}
