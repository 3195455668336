import type { ReactElement } from "react";
import { useState } from "react";

const TooltipModal = ({
  children,
  text,
}: {
  children: ReactElement;
  text: string;
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="relative inline-block">
      <div className="hidden md:block group">
        <div className="cursor-pointer">{children}</div>
        <div className="	z-100 absolute top-full shadow transform -left-1/10 -translate-x-24 mb-2 hidden group-hover:block ">
          <div className="rounded-lg bg-ui-sugar px-24px py-16px sm:w-[287px] drop-shadow-tooltip-a">
            <span className="date-night-tooltip-text  ">{text}</span>
          </div>
        </div>
      </div>

      <div className="block md:hidden">
        <div className="cursor-pointer" onClick={openModal}>
          {children}
        </div>
        {isModalOpen && (
          <div className="z-90 fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
            <div className="rounded-lg bg-ui-sugar xs:mx-[16px] xs:px-[24px] xs:pt-[32px] xs:pb-[16px] sm:w-[424px]">
              <div className="date-night-tooltip-text mb-[16px]">{text}</div>
              <div
                className="cursor-pointer z-100 my-[9px] mx-[24px] flex justify-center"
                onClick={closeModal}
              >
                <div className="date-night-tooltip-cta-text ">Close</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TooltipModal;
