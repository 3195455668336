import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { CollectionData, NewMemberHomeData } from "@types.ts";
import clsx from "clsx";
import _ from "lodash";

import { useContentful } from "@lib/contexts/ContentfulContext";
import useContentfulLive from "@lib/hooks/useContentfulLive.ts";
import { hexToRgbA } from "@pages/utils.ts";

import { NMH_LOOKUP } from "@/config";

export type CollectionSelectorProps = {
  fields: {
    collection: string;
    description: string;
    bgColor: string;
    backgroundHoverColor: string;
  };
};
export const CollectionSelector = ({ selected }: { selected?: string }) => {
  const navigate = useNavigate();
  const [isHover, setIsHover] = useState<string>("");
  const [publishedCollections, setPublishedCollections] =
    useState<NewMemberHomeData>();
  const { getEntry } = useContentful();

  useEffect(() => {
    getEntry(NMH_LOOKUP["en"], {
      include: 3,
    }).then((data: NewMemberHomeData) => {
      setPublishedCollections(data);
    });
  });
  const { data: fullData } = useContentfulLive(publishedCollections);
  const collections: CollectionData[] | undefined =
    fullData?.fields.collections;

  const showCollection = (v: number) => {
    collections?.length &&
      navigate(`/collection/${_.kebabCase(collections[v]?.fields.collection)}`);
  };

  return (
    <div className={"nmh-selector-container"}>
      <div className={"article-x-padding"}>
        <div className={"article-selector-headline"}>Your collections</div>
      </div>
      <div className={"article-x-padding"}>
        <div className={"collection-container"}>
          {collections?.map(({ fields }, index) => {
            const bgColors = fields.bgColor.split(";");

            const background =
              isHover === _.kebabCase(fields.collection) ||
              selected === _.kebabCase(fields.collection)
                ? `linear-gradient(210deg, ${bgColors[0]}, ${bgColors[0]}, ${hexToRgbA(bgColors[0], 0.3)}) `
                : `linear-gradient(0deg, ${hexToRgbA(bgColors[0], 0.1)}, ${hexToRgbA(bgColors[0], 0.1)})`;
            return (
              <div
                key={index}
                onClick={() => {
                  if (selected !== fields.collection) {
                    showCollection(index);
                  }
                }}
                onMouseEnter={() => setIsHover(_.kebabCase(fields.collection))}
                onMouseLeave={() => setIsHover("")}
                className={clsx(
                  "hover:cursor-pointer",
                  "flex flex-col justify-end",
                  "basis-1/3 gap-8px h-[112px]",
                  "border-2 rounded-xl",
                  "hover:border-none hover:pl-2px hover:pb-2px",
                )}
                style={{
                  borderColor: bgColors[0],
                  background,
                }}
              >
                <div className={"flex flex-col gap-4px p-8px sm:p-12px"}>
                  <p className={"collection-title"}>{fields.collection}</p>
                  <p className={"collection-description"}>
                    {fields.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
