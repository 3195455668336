"use client";

import { useEffect, useState } from "react";

import {
  Carousel,
  type CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/Carousel";
import { Typography } from "@/components/ui/Typography";
import { cn } from "@/lib/utils";

interface Testimonial {
  id: number;
  quote: string;
}

interface TestimonialCarouselProps {
  title: string;
  image: string;
  testimonials: Testimonial[];
}

export function TestimonialCarousel({
  testimonials,
  title,
  image,
}: TestimonialCarouselProps) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (!api) return;

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  const goToSlide = (index: number) => {
    api?.scrollTo(index);
  };

  return (
    <div className="w-full h-[320px] relative">
      <div className="w-full h-full">
        {/* Background Image */}
        <img
          src={image || "/placeholder.svg"}
          alt={title}
          className="absolute inset-0 w-full h-full object-cover"
        />
        {/* Image overlay */}
        {/*TODO@WAR I think this overlay is not in use currently*/}
        {/*<div className="absolute inset-0 bg-black/60" />*/}

        <div className="flex flex-col items-center justify-around h-full ">
          <Carousel
            className="w-full h-full grid grid-rows-3"
            setApi={setApi}
            opts={{ loop: true }}
          >
            <Typography
              variant="headline"
              className="relative flex items-end justify-center"
              customColor="#ffffff"
            >
              {title}
            </Typography>

            <CarouselContent className="h-full">
              {testimonials.map((testimonial) => (
                <CarouselItem
                  key={testimonial.id}
                  className="flex items-start justify-center"
                >
                  <Typography
                    variant="body"
                    className="text-center italic leading-relaxed max-w-2xl mt-8"
                    customColor="#ffffff"
                  >
                    {testimonial.quote}
                  </Typography>
                </CarouselItem>
              ))}
            </CarouselContent>

            {/* Navigation */}
            <div className="absolute bottom-4 left-0 right-0 flex items-center justify-center gap-4 z-10">
              <CarouselPrevious className="relative h-8 w-8 rounded-full border-0 bg-white/20 hover:bg-white/40 text-white" />

              {/* Interactive Navigation Dots */}
              <div className="flex gap-2">
                {testimonials.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => goToSlide(index)}
                    className={cn(
                      "w-2 h-2 rounded-full transition-all duration-300",
                      current === index
                        ? "bg-orange-500 scale-100"
                        : "bg-white/50 scale-90 hover:scale-100",
                    )}
                    aria-label={`Go to slide ${index + 1}`}
                  />
                ))}
              </div>

              <CarouselNext className="relative h-8 w-8 rounded-full border-0 bg-white/20 hover:bg-white/40 text-white" />
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
