import {
  atom,
  atomFamily,
  DefaultValue,
  RecoilLoadable,
  selector,
} from "recoil";

import { Auth, parseAuthResult } from "@lib/authentication";
import { doQuery } from "@lib/query";
import {
  GET_PRODUCT_QUERY,
  LIST_ENTITLEMENTS_QUERY,
  LIST_PRODUCTS_QUERY,
} from "@pages/admin/ProductCatalog/productsGql.ts";

export interface Entitlement {
  id?: number;
  name: string;
}

export interface ProductLocalization {
  id: string;
  name: string;
  description: string;
  languageCode: string;
  version: string;
}

export interface ProductPricing {
  id: string;
  amount: number;
  strikeThrough: number;
  currency: string;
  countryCodes: string[];
  startDate?: Date;
  endDate?: Date;
  version: string;
}

export interface Product {
  id: string;
  entitlements: Entitlement[];
  localizations: ProductLocalization[];
  pricings: ProductPricing[];
}

export enum ProductCatalogField {
  Entitlements,
  Summaries,
}

interface TProductCatalogState {
  entitlements: Entitlement[];
  summaries: Product[];
  products: Record<string, Product>;
  reset?: ProductCatalogField;
  productId?: string;
  currentProduct?: Product;
}

export type EditablePricing = ProductPricing & Editable;
export type EditableLocalization = ProductLocalization & Editable;

export const productCatalogState = atom<TProductCatalogState>({
  key: "productCatalog",
  default: RecoilLoadable.of(
    (async () => {
      const emptyState = {
        entitlements: undefined,
        summaries: undefined,
        products: {},
        productId: undefined,
      };
      try {
        const { accessToken } = parseAuthResult(
          await Auth.currentAuthenticatedUser(),
        );
        if (accessToken) {
          return {
            entitlements: await fetchEntitlements(),
            summaries: await fetchProductSummaries(),
            products: {},
            productId: undefined,
          };
        }

        return emptyState;
      } catch {
        return emptyState;
      }
    })(),
  ),
  effects: [
    ({ setSelf, onSet }) => {
      onSet(async (updatedValue) => {
        switch (updatedValue.reset) {
          case ProductCatalogField.Summaries:
            fetchProductSummaries(setSelf);
            break;

          case ProductCatalogField.Entitlements:
            fetchEntitlements(setSelf);
            break;
        }
        // we want to clear the reset field
        return setSelf({ ...updatedValue, reset: undefined });
      });
    },
  ],
});

async function fetchProductSummaries(
  setState?: (_: (old: any) => TProductCatalogState) => void,
) {
  try {
    const summaries =
      (await doQuery({
        query: LIST_PRODUCTS_QUERY,
        transformer: "listProducts",
      })) || [];
    setState && setState((old) => ({ ...old, summaries }));
    return summaries;
  } catch {
    // console.log('Error fetching product summaries');
  }
}

async function fetchEntitlements(
  setState?: (_: (old: any) => TProductCatalogState) => void,
) {
  try {
    const entitlements =
      (await doQuery({
        query: LIST_ENTITLEMENTS_QUERY,
        transformer: "listEntitlements",
      })) || [];
    setState && setState((old) => ({ ...old, entitlements }));
    return entitlements;
  } catch {
    // console.log('Error fetching entitlements');
  }
}

export const productSummaries = selector<Product[]>({
  key: "productSummaries",
  get: ({ get }) => {
    const state = get(productCatalogState);
    return state.summaries;
  },
  set: ({ set }, summaries) => {
    if (summaries instanceof DefaultValue) {
      return set(productCatalogState, (old) => ({
        ...old,
        reset: ProductCatalogField.Summaries,
      }));
    }
    set(productCatalogState, (old) => ({
      ...old,
      summaries,
    }));
  },
});

export const entitlementsState = selector<Entitlement[]>({
  key: "entitlements",
  get: ({ get }) => {
    const state = get(productCatalogState);
    return state.entitlements;
  },
  set: ({ set }, entitlements) => {
    if (entitlements instanceof DefaultValue) {
      return set(productCatalogState, (old) => ({
        ...old,
        reset: ProductCatalogField.Entitlements,
      }));
    }
    set(productCatalogState, (old) => ({
      ...old,
      entitlements,
    }));
  },
});

export const productDetails = atomFamily<Product, string | undefined>({
  key: "productDetails",
  default: async (productId?: string) =>
    productId
      ? await doQuery({
          query: GET_PRODUCT_QUERY,
          variables: {
            productId,
          },
          transformer: "getProduct",
        })
      : null,
});

export const selectedProductState = selector<string | undefined>({
  key: "selectedProduct",
  get: ({ get }) => {
    return get(productCatalogState).productId;
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      value = undefined;
    }

    set(productCatalogState, (old) => ({
      ...old,
      productId: value as string | undefined,
    }));
  },
});

export interface Editable {
  id: string;
  originalId: string;
  deleted?: boolean;
}

export const editProductLocalizationsState = atom<
  (ProductLocalization & Editable)[]
>({
  key: "editProductLocalizations",
  default: [],
});

export const editProductPricingsState = atom<(ProductPricing & Editable)[]>({
  key: "editProductPricings",
  default: [],
});

export const editEntitlementsState = atom<string[]>({
  key: "editProductEntitlements",
  default: [],
});

export const resetEdits = atom<number>({
  key: "resetEdits",
  default: 0,
});
