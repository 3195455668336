import type { ReactNode } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { Block, Inline } from "@contentful/rich-text-types";
import { BLOCKS } from "@contentful/rich-text-types";
import type { Document } from "@contentful/rich-text-types/dist/types/types";
import type { TypeArticleVideo } from "@types.ts";
import clsx from "clsx";

import type { IVideoPlayer } from "@components/video/VideoPlayer.tsx";
import VideoPlayer from "@components/video/VideoPlayer.tsx";

export interface IVideoMedia extends IVideoPlayer {}

export const ArticleVideo = ({ fields }: { fields: TypeArticleVideo }) => {
  const media: IVideoMedia = {
    id: fields.id,
    url: fields.url,
    poster: fields.poster.fields.file.url,
    header: fields.posterHeader,
    explicit: fields.explicit,
    subtitles: [
      {
        src: fields.subtitle,
        srcLang: "en", //Locale,
        label: "English",
        default: false,
      },
    ],
  };

  return (
    <div
      className={
        fields.description
          ? "article-video"
          : "article-video-without-description"
      }
    >
      <div className={"article-video-content"}>
        <VideoPlayer
          {...(media as IVideoMedia)}
          className="rounded-md border-collapse overflow-hidden video-block"
        />
      </div>
      {(fields.header || fields.description) && (
        <div className={"article-video-text-box"}>
          {fields.header && (
            <div className={"article-video-header"}>{fields.header}</div>
          )}
          {fields.description && (
            <div className={"article-video-description"}>
              {documentToReactComponents(fields.description, {
                renderNode: {
                  [BLOCKS.UL_LIST]: (
                    _node: Block | Inline,
                    children: ReactNode,
                  ) => (
                    <div className={"article-video-description-list-container"}>
                      <ul className={clsx("article-video-description-list")}>
                        {children}
                      </ul>
                    </div>
                  ),
                  [BLOCKS.LIST_ITEM]: (node: Block | Inline) => {
                    const transformedChildren = documentToReactComponents(
                      node as Document,
                      {
                        // renderMark: options.renderMark,
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (
                            _node: Block | Inline,
                            children: ReactNode,
                          ) => children,
                          [BLOCKS.LIST_ITEM]: (
                            _node: Block | Inline,
                            children: ReactNode,
                          ) => children,
                        },
                      },
                    );
                    return (
                      <li className={"article-video-description-list-item"}>
                        {transformedChildren}
                      </li>
                    );
                  },
                  [BLOCKS.PARAGRAPH]: (
                    _node: Block | Inline,
                    children: ReactNode,
                  ) => <div>{children}</div>,
                },
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
