import * as Sentry from "@sentry/react";

export const initializeSentry = () => {
  if (!["dev", "local"].includes(import.meta.env.VITE_ENV)) {
    Sentry.init({
      dsn: "https://1f7def9cfd5ef6acbb4412213b120b68@o4504329267445760.ingest.us.sentry.io/4508140148948992",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.captureConsoleIntegration(),
        Sentry.replayIntegration({ maskAllText: false }),
      ],
      environment: import.meta.env.VITE_ENV,
      // Tracing
      tracesSampleRate: 0.01,
      profilesSampleRate: 0.1,
      tracePropagationTargets: ["localhost", /^https:\/\/*omgyes\.com\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 0.01,
    });
  }
};
