import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { COOKIE_NAMES } from "@constants/cookies";
import { CookiesService } from "@services/CookiesService";
import i18next from "i18next";
import type { CookieSetOptions } from "universal-cookie";

export const useLocale = () => {
  const [cookies, setCookie] = useCookies<string>([COOKIE_NAMES.LOCALE]);
  const [locale, setLocale] = useState("");

  useEffect(() => {
    const queryLng = new URLSearchParams(window.location.search).get("lng");
    const storedLng = localStorage.getItem("i18nextLng");
    const cookieLng = cookies[COOKIE_NAMES.LOCALE];

    let finalLocale = queryLng || cookieLng || storedLng || "en";

    if (finalLocale === "en-US") finalLocale = "en";

    if (finalLocale !== cookieLng) {
      CookiesService.setLocaleCookie(
        (name: string, value: string, options?: CookieSetOptions) =>
          setCookie(name as any, value, options),
        finalLocale,
      );
    }
    if (finalLocale !== storedLng)
      localStorage.setItem("i18nextLng", finalLocale);

    if (locale !== finalLocale) {
      setLocale(finalLocale);
      i18next.changeLanguage(finalLocale);
    }
  }, [cookies, setCookie, locale]);

  return locale;
};
