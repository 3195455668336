import type { PropsWithChildren } from "react";
import clsx from "clsx"; // You may need to install this package

interface FullPageDivProps {
  flexDirection?: "row" | "column";
  justify?: "center" | "between" | "start" | "end";
  align?: "center" | "start" | "end";
  overlay?: boolean;
  className?: string;
}

export const FullPageDiv = ({
  flexDirection = "row",
  justify = "between",
  align = "center",
  overlay,
  className,
  children,
}: PropsWithChildren<FullPageDivProps>) => {
  return (
    <div
      className={clsx(
        "h-full w-full flex",
        {
          "flex-row": flexDirection === "row",
          "flex-col": flexDirection === "column",
          "justify-center": justify === "center",
          "justify-between": justify === "between",
          "justify-start": justify === "start",
          "justify-end": justify === "end",
          "items-center": align === "center",
          "items-start": align === "start",
          "items-end": align === "end",
          "bg-black/5 z-[1024] absolute": overlay,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
