import { Card } from "@components/ui/Card";
import { Container } from "@components/ui/Container";
import { Typography } from "@components/ui/Typography";
import VideoPlayer from "@components/video/VideoPlayer";

import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type {
  AnimationVideoBlockFields,
  BasicVideoBlockFields,
  CopyBlockFields,
} from "@pages/collections/types/Page.types";
import { TechniquePagesCommonSections } from "@pages/collections/types/Page.types";

import SectionHeading from "../../SectionHeading/SectionHeading";

import {
  findComponentsByName,
  findComponentsByNameInSection,
} from "@/utils/componentUtils";

interface TechniqueVideoSectionProps {
  title: string;
  description: string;
  videoId?: string;
  videoUrl?: string;
  posterUrl?: string;
  animationId?: string;
  animationUrl?: string;
  fullWidth?: boolean;
  isAnimation?: boolean;
  isExplicit?: boolean;
}

const TechniqueVideoSection = ({
  title,
  description,
  videoId = "",
  videoUrl = "",
  posterUrl = "",
  animationId = "",
  animationUrl = "",
  fullWidth = true,
  isExplicit,
}: TechniqueVideoSectionProps) => (
  <Card
    className="flex bg-[#f3efec] flex-col h-full py-6 px-4 md:py-8 md:px-6 gap-1 w-full"
    fullWidth={fullWidth}
  >
    {/* Make the card a flex container with h-full */}
    <div className="flex flex-col h-full">
      {/* Text section */}
      <div className="flex-grow mb-4 md:mb-6">
        <Typography variant="technique-title">{title}</Typography>
        <Typography className="mt-1">
          <span dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </div>

      {/* Videos container that grows to fill available space */}
      <div className="flex-shrink-0 flex flex-col justify-between items-center">
        <VideoPlayer
          id={videoId}
          url={videoUrl}
          poster={posterUrl}
          className="rounded-xl overflow-hidden w-full"
          explicit={isExplicit}
        />

        <VideoPlayer
          id={animationId}
          url={animationUrl}
          controls={false}
          loop={true}
          autoPlay={true}
          active={true}
          muted={true}
          aspectRatio="1x1"
          className="max-w-[275px] mt-4"
          isAnimation={true}
        />
      </div>
    </div>
  </Card>
);

export const LeveringTechniqueSection = () => {
  const { sections } = useTechniquePagesContext();

  const techniquesBlockSection = findComponentsByName(
    sections,
    TechniquePagesCommonSections.techniquesBlock,
  );

  const copyBlocks = findComponentsByNameInSection<CopyBlockFields>(
    techniquesBlockSection,
    TechniquePagesCommonSections.techniquesBlock,
    "copyBlock",
  );

  const animationVideoBlocks =
    findComponentsByNameInSection<AnimationVideoBlockFields>(
      techniquesBlockSection,
      TechniquePagesCommonSections.techniquesBlock,
      "animationVideoBlock",
    );

  const basicVideoBlocks = findComponentsByNameInSection<BasicVideoBlockFields>(
    techniquesBlockSection,
    TechniquePagesCommonSections.techniquesBlock,
    "basicVideoBlock",
  );

  return (
    <Container id="techniques" className="global-section-padding-x">
      <SectionHeading title="Techniques" />

      <Container className="global-technique-grid" fullWidth>
        {basicVideoBlocks?.map((video, index) => (
          <TechniqueVideoSection
            key={`technique-${index}`}
            title={copyBlocks?.[index]?.fields?.heading || ""}
            description={copyBlocks?.[index]?.fields?.copy || ""}
            videoId={video?.fields?.video?.id}
            videoUrl={`${import.meta.env.VITE_VIDEO_URL}${video?.fields?.video?.videoUrl}`}
            posterUrl={`${import.meta.env.VITE_IMAGE_URL}${video?.fields?.video?.coverImage}`}
            animationId={animationVideoBlocks?.[index]?.fields?.video?.id}
            animationUrl={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlocks?.[index]?.fields?.video?.url}`}
            isExplicit={basicVideoBlocks?.[index]?.fields?.explicit?.isExplicit}
          />
        ))}
      </Container>
    </Container>
  );
};
