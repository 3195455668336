export const siteLink = (
  path: string,
  queryObj: Record<string, string> = {},
  local = false,
) => {
  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(queryObj)) {
    params.append(key, value);
  }
  const queryString = params.toString();

  if (queryString !== "") {
    path += (path.includes("?") ? "&" : "?") + queryString;
  }

  return local || path.includes("http")
    ? `${path}`
    : `${import.meta.env.VITE_OMGYES_SERVER_URL}${path}`;
};
