import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Typography } from "@components/ui/Typography";

import useTailwindTheme from "@lib/hooks/useTailwindTheme.ts";
import { cn } from "@lib/utils";
import { scrollToAnchor } from "@pages/collections/utils/Page.utils";

import type { NavigationItem } from "./TechniquePageLayout.types";
import { MenuItem } from "./TechniquePageLayoutMenuItem";

import {
  Sidebar as ShadcnSidebar,
  SidebarContent,
  SidebarMenu,
  SidebarProvider,
} from "@/components/ui/Sidebar";
import { useActiveSection } from "@/hooks/useActiveSection";

export function Sidebar({ navItems }: { navItems: NavigationItem[] }) {
  const [hasScrolledEnough, setHasScrolledEnough] = useState(false);
  const activeItemId = useActiveSection(navItems);
  const location = useLocation();
  const { isLg } = useTailwindTheme();

  const getTechniqueId = () => {
    const pathParts = location.pathname.split("/");
    return pathParts[pathParts.length - 1] || "";
  };

  const techniqueId = getTechniqueId();

  // Construct logo path directly from technique ID
  // TODO: Improve it with base url if needed..
  const logoPath = `/members/temp-assets/${techniqueId}-logo-navigation.svg`;

  // Show logo when:
  // 1. Not on mobile
  // 2. User has scrolled at least 20% of page height
  const shouldShowLogo = !isLg && hasScrolledEnough;

  // Track scroll position to determine when user has scrolled 20% of page height
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;

      // Calculate scroll percentage (20% of document height)
      const scrollThreshold = documentHeight * 0.2;

      if (scrollPosition >= scrollThreshold) {
        setHasScrolledEnough(true);
      } else {
        setHasScrolledEnough(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    handleScroll();

    // Clean up event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <ShadcnSidebar
      variant="sidebar"
      collapsible="none"
      className="w-60 border-r-0 bg-dark-aubergine text-white relative"
    >
      <div className="absolute inset-x-0 top-0 h-px bg-[#1B0C32]" />
      <SidebarContent>
        <SidebarMenu className="fixed left-0 w-60">
          <figure
            className={cn(
              "flex flex-col items-center justify-center gap-2 py-[16px] mb-4",
              "transition-opacity duration-300 ease-in-out",
              shouldShowLogo ? "opacity-100" : "opacity-0",
            )}
          >
            <img
              src={logoPath}
              alt={`${techniqueId} Technique Logo`}
              className="w-[64px] h-[64px]"
            />
            <figcaption>
              <Typography variant="sidebar-technique-title">
                {techniqueId}
              </Typography>
            </figcaption>
          </figure>

          <div>
            {navItems.map((item) => {
              const isActive = activeItemId === item.href.substring(1);

              return (
                <MenuItem
                  key={`${item.title}-sidebar-menu-item`}
                  onClick={scrollToAnchor}
                  isActive={isActive}
                  {...item}
                />
              );
            })}
          </div>
        </SidebarMenu>
      </SidebarContent>
    </ShadcnSidebar>
  );
}

export { SidebarProvider };
