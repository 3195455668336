import React, { useState } from "react";

import { DatePicker } from "@components/ui/Datepicker";

import { cn } from "@lib/utils";

import FormLabel from "./FormLabel";
import InputError from "./InputError";

export interface FormDatePickerProps<T> extends React.InputHTMLAttributes<T> {
  error?: { message?: string };
  defaultErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  register: () => Record<string, any>;
  setValue: (x: any) => void;
  name?: string;
  placeholder?: string;
  label?: string;
}

export const FormDatePicker = ({
  error,
  defaultErrorMessage,
  className,
  labelClassName,
  errorClassName,
  register,
  name,
  label,
  setValue,
  placeholder,
}: FormDatePickerProps<any>) => {
  const [date, setDate] = useState<Date | null | undefined>(new Date());
  const handleDateChange = (selectedDate: Date | null | undefined) => {
    setDate(selectedDate);
    setValue(selectedDate);
  };

  return (
    <div className={cn(className || "w-full")}>
      <FormLabel className={labelClassName} htmlFor={name}>
        {label}
      </FormLabel>
      <div className="flex relative h-48px gap-16px">
        <DatePicker
          selected={date}
          onSelect={handleDateChange}
          register={register}
          placeholder={placeholder}
        />
      </div>
      <InputError
        className={errorClassName}
        hidden={!(error?.message || defaultErrorMessage)}
      >
        {error?.message || defaultErrorMessage || ""}
      </InputError>
    </div>
  );
};
