import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useParams } from "react-router-dom";
import { COOKIE_NAMES } from "@constants/cookies";
import { CookiesService } from "@services/CookiesService";
import type { NewMemberHomeData } from "@types.ts";
import type { CookieSetOptions } from "universal-cookie";

import { ArticleSelector } from "@components/ArticleSelector.tsx";
import { CollectionSelector } from "@components/CollectionSelector.tsx";
import AppPageHeader from "@components/common/AppPageHeader.tsx";
import PageFooter from "@components/common/PageFooter.tsx";
import { DateNightSelector } from "@components/DateNightSelector.tsx";
import { MostViewedTechniques } from "@components/MostViewedTechniques.tsx";
import { NerdOutOnResearch } from "@components/NerdOutOnResearch.tsx";
import NmhPromoModal from "@components/NmhPromoModal.tsx";
import TypeformReferralModal from "@components/TypeformReferralModal.tsx";

import { useAuthenticatedUser } from "@lib/authentication";
import { useContentful } from "@lib/contexts/ContentfulContext";
import { selectImageFromContentfulImages } from "@lib/helpers";
import useAnalytics from "@lib/hooks/useAnalytics.ts";
import useContentfulLive from "@lib/hooks/useContentfulLive.ts";

import { NMH_LOOKUP } from "@/config";

const NewMemberHome = () => {
  const params = useParams();
  const [publishedData, setPublishedData] = useState<NewMemberHomeData>();
  const [loaded, setLoaded] = useState(false);
  const { analytics } = useAnalytics();
  const [cookies, setCookie] = useCookies([
    COOKIE_NAMES.NMH_PROMOTION,
    COOKIE_NAMES.TYPEFORM_REFERRAL_MODAL,
  ]);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showNmhPromoModal, setShowNmhPromoModal] = useState(
    !cookies[COOKIE_NAMES.NMH_PROMOTION],
  );
  const location = useLocation();
  const { getEntry } = useContentful();
  const { authenticatedUser, isAuthenticated } = useAuthenticatedUser();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralModalParam = params.get("tfrm");
    const referralModalCookie = cookies[COOKIE_NAMES.TYPEFORM_REFERRAL_MODAL];

    if (referralModalParam === "true" && referralModalCookie !== "d") {
      setShowReferralModal(true);
    }
  }, []);

  useEffect(() => {
    analytics.page({ page: "new_member_home_page", props: params });
  }, []); //removing dependencies to ensure this only fires once when the component mounts

  useEffect(() => {
    getEntry(NMH_LOOKUP[params.locale || "en"], {
      include: 3,
    }).then((data: NewMemberHomeData) => {
      setPublishedData(data);
    });
  }, [params.id]);

  const { data } = useContentfulLive(publishedData);

  if (!data) return <></>;

  const dismissReferralModal = () => {
    setShowReferralModal(false);
    setCookie(COOKIE_NAMES.TYPEFORM_REFERRAL_MODAL, "d", {
      domain: ".omgyes.com",
      expires: new Date("9999-12-31T23:59:59.000Z"),
    });
  };

  const dismissNmhPromoModal = () => {
    setShowNmhPromoModal(false);
    CookiesService.setNmhPromoCookie(
      (name: string, value: string, options?: CookieSetOptions) =>
        setCookie(name as any, value, options),
    );
  };

  return (
    <>
      {showReferralModal && isAuthenticated && (
        <TypeformReferralModal
          userEmail={authenticatedUser.email}
          onClose={dismissReferralModal}
        />
      )}
      {showNmhPromoModal && !showReferralModal && (
        <NmhPromoModal onClose={dismissNmhPromoModal} />
      )}
      <AppPageHeader showLogo={true} />
      <div className="nmh-header flex flex-col bg-black">
        <div className={"nmh-header-background"}>
          <div
            className={"w-full"}
            style={{
              backgroundColor: !loaded
                ? "#000000"
                : data.fields.headerBackgroundColor || "#000000",
            }}
          />
          <div
            className={"w-full"}
            style={{
              backgroundColor: !loaded
                ? "#000000"
                : data.fields.headerBackgroundColorRight || "#000000",
            }}
          />
        </div>
        <div className={"nmh-header-image-container"}>
          <div className={"article-header-image-to-md"}>
            <img
              src={`${selectImageFromContentfulImages(data.fields.headerImages, 360)?.fields.file.url}?w=768`}
              className={"object-cover h-full w-full"}
              onLoad={() => setLoaded(true)}
            />
          </div>
          <div className={"article-header-image-from-md"}>
            <img
              src={`${selectImageFromContentfulImages(data.fields.headerImages, 768)?.fields.file.url}?w=1024`}
              className={"object-cover h-full w-full"}
              onLoad={() => setLoaded(true)}
            />
          </div>
        </div>
        {loaded && (
          <div className="nmh-header-text-container">
            <div className={"article-x-padding"}>
              <div className={"article-max768-container "}>
                <div className="nmh-headline">{data.fields.headline}</div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={"nmh-content"}>
        <CollectionSelector />
        <ArticleSelector />
        <DateNightSelector />
        <MostViewedTechniques />
        <NerdOutOnResearch />
      </div>
      <PageFooter />
    </>
  );
};

export default NewMemberHome;
