import { gql } from "graphql-request";

export const REDEEM_GIFT_CODE_MUTATION = gql`
  mutation redeemGiftCode(
    $giftCode: String!
    $registerUser: RegisterUserInput
    $email: AWSEmail
    $turnstileToken: String!
  ) {
    redeemGiftCode(
      giftCode: $giftCode
      registerUser: $registerUser
      email: $email
      turnstileToken: $turnstileToken
    ) {
      id
      email
      redeemedAt
      transactionId
      userId
    }
  }
`;
