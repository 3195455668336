import type { MouseEventHandler } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { CircleLoader } from "react-spinners";
import { Turnstile } from "@marsidev/react-turnstile";
import _ from "lodash";
import styled from "styled-components";

import { ExclamationCircle } from "@components/atoms/svg/icons/ExclamationCircle";
import { Form, FormError, FormInput, FormSubmit } from "@components/forms";
import BraintreeCheckout from "@components/payment/BraintreeCheckout";

import { doQueryWithApiKey } from "@lib/query";
import type {
  Product,
  ProductPricing,
} from "@pages/admin/ProductCatalog/store";
import { defaultErrorMessages } from "@pages/Auth/LoginForm";
import { formatPrice } from "@pages/utils.ts";

import { LIST_PRODUCTS_FOR_PURCHASE_QUERY } from "./listProductsQuery";

const CreateOrSignInForm = styled(Form)`
  max-width: 450px;
`;

export interface ProductPricingWithProduct extends ProductPricing {
  product: Product;
}

export interface ListProductsQueryVariables {
  email?: string;
  purchasable?: boolean;
  giftcode?: string;
  turnstileToken: string;
}

const lookupKeyByProduct = (productId: string) => {
  if (productId === "series") {
    return "checkoutPage.charge.collection";
  }
  return `checkoutPage.charge.${productId}.collection.explore`;
};

interface SenderDetails {
  email: string;
  name: string;
  cc: boolean;
}

export interface GiftCodeDetails {
  recipientEmail: string;
  name: string;
  message: string;
  sendAt: Date;
  sender: SenderDetails;
}

const Checkout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  // TODO: end to end gifts flow through checkout. this param is legacy
  const giftId = searchParams.get("giftId");
  const [productPricing, setProductPricing] =
    useState<ProductPricingWithProduct>();
  const [turnstileToken, setTurnstileToken] = useState<string>();
  const [formMode, setFormMode] = useState<string>("new");
  const [formData, setFormData] = useState<Record<string, string>>();
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [signUpComplete, setSignUpComplete] = useState<boolean>(false);
  const { register, handleSubmit, getValues } = useForm();
  const { t, i18n } = useTranslation();
  const [emailInput, setEmailInput] = useState("");

  const topBannerStyles: React.CSSProperties = {
    backgroundPosition: "50% 79%",
  };

  const giftDetails: GiftCodeDetails | undefined = giftId
    ? {
        recipientEmail: searchParams.get("recipientEmail") || "",
        name: searchParams.get("recipientName") || "",
        message: searchParams.get("note") || "",
        sendAt: new Date(
          parseInt(
            searchParams.get("deliveryDate") || new Date().getTime().toString(),
          ),
        ),
        sender: {
          email: formData?.email || "",
          name: searchParams.get("name") || "",
          cc: searchParams.get("cc") === "true",
        },
      }
    : undefined;

  useEffect(() => {
    if (!searchParams.get("ppid"))
      setSearchParams(
        {
          ...searchParams,
          ppid: import.meta.env.VITE_DEFAULT_PRODUCT_PRICE_CODE,
        },
        { replace: true },
      );
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (!turnstileToken) return;

    (async () => {
      const variables: ListProductsQueryVariables = {
        turnstileToken,
        // giftcode: productPricing?.version // problem
        purchasable: true,
      };
      if (formMode === "existing" && formData?.email) {
        variables.email = formData.email;
      }
      const results = await doQueryWithApiKey({
        query: LIST_PRODUCTS_FOR_PURCHASE_QUERY,
        variables,
        transformer: "listProductsForPurchase",
      });
      const productPricing = results.find(
        ({ id }: { id: string }) => id === searchParams.get("ppid"),
      );
      if (!productPricing) {
        throw new Error("Invalid product");
      }
      setProductPricing(productPricing);
    })();
  }, [searchParams, formData, formMode, signUpComplete, turnstileToken]);

  const onSubmit = useCallback(async (data: Record<string, string>) => {
    // TODO: emit segment event that includes email in the form
    try {
      setFormData(data);
      setSignUpComplete(true);
    } catch (error) {
      console.log(error);
      return;
    }
  }, []);

  const isSeriesOption = () =>
    productPricing?.product?.id === "series1,series2,series3";

  const FormDefaults: Record<string, Record<string, string>> = {
    new: {
      title: "checkoutPage.create",
      link: "checkoutPage.switchToExisting",
    },
    existing: {
      title: "checkoutPage.existing",
      link: "checkoutPage.switchToCreate",
    },
  };

  const handleChangeFormMode: MouseEventHandler = (event) => {
    event.preventDefault();

    setErrors({});
    setFormMode(formMode === "new" ? "existing" : "new");
  };

  return (
    <div className="flex justify-center pt-64px">
      <div className="max-w-[1040px] w-full">
        <Turnstile
          siteKey={import.meta.env.VITE_CLOUDFLARE_SITEKEY!}
          options={{ size: "invisible" }}
          onSuccess={setTurnstileToken}
        />
        <div
          className="topBlock ng-cloak bg-cover bg-no-repeat bg-checkout-banner mx-auto h-[200px] rounded-lg"
          style={topBannerStyles}
        >
          <div
            className={
              "subtitleOverlay w-full bg-black bg-opacity-40 h-full flex items-center sm:bg-transparent sm:pl-4 md:bg-transparent"
            }
          >
            {giftId ? (
              <p className="text-white font-bold text-2xl leading-[29px] max-w-[450px] uppercase mb-0 sm:max-w-[200px] sm:text-xl sm:leading-6 lang-en:sm:max-w-[270px] lang-en:sm:font-bold lang-en:sm:leading-[28px] md:leading-[30px] md:text-[26px] md:max-w-[401px] lg:max-w-[500px]">
                {t("checkoutPage.subtitle.gift")}
              </p>
            ) : (
              <p
                className={
                  "text-white font-bold text-2xl leading-[29px] max-w-[450px] uppercase mb-0 sm:max-w-[200px] sm:text-xl sm:leading-6 lang-en:sm:max-w-[270px] lang-en:sm:font-bold lang-en:sm:leading-[28px] md:leading-[30px] md:text-[26px] md:max-w-[401px] lg:max-w-[500px]"
                }
              >
                <Trans
                  i18nKey={
                    i18n.exists("checkoutPage.subtitle.fiftyOff", {
                      fallbackLng: [],
                    }) &&
                    isSeriesOption() &&
                    !productPricing?.version // this will only be present if a discount was applied
                      ? t("checkoutPage.subtitle.fiftyOff")
                      : t("checkoutPage.subtitle.seriesBoth")
                  }
                  components={{
                    span: <span />,
                  }}
                />
              </p>
            )}
          </div>
        </div>
        {!productPricing && (
          <div className="flex justify-center items-center h-full">
            <CircleLoader />
          </div>
        )}
        {productPricing && (
          <div className="w-full">
            {!signUpComplete && (
              <CreateOrSignInForm onSubmit={handleSubmit(onSubmit, setErrors)}>
                <p className="w-full font-mabry-pro-bold text-18px tracking-tight leading-tight text-center">
                  {t(FormDefaults[formMode].title)}
                </p>
                <Link
                  className="w-full hover:text-cta-hover text-cta text-14px font-mabry-pro-regular cursor-pointer text-center"
                  onClick={handleChangeFormMode}
                  to=""
                >
                  {t(FormDefaults[formMode].link)}
                </Link>
                <FormError hidden={_.isEmpty(errors)}>
                  <ul>
                    {["submit", "email", "password", "confirmPassword"].map(
                      (f: string) =>
                        errors[f] && (
                          <li key={f} className="flex items-center py-1">
                            <ExclamationCircle className="mr-4px inline min-h-14px min-w-14px" />
                            {t(errors[f].message || defaultErrorMessages[f])}
                          </li>
                        ),
                    )}
                  </ul>
                </FormError>

                <FormInput
                  className="mt-2 mb-4 w-full"
                  register={() => register("email", { required: true })}
                  label={t("giftsRedeem.email")}
                  error={errors.email}
                  onChange={(e) => {
                    setErrors({});
                    setEmailInput(e.target.value);
                  }}
                />
                {formMode === "new" && (
                  <>
                    <FormInput
                      className="mb-4 w-full"
                      register={() => register("password", { required: true })}
                      label={t("giftsRedeem.password.choose")}
                      error={errors.email}
                      type="password"
                      onChange={() => setErrors({})}
                    />
                    <FormInput
                      className="mb-4 w-full"
                      register={() =>
                        register("confirmPassword", {
                          required: true,
                          deps: "password",
                          validate: (confirmPassword) =>
                            getValues("password") === confirmPassword ||
                            defaultErrorMessages.confirmPassword,
                        })
                      }
                      label={t("giftsRedeem.password.confirm")}
                      error={errors.email}
                      type="password"
                      onChange={() => setErrors({})}
                    />
                  </>
                )}
                <FormSubmit
                  value={t("checkoutPage.createAccount")}
                  className={"px-40px text-18px mx-auto my-20px mt-40px"}
                />
                {emailInput && (
                  <p className="text-[#717171] font-[MabryPro-Regular] font-medium text-sm leading-[22px] text-center">
                    {t("checkoutPage.confirmationEmail")}{" "}
                    <span className="text-[#2973DB]">{emailInput}</span>
                  </p>
                )}
              </CreateOrSignInForm>
            )}
            {signUpComplete && (
              <div className="flex flex-col w-full items-center">
                <div className="w-1/2 m-40px text-center">
                  <p>
                    <Trans
                      i18nKey={lookupKeyByProduct(productPricing.product.id)}
                      values={{
                        priceData: {
                          price_series_series2_series3_display: formatPrice({
                            amount: productPricing.amount,
                            currency: productPricing.currency,
                          }),
                          price_series_upgrade_display: formatPrice({
                            amount: productPricing.amount,
                            currency: productPricing.currency,
                          }),
                          price_series_display: formatPrice({
                            amount: productPricing.amount,
                            currency: productPricing.currency,
                          }),
                        },
                      }}
                      components={{
                        span: <span />,
                      }}
                    />
                  </p>
                </div>
                <div className="w-1/2">
                  <BraintreeCheckout
                    productPricing={productPricing}
                    turnstileToken={turnstileToken}
                    giftCodeDetails={giftDetails}
                    registerUser={
                      formData?.password
                        ? {
                            email: formData.email,
                            password: formData.password,
                          }
                        : undefined
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
