import type { ReactNode } from "react";
import { createElement, Fragment } from "react";
import * as _ from "lodash";

import { Button } from "@components/ui/Button";

/**
 * Converts HTML string with links to React elements
 * Returns React elements where links are replaced with Button components
 */
export const processHtmlWithLinks = (
  html: string,
  navigate: (path: string) => void,
): ReactNode => {
  if (!html || !html.includes("<a ")) {
    return createElement("span", { dangerouslySetInnerHTML: { __html: html } });
  }

  const parts: ReactNode[] = [];
  // Using a non-global regex for each execution to avoid stateful regex issues
  const regexPattern = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/i;
  let remainingHtml = html;
  let key = 0;

  // Find matches while updating the remaining HTML
  let match = regexPattern.exec(remainingHtml);
  while (match !== null) {
    const matchIndex = match.index;

    // Add text before the link
    if (matchIndex > 0) {
      parts.push(
        createElement("span", {
          key: `text-${key}`,
          dangerouslySetInnerHTML: {
            __html: remainingHtml.substring(0, matchIndex),
          },
        }),
      );
      key += 1;
    }

    // Extract link details and handle potential HTML inside
    const href = match[1];
    // Use a temporary element to safely extract text from potential HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = match[2];
    const linkText = tempDiv.textContent || tempDiv.innerText || match[2];

    // Add the button
    parts.push(
      createElement(
        Button,
        {
          key: `button-${key}`,
          variant: "link",
          onClick: () => navigate(href),
          className: "px-0 py-0 h-auto text-cta hover:text-cta-hover underline",
        },
        linkText,
      ),
    );
    key += 1;

    // Update the remaining HTML and look for the next match
    remainingHtml = remainingHtml.substring(matchIndex + match[0].length);
    match = regexPattern.exec(remainingHtml);
  }

  // Add any remaining text
  if (remainingHtml.length > 0) {
    parts.push(
      createElement("span", {
        key: `text-${key}`,
        dangerouslySetInnerHTML: { __html: remainingHtml },
      }),
    );
  }

  return createElement(Fragment, null, ...parts);
};

export function hexToRgbA(hex: string, alpha: number): string {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error(`Bad Hex: ${hex}`);
  }
  const c = hex.substring(1).split("");
  let d: number;
  if (c.length === 3) {
    d = parseInt("0x" + [c[0], c[0], c[1], c[1], c[2], c[2]].join(""), 16);
  } else {
    d = parseInt("0x" + c.join(""), 16);
  }
  return (
    "rgba(" +
    [(d >> 16) & 255, (d >> 8) & 255, d & 255].join(",") +
    `,${alpha})`
  );
}

export function keep(arr: any[], ...props: any[]) {
  return _.map(arr, _.partialRight(_.pick, ...props));
}

export const formatPrice = ({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) => {
  const formatter = new Intl.NumberFormat("default", {
    style: "currency",
    currency,
    maximumFractionDigits: 0,
  });
  return formatter.format(amount);
};
