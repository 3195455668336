import { gql } from "graphql-request";

export const LIST_ENTITLEMENTS_QUERY = gql`
  query ListEntitlements {
    listEntitlements {
      name
      products {
        id
      }
    }
  }
`;

export const UPDATE_ENTITLEMENTS_MUTATION = gql`
  mutation UpdateEntitlements(
    $toCreate: [EntitlementCreateManyInput!]
    $toDelete: [String!] = []
  ) {
    createManyEntitlements(data: $toCreate, skipDuplicates: true) {
      count
    }
    deleteManyEntitlements(where: { name: { in: $toDelete } }) {
      count
    }
  }
`;

export const LIST_PRODUCTS_QUERY = gql`
  query ListProducts {
    listProducts {
      id
      entitlements {
        name
      }
      pricings {
        id
        amount
        strikeThrough
        currency
        countryCodes
        startDate
        endDate
        version
      }
      localizations {
        id
        name
        description
        languageCode
        version
      }
    }
  }
`;

export const GET_PRODUCT_QUERY = gql`
  query GetProduct($productId: String!) {
    getProduct(where: { id: $productId }) {
      id
      entitlements {
        name
      }
      pricings {
        id
        amount
        strikeThrough
        currency
        countryCodes
        startDate
        endDate
        version
      }
      localizations {
        id
        name
        description
        languageCode
        version
      }
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation UpdateProduct(
    $id: String!
    $newId: String
    $entitlements: [EntitlementWhereUniqueInput!]!
    $localizations: ProductLocalizationsUpdateNestedInput!
    $pricings: ProductPricingsUpdateNestedInput!
  ) {
    updateProduct(
      where: { id: $id }
      data: {
        id: $newId
        entitlements: { set: $entitlements }
        localizations: $localizations
        pricings: $pricings
      }
    ) {
      id
      entitlements {
        name
      }
      pricings {
        id
        amount
        strikeThrough
        currency
        countryCodes
        startDate
        endDate
        version
      }
      localizations {
        id
        name
        description
        languageCode
        version
      }
    }
  }
`;

export const CREATE_PRODUCT_MUTATION = gql`
  mutation CreateProduct(
    $id: String!
    $entitlements: [EntitlementWhereUniqueInput!]!
    $localizations: [ProductLocalizationCreateInput!]!
    $pricings: [ProductPricingCreateInput!]!
  ) {
    createProduct(
      data: {
        id: $id
        entitlements: { connect: $entitlements }
        localizations: { create: $localizations }
        pricings: { create: $pricings }
      }
    ) {
      id
      entitlements {
        name
      }
      pricings {
        id
        amount
        strikeThrough
        currency
        countryCodes
        startDate
        endDate
        version
      }
      localizations {
        id
        name
        description
        languageCode
        version
      }
    }
  }
`;
