import React from "react";

import { cn } from "@lib/utils";

interface FormErrorProps {
  hidden?: boolean;
  children: React.ReactNode;
  className?: string;
}

const FormError: React.FC<FormErrorProps> = ({
  hidden = false,
  children,
  className,
}) => {
  return (
    <span
      className={cn(
        `
          ${hidden ? "hidden" : "flex"}
          rounded-lg
          border
          border-[rgba(192,18,18,0.06)]
          bg-[rgba(192,18,18,0.04)]
          mt-4
          w-full
        `,
        className,
      )}
    >
      {children}
    </span>
  );
};

export default FormError;
