import { useEffect, useState } from "react";
import { useParams } from "react-router";
import throttle from "lodash.throttle";

import useAnalytics from "@lib/hooks/useAnalytics.ts";
import { AnalyticsEvent } from "@root/constants.ts";

type ScrollDepths = {
  10: boolean;
  50: boolean;
  90: boolean;
};

const useScrollDepthTracker = () => {
  const { analytics } = useAnalytics();
  const params = useParams();

  const [scrollDepths, setScrollDepths] = useState<ScrollDepths>({
    10: false,
    50: false,
    90: false,
  });

  useEffect(() => {
    const handleScroll = throttle(() => {
      const scrollTop = window.scrollY;
      const docHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercent = (scrollTop / docHeight) * 100;

      const newDepths = { ...scrollDepths };
      const thresholds: (keyof ScrollDepths)[] = [10, 50, 90];

      thresholds.forEach((threshold) => {
        if (scrollPercent >= threshold && !scrollDepths[threshold]) {
          newDepths[threshold] = true;
          analytics.track({
            event: AnalyticsEvent.SCROLL_DEPTH,
            props: { ...params, scroll_depth: threshold.toString() },
          });
        }
      });

      setScrollDepths(newDepths);
    }, 200); // Throttle the scroll event handler

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollDepths]);

  return scrollDepths;
};

export default useScrollDepthTracker;
