import { useInView } from "react-intersection-observer";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router";

import { Button } from "@components/ui/Button";
import { Card } from "@components/ui/Card";
import { Container } from "@components/ui/Container";
import { Typography } from "@components/ui/Typography";

import { ArrowIcon } from "@pages/collections/toy-techniques/levering/components/ArrowIcon";

import { NavigationGroup } from "../NavigationGroup";
import SectionHeading from "../SectionHeading/SectionHeading";

import type {
  BedroomBlockColumn,
  BedroomBlockColumnItem,
  BedroomBlockProps,
} from "./BedroomBlock.types";

import { useIsMobile } from "@/hooks/useMobile";

export const BedroomBlock = ({
  heading,
  columns,
  techBlockNavigation,
}: BedroomBlockProps) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const buildNavigationRoute = (title: string) => {
    const techniquePageName = title.replaceAll(" ", "-").toLowerCase();
    return `/techniques/${techniquePageName}`;
  };

  const { ref, inView: isInViewport } = useInView({
    threshold: 0,
  });

  return (
    <>
      <Container
        id="bringing-it-to-the-bedroom"
        fullWidth
        className="global-section-padding-x"
      >
        <SectionHeading title={heading} />

        <Container
          className="grid md:grid-cols-2 gap-8 md:gap-5"
          fullWidth={!isMobile}
        >
          {columns.map((column: BedroomBlockColumn) => (
            <Card
              key={column.heading}
              className="flex flex-col gap-5 md:gap-6 border-none shadow-none"
            >
              <Typography variant="bedroom-block-headline">
                {column.heading}
              </Typography>
              {column.items.map((item: BedroomBlockColumnItem) => (
                <div className="flex flex-col gap-1" key={item.heading}>
                  <Typography variant="bedroom-block-title">
                    {item.heading}
                  </Typography>
                  <Typography>
                    {item.renderCopy ? (
                      item.renderCopy()
                    ) : (
                      <span dangerouslySetInnerHTML={{ __html: item.copy }} />
                    )}
                  </Typography>
                </div>
              ))}
            </Card>
          ))}
        </Container>
      </Container>

      <Container fullWidth ref={ref} className="global-section-padding-x">
        <NavigationGroup
          prevTitle={techBlockNavigation.children[0].title}
          nextTitle={techBlockNavigation.children[1].title}
          prevDescription={techBlockNavigation.children[0].subtitle}
          nextDescription={techBlockNavigation.children[1].subtitle}
          prevIcon={
            <Lottie
              play={isInViewport}
              style={{ width: "100%", height: "100%" }}
              path={`${import.meta.env.VITE_IMAGE_URL}${techBlockNavigation.children[0].animation}`}
            />
          }
          nextIcon={
            <Lottie
              play={isInViewport}
              style={{ width: "100%", height: "100%" }}
              path={`${import.meta.env.VITE_IMAGE_URL}${techBlockNavigation.children[1].animation}`}
            />
          }
          onPrevious={() => {
            navigate(
              buildNavigationRoute(techBlockNavigation.children[0].title),
            );
          }}
          onNext={() => {
            navigate(
              buildNavigationRoute(techBlockNavigation.children[1].title),
            );
          }}
        />
      </Container>

      <Container className="mb-14">
        <Button
          variant="link"
          className="w-full flex items-center gap-2"
          onClick={() => navigate("/home")}
        >
          <ArrowIcon />
          Back to home
        </Button>
      </Container>
    </>
  );
};
