import React from "react";

import ButtonCta from "@/components/atoms/ButtonCta.tsx";

interface Hideable {
  $hidden?: boolean;
  children: React.ReactNode;
}

export const FormError: React.FC<Hideable> = ({ $hidden, children }) => (
  <div
    className={`${$hidden ? "hidden" : "flex"} rounded-lg border border-[rgba(192,18,18,0.06)] bg-[rgba(192,18,18,0.04)] mt-4 w-full`}
  >
    <span
      className={`${$hidden ? "hidden" : "block"} p-2 px-3 text-[#C01212] text-sm w-full items-center`}
    >
      {children}
    </span>
  </div>
);

export const FormSuccess: React.FC<Hideable> = ({ $hidden, children }) => (
  <div
    className={`${$hidden ? "hidden" : "flex"} rounded-lg border border-[#E3EFEC] bg-[#F6FAF9] mt-4 w-full`}
  >
    <span
      className={`${$hidden ? "hidden" : "block"} p-2 px-3 text-[#198065] text-sm w-full items-center`}
    >
      {children}
    </span>
  </div>
);

export interface FormInputProps<T> extends React.InputHTMLAttributes<T> {
  error?: { message?: string };
  defaultErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  errorClassName?: string;
  hintClassName?: string;
  register: () => Record<string, any>;
  name?: string;
  placeholder?: string;
  label?: string;
  hint?: string;
  onChange: () => void;
}

export const FormInput = ({
  error,
  defaultErrorMessage,
  className,
  labelClassName,
  hintClassName,
  inputClassName,
  errorClassName,
  onChange,
  register,
  hint,
  ...props
}: FormInputProps<any>) => (
  <div className={className || "w-full"}>
    <label
      className={`${labelClassName || "text-sm text-ui-coal font-normal leading-5 tracking-[0.175px] font-[MabryPro-Regular]"}`}
      htmlFor={props.name}
    >
      {props.label}
    </label>
    <input
      className={`${
        inputClassName ||
        `p-2 block w-full rounded-lg border-2 bg-[#F3F3F3] 
        ${error ? "border-[rgba(192,18,18,0.3)] text-[rgba(192,18,18,0.3)]" : "border-[#F3F3F3] text-slate-700"}
        hover:bg-[#F0F0F0] hover:border-[${error ? "rgba(192,18,18,0.3)" : "#F0F0F0"}]
        focus:bg-white focus:border-[#2973DB] active:bg-white active:border-[#2973DB]`
      }`}
      autoComplete={"none"}
      aria-invalid={!!error}
      {...props}
      {...register()}
      onChange={onChange}
    />
    <span
      className={`${hintClassName || "p-2 px-3 text-ui-coal text-xs w-full items-center"} ${!hint && "hidden"}`}
    >
      {hint || ""}
    </span>
    <span
      className={`${errorClassName || "p-2 px-3 text-[#C01212] text-sm w-full items-center"} ${!(error?.message || defaultErrorMessage) && "hidden"}`}
    >
      {error?.message || defaultErrorMessage || ""}
    </span>
  </div>
);

export const FormSubmit: React.FC<any> = ({ className, ...args }) => (
  <ButtonCta className={className}>
    <input {...args} type="submit" />
  </ButtonCta>
);

export const Form: React.FC<{
  className?: string;
  children: React.ReactNode;
  onSubmit: () => void;
}> = ({ className, children, onSubmit }) => (
  <form
    className={`${className || "flex flex-col mx-auto items-center bg-white rounded-xl w-full"}`}
    onSubmit={onSubmit}
  >
    {children}
  </form>
);

export const setSubmitError = (setErrors: any, err: any) =>
  setErrors((errors: any) => ({ ...errors, submit: err }));
