import type { Dispatch } from "react";
import { useContext } from "react";

import {
  type Action,
  createDataContext,
} from "@lib/contexts/createDataContext";

const ACTIONS = {
  VIDEO_UPDATE: "videoUpdate",
};

type VideoPlayerState = {
  activeVideoId?: string | null;
};

type VideoPlayerActions = {
  setVideo: (d: Dispatch<Action>) => (vId: string | null) => void;
};

const videoPlayerReducer = (
  state: VideoPlayerState,
  action: Action,
): VideoPlayerState => {
  switch (action.type) {
    case ACTIONS.VIDEO_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const setVideo =
  (dispatch: Dispatch<Action>) => async (activeVideoId: string | null) => {
    dispatch({
      type: ACTIONS.VIDEO_UPDATE,
      payload: { activeVideoId },
    });
  };

export const { Provider: VideoPlayerProvider, Context: VideoPlayerContext } =
  createDataContext<VideoPlayerActions, VideoPlayerState>(
    videoPlayerReducer,
    { setVideo },
    { activeVideoId: null },
  );

export const useVideoContext = () => useContext(VideoPlayerContext);
