import type React from "react";

import { cn } from "@lib/utils";

import { Typography } from "@/components/ui/Typography";

interface PercentageHeadlineProps {
  percentage: string;
  headline: React.ReactNode;
  color?: string;
  className?: string;
}

export const PercentageHeadline: React.FC<PercentageHeadlineProps> = ({
  percentage,
  headline,
  color = "#d8591c",
  className,
}) => {
  return (
    <div
      className={cn(
        "pl-4 border-l-2 flex-col justify-center flex content-gap-lg !xl:mb-0",
        className,
      )}
      style={{ borderColor: color }}
    >
      <Typography variant="percentage-headline" customColor={color}>
        {percentage}
      </Typography>
      <Typography variant="percentage-text" customColor={color}>
        {headline}
      </Typography>
    </div>
  );
};
