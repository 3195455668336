import { useEffect, useState } from "react";
import type { NewMemberHomeData } from "@types.ts";
import clsx from "clsx";

import { ArticleCarousel } from "@components/ArticleCarousel.tsx";
import { TechniqueIcon } from "@components/TechniqueIcon.tsx";

import { useContentful } from "@lib/contexts/ContentfulContext";
import { navigateToLegacyTechnique } from "@lib/links.ts";

import { NMH_LOOKUP } from "@/config";

export const MostViewedTechniques = () => {
  const [content, setContent] = useState<NewMemberHomeData["fields"]>();
  const [hover, setHover] = useState<string | number>();

  const { getEntry } = useContentful();

  useEffect(() => {
    getEntry(NMH_LOOKUP["en"], {
      include: 3,
    }).then((data: NewMemberHomeData) => {
      setContent(data?.fields);
    });
  });

  if (!content || content?.mostViewedTechniques?.length === 0) return <></>;

  return (
    <div className={"nmh-selector-container"}>
      <div className={"article-x-padding"}>
        <div className={"article-selector-headline"}>
          {content?.mostViewedTechniquesHeading}
        </div>
      </div>
      <ArticleCarousel
        showing={{
          xs: 3.2,
          sm: 3.2,
          md: 4,
          lg: 4,
        }}
        scrollIconTopMargin={"mt-[-50px]"}
      >
        {content?.mostViewedTechniques.map(({ fields }, index) => {
          return (
            <div
              key={index}
              className={"most-viewed-selector-tile-box hover:cursor-pointer"}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(undefined)}
            >
              <div
                className={"most-viewed-selector-tile relative"}
                onClick={() => navigateToLegacyTechnique(fields.title)}
                style={{
                  background: clsx(
                    fields.collection === "Essentials"
                      ? "radial-gradient(at bottom left, rgba(180, 203, 255, .8) 25%, rgba(180, 203, 255, 1) 70%)"
                      : "",
                    fields.collection === "Inner Pleasure"
                      ? "radial-gradient(at bottom left, rgba(255, 195, 148, .8) 25%,  rgba(255, 195, 148, 1) 70%)"
                      : "",
                    fields.collection === "Toy Pleasure"
                      ? "radial-gradient(at bottom left, rgba(255, 216, 124, .8) 25%, rgba(255, 216, 124, 1) 70%)"
                      : "",
                  ),
                }}
              >
                <div
                  className={clsx(
                    "rounded-xl absolute h-full w-full duration-300 transition-opacity ease-in-out z-20",
                    hover === index ? "opacity-100" : "opacity-0",
                    fields.collection === "Essentials" &&
                      hover === index &&
                      "bg-[#C3D4FD]",
                    fields.collection === "Inner Pleasure" &&
                      hover === index &&
                      "bg-[#FFC394]",
                    fields.collection === "Toy Pleasure" &&
                      hover === index &&
                      "bg-[#FFD87C]",
                  )}
                />
                <div
                  className={clsx(
                    "flex w-full h-full",
                    "border-none rounded-xl",
                    "absolute z-0",
                  )}
                  style={{
                    // backgroundImage: `url(${fields.image.fields.file.url})`,
                    backgroundSize: "cover",
                  }}
                />
                <div className={"most-viewed-selector-tile-content z-40"}>
                  <div className={"most-viewed-selector-tile-content-icon"}>
                    <div className={"rounded-lg justify-center items-center"}>
                      <TechniqueIcon
                        technique={fields}
                        animate={true}
                        hovered={hover === index}
                      />
                    </div>
                  </div>
                  <div className={"flex justify-center"}>{fields.title}</div>
                </div>
              </div>
              <div className={"most-viewed-selector-tile-sub-content"}>
                {fields.description}
              </div>
            </div>
          );
        })}
      </ArticleCarousel>
    </div>
  );
};
