import { useState } from "react";

import { Card } from "@components/ui/Card";
import { Container } from "@components/ui/Container";
import { Quote } from "@components/ui/Quote";
import { Switch } from "@components/ui/Switch";
import { Typography } from "@components/ui/Typography";
import VideoPlayer from "@components/ui/VideoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "@components/ui/VideoPlayer/VideoPlayer.context";

import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type {
  AnimationVideoBlockFields,
  BasicVideoBlockFields,
  CopyBlockFields,
} from "@pages/collections/types/Page.types";
import { TechniquePagesCommonSections } from "@pages/collections/types/Page.types";

import { ContentCard } from "../../ContentCard/ContentCard";
import SectionHeading from "../../SectionHeading/SectionHeading";
import { VideoWithCaption } from "../../VideoWithCaption";

import {
  findComponentsByName,
  findComponentsByNameInSection,
  findComponentsByProperty,
  splitStringWithBasicQuote,
} from "@/utils/componentUtils";

export const ConcealingTechniqueSection = () => {
  const { sections } = useTechniquePagesContext();

  const techniquesBlockSection = findComponentsByName(
    sections,
    TechniquePagesCommonSections.techniquesBlock,
  );

  const copyBlocks = findComponentsByNameInSection<CopyBlockFields>(
    techniquesBlockSection,
    TechniquePagesCommonSections.techniquesBlock,
    "copyBlock",
  );

  const basicQuotesBlocks = findComponentsByProperty<{ text: string }>(
    techniquesBlockSection,
    "cssClass",
    "basic-quote",
  );

  const linksBlocks = findComponentsByProperty<{ text: string }>(
    techniquesBlockSection,
    "cssClass",
    "link",
  );

  const [, quote] = splitStringWithBasicQuote(copyBlocks[4].fields?.copy);

  const basicVideoBlocks = findComponentsByNameInSection<BasicVideoBlockFields>(
    techniquesBlockSection,
    TechniquePagesCommonSections.techniquesBlock,
    "basicVideoBlock",
  );

  const animationVideoBlocks =
    findComponentsByNameInSection<AnimationVideoBlockFields>(
      techniquesBlockSection,
      TechniquePagesCommonSections.techniquesBlock,
      "animationVideoBlock",
    );

  const [switchState, setSwitchState] = useState(false);

  return (
    <VideoPlayerProvider>
      <Container fullWidth id="techniques">
        <div className="global-section-padding-x">
          <SectionHeading title="Techniques" />
        </div>

        <div className="flex flex-col gap-4 sm:px-6 md:px-10 lg:px-14">
          {/* First technique block */}
          <ContentCard
            className="gap-7 sm:gap-7 sm:flex sm:flex-col md:gap-8"
            key={copyBlocks[0].fields?.heading}
            heading={copyBlocks[0].fields?.heading || ""}
            copy={copyBlocks[0].fields?.copy || ""}
          >
            <div className="gap-7 flex flex-col md:gap-8 xl:grid xl:grid-cols-2 xl:gap-5">
              <VideoWithCaption video={basicVideoBlocks[0]} />

              <div className="flex flex-col gap-1">
                <Typography variant="technique-title">
                  {copyBlocks[1].fields?.heading}
                </Typography>

                <Typography>
                  <span
                    className="[&>ul]:list-disc [&>ul]:pl-6 [&>ul]:font-bold [&>ul]:mt-4"
                    dangerouslySetInnerHTML={{
                      __html: copyBlocks[1].fields?.copy || "",
                    }}
                  />
                </Typography>
              </div>
            </div>
          </ContentCard>

          {/* Second technique block */}
          <ContentCard
            className="gap-4 sm:flex sm:flex-col md:grid md:grid-cols-2 md:gap-5"
            key={copyBlocks[2].fields?.heading}
            heading={copyBlocks[2].fields?.heading || ""}
            copy={copyBlocks[2].fields?.copy || ""}
          >
            <Quote className="md:pt-9" text={basicQuotesBlocks[0].text} />

            <div className="flex flex-col gap-4">
              {switchState ? (
                <VideoPlayer
                  className="my-2"
                  controls={false}
                  autoPlay
                  isAnimation
                  id={animationVideoBlocks[0].fields?.video.id || ""}
                  url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlocks[0].fields?.video.url}`}
                  loop={animationVideoBlocks[0].fields?.video.loop}
                />
              ) : (
                <VideoPlayer
                  className="my-2"
                  controls={false}
                  autoPlay
                  isAnimation
                  id={animationVideoBlocks[1].fields?.video.id || ""}
                  url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlocks[1].fields?.video.url}`}
                  loop={animationVideoBlocks[1].fields?.video.loop}
                />
              )}

              <div className="flex items-center justify-center gap-4">
                <Typography className="!text-[#292625]">
                  {linksBlocks[0].text}
                </Typography>
                <Switch
                  checked={switchState}
                  onCheckedChange={setSwitchState}
                  thumbClassName="!h-24px !w-24px data-[state=checked]:translate-x-9"
                  className="data-[state=checked]:bg-[#D8591C] data-[state=unchecked]:bg-[#D8591C] w-[64px] h-[30px]"
                />
                <Typography className="!text-[#292625]">
                  {linksBlocks[1].text}
                </Typography>
              </div>
            </div>

            <VideoWithCaption video={basicVideoBlocks[1]} />
          </ContentCard>

          {/* Third technique block */}
          <ContentCard
            className="gap-7 sm:gap-7 sm:flex sm:flex-col md:gap-8 xl:grid xl:grid-cols-3 xl:gap-x-5 xl:gap-y-7"
            key={copyBlocks[3].fields?.heading}
            heading={copyBlocks[3].fields?.heading || ""}
            copy={copyBlocks[3].fields?.copy || ""}
          >
            <VideoWithCaption
              className="xl:col-span-2"
              video={basicVideoBlocks[2]}
            />

            <div className="flex flex-col gap-7 md:flex-row md:gap-5 xl:col-span-3">
              <VideoPlayer
                className="md:flex-1"
                controls={false}
                autoPlay
                isAnimation
                id={animationVideoBlocks[2].fields?.video.id || ""}
                url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlocks[2].fields?.video.url}`}
                loop={animationVideoBlocks[2].fields?.video.loop}
              />

              <Quote className="md:flex-1" text={basicQuotesBlocks[1].text} />
            </div>
          </ContentCard>

          {/* Fourth technique block */}
          <Card
            className={
              "flex bg-[#EFECE7] flex-col py-6 px-4 sm:py-7 sm:px-5 lg:py-8 lg:px-6 w-full sm:rounded-xl gap-6 md:gap-5 sm:flex sm:flex-col md:grid md:grid-cols-2"
            }
          >
            <div className="flex flex-col gap-1">
              <Typography variant="technique-title">
                {copyBlocks[4].fields?.heading}
              </Typography>

              <Typography>
                <span
                  dangerouslySetInnerHTML={{
                    __html: copyBlocks[4].fields?.copy || "",
                  }}
                />
              </Typography>
              <Quote className="mb-5" text={quote} />
            </div>

            <VideoPlayer
              aspectRatio="aspect-auto"
              controls={false}
              autoPlay
              isAnimation
              id={animationVideoBlocks[3].fields?.video.id || ""}
              url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlocks[3].fields?.video.url}`}
              loop={animationVideoBlocks[3].fields?.video.loop}
            />
          </Card>
        </div>
      </Container>
    </VideoPlayerProvider>
  );
};
