import { Typography } from "@components/ui/Typography";
import VideoPlayer from "@components/ui/VideoPlayer/VideoPlayer";

import type { Component } from "@utils/componentUtils";

import type { BasicVideoBlockFields } from "../types/Page.types";

export const VideoWithCaption = ({
  video,
  className,
}: {
  video: Component<BasicVideoBlockFields>;
  className?: string;
}) => {
  return (
    <div className={className}>
      <VideoPlayer
        className="rounded-xl"
        explicit={video.fields?.explicit.isExplicit}
        poster={`${import.meta.env.VITE_IMAGE_URL}${video.fields?.video.coverImage}`}
        id={video.fields?.video.id || ""}
        url={`${import.meta.env.VITE_VIDEO_URL}${video.fields?.video.videoUrl}`}
        captionLocales={video.fields?.video.captionLocales}
        captionSource={video.fields?.video.captionSource}
      />
      <div className="flex flex-col">
        <figcaption className="flex flex-col mt-2 gap-1">
          <Typography variant="video-caption-title">
            {video.fields?.subContent.participantName}
          </Typography>

          <Typography variant="video-caption-text">
            {video.fields?.subContent.caption}
          </Typography>
        </figcaption>
      </div>
    </div>
  );
};
