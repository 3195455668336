import { cn } from "@lib/utils";

import { Typography } from "../Typography";

interface QuoteProps {
  text: string;
  author?: string;
  className?: string;
}

export const Quote = ({ text, author, className }: QuoteProps) => {
  return (
    <div className={cn("flex flex-col gap-2", className)}>
      <Typography
        variant="body"
        as="span"
        className="text-ui-coal italic pl-2 border-l-2 border-solid border-[#D8591C]"
      >
        {text}
      </Typography>
      {author && (
        <Typography variant="body" as="span" className="text-[#292625]">
          {author}
        </Typography>
      )}
    </div>
  );
};
