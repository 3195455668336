import type { JSX } from "react";
import React from "react";
import { cva, type VariantProps } from "class-variance-authority";

type TypographyVariant =
  | "page-title" // maps to h1 by default
  | "paragraph-title" // maps to h2 by default
  | "section-title" // maps to h2 by default
  | "texture-panel-section-title" // maps to h2 by default
  | "subsection" // maps to h3 by default
  | "texture-panel-section-headline" // maps to h3 by default
  | "animation-title" // maps to h3 by default
  | "percentage-headline" // maps to h4 by default
  | "percentage-text" // maps to span by default
  | "headline" // maps to h4 by default
  | "technique-title" // maps to h5 by default
  | "page-subtitle" // maps to p by default
  | "bedroom-block-headline" // maps to p by default
  | "bedroom-block-title" // maps to p by default
  | "paragraph" // maps to p by default
  | "caption" // maps to span by default
  | "description" // maps to span by default
  | "body" // maps to p by default
  | "caption-text" // maps to span by default
  | "bottom-nav-tag" // maps to span by default
  | "bottom-nav-title" // maps to h4 by default
  | "bottom-nav-text" // maps to span by default
  | "video-caption-title" // maps to span by default
  | "video-caption-text" // maps to span by default
  | "sidebar-technique-title"
  | "texture-panel-item-title" // maps to span by default
  | "texture-panel-item-description"; // maps to span by default

const variantDefaultColors: Record<TypographyVariant, string> = {
  "page-title": "text-[#292625]",
  "paragraph-title": "text-[#292625]",
  "section-title": "text-[#292625]",
  "texture-panel-section-title": "text-[#292625]",
  "texture-panel-section-headline": "text-ui-coal",
  "page-subtitle": "text-ui-coal",
  "animation-title": "text-[#292625]",
  "technique-title": "text-[#292625]",
  "bedroom-block-headline": "text-[#d8591c]",
  "bedroom-block-title": "text-[#292625]",
  "percentage-headline": "text-[#d8591c]",
  "percentage-text": "text-[#d8591c]",
  headline: "text-[#d8591c]",
  subsection: "text-[#292625]",
  paragraph: "text-ui-coal",
  caption: "text-[#4B5563]", // gray-600
  description: "text-ui-coal",
  body: "text-ui-coal",
  "caption-text": "text-ui-coal",
  "bottom-nav-tag": "text-ui-coal",
  "bottom-nav-title": "text-[#292625]",
  "bottom-nav-text": "text-[#292625]",
  "video-caption-title": "text-ui-coal",
  "video-caption-text": "text-[#292625]",
  "sidebar-technique-title": "text-[#fff]",
  "texture-panel-item-title": "text-[#292625]",
  "texture-panel-item-description": "text-[#292625]",
};

const typographyVariants = cva("font-mabry-pro-regular", {
  variants: {
    variant: {
      "page-title": `leading-[24px] md:leading-[36px] text-[20px] md:text-[32px] !font-mabry-pro-medium tracking-normal ${variantDefaultColors["page-title"]}`,
      "paragraph-title": `leading-[24px] md:leading-[28px] text-[16px] md:text-[18px] !font-mabry-pro-medium tracking-normal ${variantDefaultColors["paragraph-title"]}`,
      "page-subtitle": `leading-[12px] md:leading-[18px] text-[12px] md:text-[18px]  ${variantDefaultColors["page-subtitle"]}`,
      "section-title": `text-[18px] leading-[24px] md:text-[20px] md:leading-[28px] !font-mabry-pro-medium tracking-wide font-medium ${variantDefaultColors["section-title"]} uppercase`,
      "texture-panel-section-title": `text-[18px] leading-[24px] md:text-[20px] md:leading-[28px] !font-mabry-pro-medium tracking-normal font-medium ${variantDefaultColors["texture-panel-section-title"]}`,
      "texture-panel-section-headline": `text-[12px] leading-[12px] md:text-[14px] md:leading-[14px] !font-mabry-pro-medium tracking-normal font-bold ${variantDefaultColors["texture-panel-section-headline"]} uppercase`,
      "animation-title": `text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] !font-mabry-pro-medium tracking-normal font-medium ${variantDefaultColors["animation-title"]}`,
      "technique-title": `text-[16px] leading-[24px] md:text-[18px] md:leading-[28px] !font-mabry-pro-medium font-medium ${variantDefaultColors["technique-title"]}`,
      "bedroom-block-headline": `!font-mabry-pro-bold font-bold text-[12px] leading-[12px] tracking-wide uppercase ${variantDefaultColors["headline"]}`,
      "bedroom-block-title": `text-[16px] leading-[24px] md:text-[18px] md:leading-[28px] !font-mabry-pro-medium ${variantDefaultColors["technique-title"]}`,
      "percentage-headline": `text-[48px] leading-[48px]  md:text-[64px] md:leading-[64px] tracking-tight !font-mabry-pro-bold uppercase ${variantDefaultColors["percentage-headline"]}`,
      "percentage-text": `text-[16px] leading-[24px] md:text-[18px] md:leading-[28px] !font-mabry-pro-regular ${variantDefaultColors["percentage-text"]}`,
      subsection: `text-base font-medium leading-normal  ${variantDefaultColors["subsection"]}`,
      headline: `!font-mabry-pro-bold leading-3 tracking-wide  uppercase text-xs ${variantDefaultColors["headline"]}`,
      paragraph: `text-[14px] leading-[20px] md:text-[16px] md:leading-[24px]  ${variantDefaultColors["paragraph"]}`,
      caption: `text-sm leading-normal  ${variantDefaultColors["caption"]}`,
      description: `!font-mabry-pro-bold  ${variantDefaultColors["description"]}`,
      body: `text-[14px] leading-[20px] md:text-[16px] md:leading-[24px]  ${variantDefaultColors["body"]}`,
      "caption-text": `text-sm leading-normal  ${variantDefaultColors["caption-text"]}`,
      "bottom-nav-tag": `text-[14px] md:text-[16px] leading-[18px]  ${variantDefaultColors["bottom-nav-tag"]}`,
      "bottom-nav-title": `text-[16px] md:text-[20px] leading-[2#5452518px] !font-mabry-pro-medium ${variantDefaultColors["bottom-nav-title"]}`,
      "bottom-nav-text": `text-[14px] md:text-[16px] leading-[18px]  ${variantDefaultColors["bottom-nav-text"]}`,
      "video-caption-title": `text-[10px] leading-[10px] md:text-[12px] md:leading-[12px]   ${variantDefaultColors["video-caption-title"]}`,
      "video-caption-text": `text-[12px] leading-[12px] md:text-[14px] md:leading-[14px]  ${variantDefaultColors["video-caption-text"]}`,
      "sidebar-technique-title": `text-[16px] leading-[24px] md:text-[20px] md:leading-[28px] !font-mabry-pro-medium ${variantDefaultColors["sidebar-technique-title"]} capitalize`,
      "texture-panel-item-title": `text-[20px] leading-[28px] !font-mabry-pro-medium ${variantDefaultColors["texture-panel-item-title"]}`,
      "texture-panel-item-description": `text-[16px] leading-[24px] ${variantDefaultColors["texture-panel-item-description"]}`,
    },
    color: {
      default: "", // empty string to allow default colors from variant
      inherit: "text-inherit",
      ...variantDefaultColors,
    },
  },
  defaultVariants: {
    variant: "body",
    color: "default",
  },
});

const variantToElement: Record<TypographyVariant, keyof JSX.IntrinsicElements> =
  {
    "page-title": "h1",
    "section-title": "h2",
    "paragraph-title": "h2",
    "texture-panel-section-title": "h2",
    "texture-panel-section-headline": "h3",
    headline: "h4",
    subsection: "h3",
    "animation-title": "h3",
    "percentage-headline": "h4",
    "percentage-text": "span",
    "technique-title": "h5",
    "page-subtitle": "p",
    "bedroom-block-headline": "p",
    "bedroom-block-title": "p",
    paragraph: "p",
    caption: "span",
    description: "span",
    body: "p",
    "caption-text": "span",
    "bottom-nav-text": "span",
    "bottom-nav-title": "h4",
    "bottom-nav-tag": "span",
    "video-caption-title": "span",
    "video-caption-text": "span",
    "sidebar-technique-title": "span",
    "texture-panel-item-title": "span",
    "texture-panel-item-description": "span",
  };

interface TypographyProps
  extends VariantProps<typeof typographyVariants>,
    Omit<React.HTMLProps<HTMLElement | HTMLAnchorElement>, "color"> {
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  customColor?: string;
}

export const Typography = ({
  children,
  variant = "body",
  as,
  className,
  color,
  customColor,
}: TypographyProps) => {
  const Component =
    as || (variant === "body" ? "p" : variantToElement[variant || "body"]);

  // Handle customColor properly by applying it directly as a style prop instead of a class
  const combinedClassName = typographyVariants({
    variant,
    color: customColor ? "inherit" : color, // Use 'inherit' when customColor is provided
    className,
  });

  return (
    <Component
      className={combinedClassName}
      style={customColor ? { color: customColor } : undefined}
    >
      {children}
    </Component>
  );
};
