import "./TriangleIcon.css";

import { cn } from "@/lib/utils";

export function TriangleIcon({ isActive }: { isActive: boolean }) {
  return (
    <div
      className={cn(
        "absolute -right-5 w-[50px] h-[100px] aspect-[1/2] triangle-mask bg-dark-aubergine",
        "group-hover:transition-all group-hover:block group-hover:bg-white",
        "hidden",
        isActive && "block bg-white",
      )}
    />
  );
}
