import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { useLocale } from "@lib/contexts/LocaleContext";
import useAnalytics from "@lib/hooks/useAnalytics";
import { AnalyticsEvent } from "@root/constants";

interface SimulationComponentProps {
  simulationId: string;
  height?: string;
  className?: string;
}

const SimulationComponent: React.FC<SimulationComponentProps> = ({
  simulationId,
  height = "700px",
  className = "",
}) => {
  const { analytics } = useAnalytics();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [simulationUrl, setSimulationUrl] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const locale = useLocale();

  useEffect(() => {
    if (simulationId) {
      const url = `https://simulation.uat.omgyes.com/#/view?variant=max&pkgs=${simulationId}&lang=${locale}`;
      setSimulationUrl(url);

      analytics.track({
        event: AnalyticsEvent.SIMULATION_LOADED,
        props: {
          simulationId,
          url,
        },
      });
    }
  }, [simulationId, locale, analytics]);

  useEffect(() => {
    // Listen for messages from the iframe
    const handleMessage = (event: MessageEvent) => {
      if (!event.data || typeof event.data !== "object") return;

      switch (event.data.type) {
        case "SHOW_WARNING":
        case "START_SIM":
          setIsFullscreen(true);
          break;
        case "HIDE_WARNING":
        case "END_SIM":
          setIsFullscreen(false);
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleIframeError = (
    event: React.SyntheticEvent<HTMLIFrameElement, Event>,
  ) => {
    setError("Failed to load simulation. Please try again later.");
    setLoading(false);
    console.error("Iframe error:", event);
  };

  return (
    <div
      className={clsx(
        "relative w-full mx-auto",
        className,
        isFullscreen &&
          "fullscreen w-screen h-screen fixed top-0 left-0 z-[101] m-0 p-0",
      )}
    >
      {loading && <div className="text-center py-8">Loading simulation...</div>}

      {error && <div className="text-center text-red-600 py-8">{error}</div>}

      {simulationUrl && (
        <iframe
          ref={iframeRef}
          src={simulationUrl}
          title={`Simulation - ${simulationId}`}
          height={isFullscreen ? "100vh" : height}
          frameBorder="0"
          scrolling="no"
          allowFullScreen
          onLoad={handleIframeLoad}
          onError={handleIframeError}
          className={clsx(
            "border-none w-full",
            isFullscreen &&
              "fullscreen w-screen h-screen fixed top-0 left-0 z-[101] m-0",
          )}
        />
      )}
    </div>
  );
};

export default SimulationComponent;
