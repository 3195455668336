export const COOKIE_NAMES = {
  /**
   * Cookie storing the user's locale/language preference
   */
  LOCALE: "loc",

  /**
   * Cookie indicating NMH promotion status
   * Value: 'd' when set
   */
  NMH_PROMOTION: "_nmhPromo",

  /**
   * Cookie for typeform referral modal status
   * Value: 'd' when dismissed
   */
  TYPEFORM_REFERRAL_MODAL: "_tfrm",

  /**
   * Cookie indicating explicit material acknowledgment
   */
  EXPLICIT_MATERIAL: "_exM",

  /**
   * Cookie storing the session start timestamp
   * Value: ISO timestamp string
   */
  SESSION_START: "sessionStart",

  /**
   * Cookie controlling the display of welcome text
   */
  SHOW_WELCOME_TEXT: "showWelcomeText",

  /**
   * Cookie for remembering user login
   */
  REMEMBER_ME: "rememberMe",

  /**
   * Cookie for storing experiment variant
   * Value: Format - "{experimentId}.{variantId}"
   */
  EXPERIMENT: "_gaexp_omgyes",

  /**
   * Cookie for storing experiment variant details
   */
  EXPERIMENT_VARIANT: "_gaexp_variant",
} as const;

/**
 * Common cookie values
 */
export const COOKIE_VALUES = {
  NMH_PROMOTION_ENABLED: "d",
  EXPLICIT_MATERIAL_ENABLED: true,
} as const;

/**
 * Common cookie expiration dates
 */
export const COOKIE_EXPIRATION = {
  NEVER: new Date("9999-12-31T23:59:59.000Z"),
  SESSION: undefined, // Browser session
} as const;

/**
 * Type representing all possible cookie names
 */
export type CookieName = keyof typeof COOKIE_NAMES;

/**
 * Type representing all possible cookie values
 */
export type CookieValue = string | boolean | undefined;

/**
 * Common cookie options interface
 */
export interface CookieOptions {
  path?: string;
  domain?: string;
  expires?: Date;
  sameSite?: "strict" | "lax" | "none";
  secure?: boolean;
}

/**
 * Cookie domain configuration based on environment
 */
export const getCookieDomain = (): string => {
  return ["dev", "local"].includes(import.meta.env.VITE_ENV)
    ? "localhost"
    : ".omgyes.com";
};

/**
 * Default cookie options
 */
export const DEFAULT_COOKIE_OPTIONS: CookieOptions = {
  path: "/",
  domain: getCookieDomain(),
  sameSite: "lax",
} as const;
