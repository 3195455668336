import { useSuspenseQuery } from "@tanstack/react-query";

import type { Sections } from "../types/Page.types";

export const useGetTechniquePageContent = ({
  locale,
  id,
}: {
  locale?: string;
  id?: string;
}) =>
  useSuspenseQuery<{ sections: Sections }>({
    queryKey: ["technique-page-content", locale, id],
    queryFn: () => import(`@data/techniques/${locale}/technique_s3_${id}.json`),
  });
