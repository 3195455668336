import { useMemo } from "react";

import { Container } from "@components/ui/Container";
import { Typography } from "@components/ui/Typography";

import useTailwindTheme from "@lib/hooks/useTailwindTheme";
import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type { CopyBlockFields } from "@pages/collections/types/Page.types";

import "./LessButMoreBlock.styles.css";

import {
  findComponentsByName,
  findComponentsByNameInSection,
  findComponentsByProperty,
} from "@/utils/componentUtils";

const sectionName = "lessButMore";

export const LessButMoreBlock = () => {
  const { sections } = useTechniquePagesContext();
  const { isLg } = useTailwindTheme();

  const lessButMoreSection = findComponentsByName(sections, sectionName);

  const [lessButMore] = findComponentsByNameInSection<CopyBlockFields>(
    lessButMoreSection,
    sectionName,
    "copyBlock",
  );

  const [lessButMoreGraphString] = findComponentsByProperty(
    lessButMoreSection,
    "component",
    "lessButMoreGraph",
  );

  const [lessButMoreGraphDescription] = findComponentsByProperty(
    lessButMoreSection,
    "cssClass",
    "copy",
  );

  const [lessButMoreGraph, lessButMoreLgGraph] = useMemo(() => {
    return lessButMoreGraphString.html
      .replaceAll("\n", "")
      .split(/<div class="graph-container lg">(.*?)<\/div>/);
  }, [lessButMoreGraphString]);

  return (
    <Container className="global-section-padding-x flex flex-col gap-4 md:gap-5 xl:grid xl:grid-cols-3">
      <div className="flex flex-col gap-1">
        <Typography variant="paragraph-title">
          {lessButMore.fields?.heading}
        </Typography>
        <Typography variant="paragraph">
          <div
            className="flex flex-col gap-4"
            dangerouslySetInnerHTML={{
              __html: lessButMore.fields?.copy || "",
            }}
          />
        </Typography>
      </div>

      <div className="flex flex-col gap-4 md:gap-5 xl:col-span-2">
        <Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: isLg ? lessButMoreLgGraph : lessButMoreGraph || "",
            }}
          />
        </Typography>

        <Typography variant="paragraph" className="text-center xl:col-span-3">
          <span
            dangerouslySetInnerHTML={{
              __html: lessButMoreGraphDescription.text || "",
            }}
          />
        </Typography>
      </div>
    </Container>
  );
};
