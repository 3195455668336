import { Navigate, Outlet } from "react-router-dom";

import LoadingInterstitial from "@components/LoadingInterstitial";

import { useAuthenticatedUser } from "@lib/authentication";

interface ProtectedRouteProps {
  redirectTo?: string;
}

export default function ProtectedRoute({
  redirectTo = "/",
}: ProtectedRouteProps) {
  const { isAuthenticated, isLoading } = useAuthenticatedUser();

  if (isLoading) {
    return <LoadingInterstitial />;
  }

  if (!isAuthenticated) {
    return <Navigate to={redirectTo} replace />;
  }

  return <Outlet />;
}
