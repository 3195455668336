export const ExplicitTagIcon = ({ className = "" }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.46639 3.81873C3.63871 3.56503 3.54558 3.23597 3.25839 3.08375C2.9712 2.93153 2.59869 3.01379 2.42637 3.26749C1.35142 4.85014 -0.157905 8.94234 2.42637 12.7472C2.59869 13.0009 2.9712 13.0831 3.25839 12.9309C3.54558 12.7787 3.63871 12.4496 3.46639 12.1959C1.19923 8.85799 2.51991 5.21223 3.46639 3.81873Z"
      fill="currentColor"
    />
    <path
      d="M12.5336 3.81873C12.3613 3.56503 12.4544 3.23597 12.7416 3.08375C13.0288 2.93153 13.4013 3.01379 13.5736 3.26749C14.6486 4.85014 16.1579 8.94234 13.5736 12.7472C13.4013 13.0009 13.0288 13.0831 12.7416 12.9309C12.4544 12.7787 12.3613 12.4496 12.5336 12.1959C14.8008 8.85799 13.4801 5.21223 12.5336 3.81873Z"
      fill="currentColor"
    />
    <path
      d="M4.93845 7.30808C5.06455 7.09995 5.33001 6.98926 5.59206 7.03556L5.74023 7.06174C7.23192 7.32528 8.76792 7.32528 10.2596 7.06174L10.4078 7.03556C10.6698 6.98926 10.9353 7.09995 11.0614 7.30808C11.1875 7.51622 11.1445 7.77273 10.9555 7.93966L8.60633 10.0149V12.4715C8.60633 12.7674 8.33482 13.0072 7.9999 13.0072C7.66498 13.0072 7.39347 12.7674 7.39347 12.4715V10.0148L5.04432 7.93966C4.85535 7.77273 4.81236 7.51622 4.93845 7.30808Z"
      fill="currentColor"
    />
  </svg>
);
