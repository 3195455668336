import React from "react";

import { cn } from "@/lib/utils";

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * When true, the container takes full width.
   * When false, it has max-width, padding, and is centered.
   */
  fullWidth?: boolean;
  /**
   * Element type to render the container as
   * @default "section"
   */
  elementTag?: "div" | "section" | "main";
  /**
   * Optional children to render within the container
   */
  children?: React.ReactNode;
  /**
   * Optional additional className
   */
  className?: string;
}

/**
 * Container component that can be either full width or constrained with padding.
 * Can be rendered as different HTML elements via the variant prop.
 */
const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      fullWidth = false,
      elementTag = "section",
      className,
      children,
      ...props
    },
    ref,
  ) => {
    const Component = elementTag as React.ElementType;

    let baseStyles = fullWidth ? "w-full" : "mx-auto max-w-5xl flex flex-col";

    return (
      <Component ref={ref} className={cn(baseStyles, className)} {...props}>
        {children}
      </Component>
    );
  },
);

Container.displayName = "Container";

export { Container };
