import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { Outlet } from "react-router-dom";

import LoadingInterstitial from "@components/LoadingInterstitial.tsx";

import { useAuthenticatedUser } from "@lib/authentication";

export default function AdminPortal() {
  const navigate = useNavigate();
  const { authenticatedUser } = useAuthenticatedUser();
  const authenticatedUserRef = useRef(authenticatedUser);

  useEffect(() => {
    authenticatedUserRef.current = authenticatedUser;
  }, [authenticatedUser]);

  if (!authenticatedUser) {
    return <LoadingInterstitial />;
  } else if (!(authenticatedUser?.groups || []).includes("admins")) {
    navigate("/");
  }

  return (
    <>
      <Outlet />
    </>
  );
}
