import React from "react";

import { cn } from "@lib/utils";

import FormError from "./FormError";

interface InputErrorProps {
  hidden?: boolean;
  children: React.ReactNode;
  className?: string;
}

const InputError: React.FC<InputErrorProps> = ({
  hidden = false,
  children,
  className,
}) => {
  return (
    <span
      className={cn(
        `
          ${hidden ? "hidden" : "block"}
          px-3 py-2
          text-[#C01212]
          text-sm
          w-full
          items-center
        `,
        className,
      )}
    >
      {children}
    </span>
  );
};

export default InputError;

interface FormErrorWrapperProps {
  hidden?: boolean;
  children: React.ReactNode;
}

export const FormErrorWrapper: React.FC<FormErrorWrapperProps> = ({
  hidden,
  children,
}) => (
  <FormError hidden={hidden}>
    <InputError>{children}</InputError>
  </FormError>
);
