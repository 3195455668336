import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import type { NewMemberHomeData } from "@types.ts";

import { Collection } from "@components/Collection.tsx";
import { CollectionSelector } from "@components/CollectionSelector.tsx";
import AppPageHeader from "@components/common/AppPageHeader.tsx";
import PageFooter from "@components/common/PageFooter.tsx";

import { useContentful } from "@lib/contexts/ContentfulContext";
import { selectImageFromContentfulImages } from "@lib/helpers";
import useContentfulLive from "@lib/hooks/useContentfulLive.ts";

import { NMH_LOOKUP } from "@/config";

const CollectionPage = () => {
  const collectionRef = useRef<null | HTMLDivElement>(null);

  const params = useParams();
  const [publishedData, setPublishedData] = useState<NewMemberHomeData>();
  const [headerImageLoaded, setHeaderImageLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const { getEntry } = useContentful();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [params.id]);

  useEffect(() => {
    getEntry(NMH_LOOKUP[params.locale || "en"], {
      include: 3,
    }).then((data: any) => {
      setPublishedData(data);
    });
  }, [params.id]);

  const { data } = useContentfulLive(publishedData);

  if (!data) return <></>;

  return (
    <>
      <AppPageHeader
        showLogo={true}
        previousPage={"/home"}
        scrolledTitle={"Your Collections"}
      />
      <div className="nmh-header flex flex-col bg-black">
        <div className={"nmh-header-background"}>
          <div
            className={"w-full"}
            style={{
              backgroundColor: !headerImageLoaded
                ? "#000000"
                : data.fields.collectionsHeaderBackgroundColor || "#000000",
            }}
          />
          <div
            className={"w-full"}
            style={{
              backgroundColor: !headerImageLoaded
                ? "#000000"
                : data.fields.collectionsHeaderBackgroundColorRight ||
                  "#000000",
            }}
          />
        </div>
        <div className={"nmh-header-image-container"}>
          <div className={"article-header-image-to-md"}>
            <img
              src={`${selectImageFromContentfulImages(data.fields.collectionsHeaderImages, 360)?.fields.file.url}?w=768`}
              className={"object-cover h-full w-full"}
              onLoad={() => setHeaderImageLoaded(true)}
            />
          </div>
          <div className={"article-header-image-from-md"}>
            <img
              src={`${selectImageFromContentfulImages(data.fields.collectionsHeaderImages, 768)?.fields.file.url}?w=1024`}
              className={"object-cover h-full w-full"}
              onLoad={() => setHeaderImageLoaded(true)}
            />
          </div>
        </div>
        <div className="nmh-header-text-container">
          <div className={"article-x-padding"}>
            <div className={"article-max768-container "}>
              <div className="nmh-headline">{data.fields.headline}</div>
            </div>
          </div>
        </div>
      </div>

      {headerImageLoaded && (
        <div className={"collections-content"}>
          <div ref={collectionRef}>
            <CollectionSelector selected={params.id} />
          </div>
          {params.id && (
            <div className={"article-x-padding"}>
              <div className={"collection-container"}>
                <Collection
                  collections={data.fields.collections}
                  selectedCollection={params.id}
                />
              </div>
            </div>
          )}
          {params.id && (
            <div className={"article-x-padding"}>
              <div className={"collections-return-to-home-container"}>
                <div
                  className={"article-return-to-home"}
                  onClick={() =>
                    navigate("/home", { preventScrollReset: false })
                  }
                >
                  <div className={"h-full"}>
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.5889 13.0139C10.8037 12.8078 10.8037 12.4736 10.5889 12.2675L6.57782 8.41846L10.5889 4.56943C10.8037 4.36332 10.8037 4.02915 10.5889 3.82304C10.3741 3.61693 10.0259 3.61693 9.81109 3.82304L5.41109 8.04526C5.1963 8.25137 5.1963 8.58554 5.41109 8.79165L9.81109 13.0139C10.0259 13.22 10.3741 13.22 10.5889 13.0139Z"
                        fill="#2973DB"
                      />
                    </svg>
                  </div>
                  <div className={"h-[24px] mb-0.5"}>Return to home</div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <PageFooter />
    </>
  );
};

export default CollectionPage;
