import { useNavigate } from "react-router-dom";
import { captureException } from "@sentry/react";
import type { AxiosError } from "axios";
import axios from "axios";
import axiosRetry from "axios-retry";

import useAnalytics from "@lib/hooks/useAnalytics";
import { AnalyticsEvent } from "@root/constants";

import { useQueryAuthenticateUserOnLegacy } from "../../queries/use-query-authenticate-user-on-legacy";
import { useAuthenticatedSession } from "../use-authenticated-session/use-authenticated-session";

axiosRetry(axios, {
  retries: 5,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    const status = error?.response?.status || 0;
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || status >= 400;
  },
});

export const useAuthenticateUserOnLegacy = () => {
  const { analytics } = useAnalytics();
  const searchParams = new URLSearchParams(location.search);
  const { idToken } = useAuthenticatedSession();

  const navigate = useNavigate();
  const redirectUri = `${
    import.meta.env.VITE_OMGYES_SERVER_URL
  }/members/home?${searchParams.toString()}`;
  const { status, error } = useQueryAuthenticateUserOnLegacy({
    redirectUri,
    idToken,
  });

  if (status === "error") {
    captureException(error);
    const axiosError = error as AxiosError;
    const errorDetails = {
      code: axiosError.code,
      message: axiosError.message,
      name: axiosError.name,
      status: axiosError.status,
      headers: axiosError.config?.headers,
      data: axiosError.config?.data,
      config: axiosError.config,
      affectedComponent: "PageLayout",
    };

    analytics.track({
      event: AnalyticsEvent.AUTH_ERROR,
      props: { ...errorDetails },
    });

    navigate("/logout");
  }

  return { canRedirectToCollections: status === "success" };
};
