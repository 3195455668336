import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import type { Article, NewMemberHomeData } from "@types.ts";
import _ from "lodash";

import { ArticleCarousel } from "@components/ArticleCarousel.tsx";

import { useAuthenticatedUser } from "@lib/authentication";
import { useContentful } from "@lib/contexts/ContentfulContext";

import { NMH_LOOKUP } from "@/config";

const BG = "#8F3300";

export const ArticleSelector = ({
  currentArticle,
}: {
  currentArticle?: string;
}) => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState<Article[]>([]);
  const [hover, setHover] = useState<number | undefined>(undefined);
  const { authenticatedUser } = useAuthenticatedUser();

  let sectionTitle;
  let differenceInDays = 0;

  const dateCreated =
    authenticatedUser?.customOmgYesProperties?.firstNmhAccessDate;

  if (dateCreated) {
    const d = new Date(dateCreated);
    differenceInDays = (Date.now() - d.valueOf()) / (1e3 * 3600 * 24);
  }

  if (differenceInDays > 30) {
    sectionTitle = "Explore by theme";
  } else {
    sectionTitle = "Recommended places to start";
  }

  const { getEntry } = useContentful();

  useEffect(() => {
    getEntry(NMH_LOOKUP["en"], {
      include: 3,
    }).then((data: NewMemberHomeData) => {
      setArticles(data?.fields.articles);
    });
  });

  if (articles?.length === 0) return <></>;

  return (
    <div className={"nmh-selector-container"}>
      <div className={"article-x-padding"}>
        <div className={"article-selector-headline"}>
          {authenticatedUser && sectionTitle}
        </div>
      </div>
      <ArticleCarousel
        showing={{
          xs: 3.2,
          sm: 6,
          md: 5,
          lg: 6,
        }}
      >
        {articles
          .filter(
            (x) =>
              (x.fields.slug || _.kebabCase(x.fields.articleHeading)) !==
              currentArticle,
          )
          .map((article, index) => {
            return (
              <div
                key={index}
                className={"article-selector-tile-box hover:cursor-pointer"}
              >
                <div
                  className={"article-selector-tile"} //bg-gradient-to-t from-[#8f3300] hover:from-[#b24001] before:to-60%'}
                  onClick={() =>
                    navigate(
                      `/article/${article.fields.slug || _.kebabCase(article.fields.articleHeading)}`,
                    )
                  }
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(undefined)}
                  style={{
                    borderColor: BG,
                    background:
                      hover === index
                        ? `linear-gradient(to top, rgba(178, 64, 1, 0.8), rgba(178, 64, 1, 0) 60%), url(${article.fields.thumbnailBackground.fields.file.url}?w=200&h=200) left bottom / cover`
                        : `linear-gradient(to top, #8f3300, #8f330000 60%), url(${article.fields.thumbnailBackground.fields.file.url}?w=200&h=200) left bottom / cover`,
                    textShadow: "0px 1px 0px #302627",
                  }}
                >
                  <div className={"article-selector-tile-content"}>
                    {article.fields.articleHeading}
                  </div>
                </div>
              </div>
            );
          })}
      </ArticleCarousel>
    </div>
  );
};
