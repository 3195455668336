import { Container } from "@components/ui/Container";
import VideoPlayer from "@components/ui/VideoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "@components/ui/VideoPlayer/VideoPlayer.context";

import useTailwindTheme from "@lib/hooks/useTailwindTheme";
import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type {
  AnimationVideoBlockFields,
  BasicVideoBlockFields,
  CopyBlockFields,
} from "@pages/collections/types/Page.types";
import { TechniquePagesCommonSections } from "@pages/collections/types/Page.types";

import { ContentCard } from "../../ContentCard/ContentCard";
import SectionHeading from "../../SectionHeading/SectionHeading";
import { VideoWithCaption } from "../../VideoWithCaption";

import {
  findComponentsByName,
  findComponentsByNameInSection,
} from "@/utils/componentUtils";
export const WrappingTechniqueSection = () => {
  const { sections } = useTechniquePagesContext();
  const { isMd } = useTailwindTheme();

  const techniquesBlockSection = findComponentsByName(
    sections,
    TechniquePagesCommonSections.techniquesBlock,
  );

  const copyBlocks = findComponentsByNameInSection<CopyBlockFields>(
    techniquesBlockSection,
    TechniquePagesCommonSections.techniquesBlock,
    "copyBlock",
  );

  const animationVideoBlocks =
    findComponentsByNameInSection<AnimationVideoBlockFields>(
      techniquesBlockSection,
      TechniquePagesCommonSections.techniquesBlock,
      "animationVideoBlock",
    );

  const basicVideoBlocks = findComponentsByNameInSection<BasicVideoBlockFields>(
    techniquesBlockSection,
    TechniquePagesCommonSections.techniquesBlock,
    "basicVideoBlock",
  );

  return (
    <VideoPlayerProvider>
      <Container fullWidth className="flex flex-col" id="techniques">
        <div className="global-section-padding-x">
          <SectionHeading title="Techniques" />
        </div>

        <div className="flex flex-col gap-4">
          {copyBlocks.map((copyBlock, index) => {
            //  We need to render the last two items in a different layout when on a screen larger than md
            if (isMd && index > 2) {
              return null;
            }

            return (
              <ContentCard
                key={copyBlock.fields?.heading}
                heading={copyBlock.fields?.heading || ""}
                copy={copyBlock.fields?.copy || ""}
              >
                <VideoPlayer
                  controls={false}
                  autoPlay
                  isAnimation
                  id={animationVideoBlocks[index].fields?.video.id || ""}
                  url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlocks[index].fields?.video.url}`}
                  loop={animationVideoBlocks[index].fields?.video.loop}
                />

                {/* We have two videos only in the second technique section block */}
                {index === 1 && (
                  <Container
                    className="flex flex-col gap-6 mt-1 sm:col-span-2 sm:flex-row sm:gap-4 sm:justify-between"
                    fullWidth
                  >
                    {basicVideoBlocks.map((basicVideoBlock) => (
                      <div
                        key={basicVideoBlock.fields?.video.id}
                        className="sm:flex-1"
                      >
                        <VideoWithCaption video={basicVideoBlock} />
                      </div>
                    ))}
                  </Container>
                )}
              </ContentCard>
            );
          })}

          <div className="flex flex-row gap-4">
            {isMd &&
              copyBlocks.map((copyBlock, index) => {
                if (index < 3) {
                  return null;
                }

                return (
                  <ContentCard
                    className="md:flex-col md:flex md:justify-between"
                    key={copyBlock.fields?.heading}
                    heading={copyBlock.fields?.heading || ""}
                    copy={copyBlock.fields?.copy || ""}
                  >
                    <VideoPlayer
                      autoPlay
                      isAnimation
                      controls={false}
                      id={animationVideoBlocks[index].fields?.video.id || ""}
                      url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlocks[index].fields?.video.url}`}
                      loop={animationVideoBlocks[index].fields?.video.loop}
                    />
                  </ContentCard>
                );
              })}
          </div>
        </div>
      </Container>
    </VideoPlayerProvider>
  );
};
