import type { ReactNode } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { Block, Inline } from "@contentful/rich-text-types";
import { BLOCKS } from "@contentful/rich-text-types";
import type { TypeArticleSimpleIllustration } from "@types.ts";
import clsx from "clsx";

export const ArticleSimpleIllustrations = ({
  fields,
}: {
  fields: TypeArticleSimpleIllustration;
}) => {
  return (
    <div className={"article-simple-illustrations"}>
      {fields.header && (
        <div className={"article-simple-illustrations-header"}>
          {documentToReactComponents(fields.header, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (
                _node: Block | Inline,
                children: ReactNode,
              ) => children,
            },
          })}
        </div>
      )}
      {fields.illustrations && (
        <div className={"article-simple-illustrations-content"}>
          {fields.illustrations?.length > 1 ? (
            fields.illustrations?.map((illustration, index: number) => {
              return (
                <div
                  key={index}
                  className={"article-simple-illustrations-content-tile"}
                >
                  <div
                    className={
                      "article-simple-illustrations-content-tile-image-container"
                    }
                  >
                    <div
                      className={
                        "article-simple-illustrations-content-tile-image"
                      }
                    >
                      <img src={illustration.fields.image?.fields.file.url} />
                    </div>
                  </div>
                  <div
                    className={
                      "article-simple-illustrations-content-tile-text-container"
                    }
                  >
                    <div
                      className={
                        "article-simple-illustrations-content-tile-heading"
                      }
                    >
                      {illustration.fields.heading}
                    </div>
                    <div
                      className={
                        "article-simple-illustrations-content-tile-description"
                      }
                    >
                      {documentToReactComponents(
                        illustration.fields.description,
                        {
                          renderNode: {
                            [BLOCKS.PARAGRAPH]: (
                              _node: Block | Inline,
                              children: ReactNode,
                            ) => <div>{children}</div>,
                          },
                        },
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className={clsx(
                "article-simple-illustrations-content-inline",
                fields.illustrations[0].fields.reverse
                  ? "flex-col-reverse sm:flex-row-reverse"
                  : "sm:flex-row flex-col",
              )}
            >
              <div
                className={
                  "basis-1/2 article-simple-illustrations-content-inline-image-container"
                }
              >
                <div
                  className={
                    "article-simple-illustrations-content-inline-image"
                  }
                >
                  <img
                    src={fields.illustrations[0].fields.image?.fields.file.url}
                  />
                </div>
              </div>
              <div
                className={
                  "basis-1/2 article-simple-illustrations-content-inline-text-container"
                }
              >
                <div
                  className={
                    "article-simple-illustrations-content-tile-heading"
                  }
                >
                  {fields.illustrations[0].fields.heading}
                </div>
                <div className={"article-section-description"}>
                  {documentToReactComponents(
                    fields.illustrations[0].fields.description,
                    {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (
                          _node: Block | Inline,
                          children: ReactNode,
                        ) => <div>{children}</div>,
                      },
                    },
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
