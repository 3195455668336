import type { Dispatch } from "react";
import { useContext } from "react";

import type { Action } from "./createDataContext";
import { createDataContext } from "./createDataContext";

const ACTIONS = {
  VIDEO_UPDATE: "videoUpdate",
};

type VideoState = {
  activeVideoId?: string | null;
};

type VideoActions = {
  setVideo: (d: Dispatch<Action>) => (vId: string | null) => void;
};

const videoReducer = (state: VideoState, action: Action): VideoState => {
  switch (action.type) {
    case ACTIONS.VIDEO_UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const setVideo =
  (dispatch: Dispatch<Action>) => async (activeVideoId: string | null) =>
    dispatch({ type: ACTIONS.VIDEO_UPDATE, payload: { activeVideoId } });

export const { Provider: VideoProvider, Context: VideoContext } =
  createDataContext<VideoActions, VideoState>(
    videoReducer,
    { setVideo },
    { activeVideoId: null },
  );

export const useVideoContext = () => useContext(VideoContext);
