import React from "react";

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const Button = ({ children, onClick }: ButtonProps) => (
  <button
    onClick={onClick}
    className="px-2 my-auto mx-2 block border border-sky-700 text-sky-700 rounded bg-white text-medium hover:border-sky-400 hover:font-semibold"
  >
    {children}
  </button>
);
