import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import type FilePlayer from "react-player/file";

import { useLocale } from "@lib/contexts/LocaleContext";
import useAnalytics from "@lib/hooks/useAnalytics";
import type { LanguageCode } from "@root/constants";
import { AnalyticsEvent, LanguageLabels } from "@root/constants";

import { useVideoContext } from "./VideoPlayer.context";
import type { VideoPlayerProps } from "./VideoPlayer.types";

export const useVideoPlayer = ({
  id,
  autoPlay,
  poster,
  track = true,
  url,
  loop = false,
  explicit,
  captionLocales,
  captionSource,
  ...props
}: VideoPlayerProps) => {
  const { setVideo } = useVideoContext();
  const [cookies, setCookie] = useCookies(["_exM"]);
  const fileRef = useRef<FilePlayer>(null);
  const [showVideoPoster, setShowVideoPoster] = useState<boolean>(
    !autoPlay && !!poster,
  );
  const locale = useLocale();

  const [controls, setControls] = useState<boolean>(props.controls ?? true);
  const [playedOnce, setPlayedOnce] = useState<boolean>();
  const [playing, setPlaying] = useState<boolean>();
  const [muted, setMuted] = useState<boolean>(props.muted ?? false);
  const [duration, setDuration] = useState<number | undefined>();
  const [progress, setProgress] = useState<number | undefined>();
  const { analytics } = useAnalytics();
  const [thresholdsReached, setThresholdsReached] = useState({
    tenPercent: false,
    fiftyPercent: false,
    ninetyPercent: false,
  });

  const [subtitlesEnabled, setSubtitlesEnabled] = useState<boolean>(false);

  const subtitles = useMemo(() => {
    if (!captionLocales) return [];

    return captionLocales.split(",").map((captionLocale) => ({
      src: `${import.meta.env.VITE_CAPTION_URL}${captionLocale}/${captionSource}`,
      srcLang: captionLocale,
      label: LanguageLabels[captionLocale as LanguageCode],
      default: captionLocale === locale,
    }));
  }, [captionLocales, captionSource, locale]);

  const handleStorageChange = useCallback(() => {
    const preferredLanguage = localStorage.getItem("ccl");
    const matchingTrack = subtitles.find(
      (subtitle) => subtitle.srcLang === preferredLanguage,
    );

    if (matchingTrack) {
      return setSubtitlesEnabled(true);
    }

    return setSubtitlesEnabled(false);
  }, [subtitles]);

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    handleStorageChange();

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [subtitles]);

  useEffect(() => {
    if (autoPlay === true && !playing && !playedOnce) {
      setControls(false);
      setMuted(true);
      setPlaying(true);
      if (!props.isAnimation) {
        setVideo(id);
      }
    }
  }, [autoPlay, id, setVideo, playing, playedOnce]);

  const handleSeek = (val: number) => {
    if (track) {
      analytics.track({
        event: AnalyticsEvent.VIDEO_CLICK,
        props: { seek: val, Video_source: url, label: id },
      });
    }
  };

  useEffect(() => {
    if (progress !== undefined && duration !== undefined && track) {
      const progressPercent = (progress / duration) * 100;

      if (!thresholdsReached.tenPercent && progressPercent >= 10) {
        analytics.track({
          event: AnalyticsEvent.VIDEO_TRACK,
          props: {
            threshold: "10",
            progress,
            duration,
            Video_source: url,
            label: id,
          },
        });
        return setThresholdsReached((prev) => ({ ...prev, tenPercent: true }));
      }

      if (!thresholdsReached.fiftyPercent && progressPercent >= 50) {
        analytics.track({
          event: AnalyticsEvent.VIDEO_TRACK,
          props: {
            threshold: "50",
            progress,
            duration,
            Video_source: url,
            label: id,
          },
        });
        return setThresholdsReached((prev) => ({
          ...prev,
          fiftyPercent: true,
        }));
      }

      if (!thresholdsReached.ninetyPercent && progressPercent >= 90) {
        analytics.track({
          event: AnalyticsEvent.VIDEO_TRACK,
          props: {
            threshold: "90",
            progress,
            duration,
            Video_source: url,
            label: id,
          },
        });
        setThresholdsReached((prev) => ({ ...prev, ninetyPercent: true }));
      }
    }
  }, [progress, duration, track]);

  const handleVideoEnd = () => {
    setPlayedOnce(true);
    handleSeek(0);

    if (!loop && !props.isAnimation) {
      setVideo(null);
    }
  };

  const handleMuteChange = (isMuted: boolean) => {
    if (!isMuted && track) {
      analytics.track({
        event: AnalyticsEvent.VIDEO_UNMUTE,
        props: { Video_source: url, label: id },
      });
    }

    if (!isMuted) {
      setControls(true);
    }

    setMuted(isMuted);
  };

  const handleSubtitleChange = (textTracks: TextTrackList) => {
    let subtitleEnabled = false;
    for (let i = 0; i < textTracks.length; i++) {
      if (textTracks[i].mode === "showing") {
        setSubtitlesEnabled(true);
        localStorage.setItem("ccl", textTracks[i].language);
        subtitleEnabled = true;
        break;
      }
    }
    if (!subtitleEnabled) {
      setSubtitlesEnabled(false);
      localStorage.removeItem("ccl");
    }
  };

  const handlePosterClick = () => {
    if (explicit && !cookies._exM) {
      setCookie("_exM", true, { domain: ".omgyes.com" });
    }

    setPlaying(true);
    setVideo(id);
    setShowVideoPoster(false);

    if (id && track) {
      analytics.track({
        event: AnalyticsEvent.VIDEO_CLICK,
        props: { Video_source: url, label: id },
      });
    }
  };

  return {
    muted,
    setMuted,
    controls,
    setControls,
    subtitlesEnabled,
    fileRef,
    playing,
    handleMuteChange,
    setProgress,
    setDuration,
    handleVideoEnd,
    handleSubtitleChange,
    showVideoPoster,
    duration,
    handlePosterClick,
    subtitles,
  };
};
