import { useIsMobile } from "@hooks/useMobile";

import { Container } from "@components/ui/Container";

import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type { AnimationVideoBlockFields } from "@pages/collections/types/Page.types";

import { ComparisonAnimationSection } from "../../ComparisonAnimationSection";
import { PercentageHeadline } from "../../PercentageHeadline";

import {
  findComponentsByNameInSection,
  findComponentsByProperty,
} from "@/utils/componentUtils";

export const TopBlockVsAnimation = () => {
  const isMobile = useIsMobile();
  const { sections } = useTechniquePagesContext();

  const [animationVsText] = findComponentsByProperty<{ text: string }>(
    sections,
    "cssClass",
    "separator--vs",
  );

  const vsBlockAnimationVideos =
    findComponentsByNameInSection<AnimationVideoBlockFields>(
      sections,
      "topAnimation",
      "animationVideoBlock",
    );

  const [statBlock] = findComponentsByNameInSection<{
    ratio: string;
    copy: string;
  }>(sections, "topAnimation", "statBlock");

  return (
    <Container
      className="global-section-padding-x flex flex-col-reverse xl:grid xl:grid-cols-[3fr,1fr] items-start xl:items-center"
      fullWidth={true}
    >
      <Container fullWidth>
        <ComparisonAnimationSection
          leftVideo={{
            id: vsBlockAnimationVideos?.[0]?.fields?.video?.id,
            url: `${import.meta.env.VITE_VIDEO_URL}${vsBlockAnimationVideos?.[0]?.fields?.video?.url}`,
            label: "In and Out",
          }}
          rightVideo={{
            id: vsBlockAnimationVideos?.[1]?.fields?.video?.id,
            url: `${import.meta.env.VITE_VIDEO_URL}${vsBlockAnimationVideos?.[1]?.fields?.video?.url}`,
            label: "Levering",
          }}
          isMobile={isMobile}
          animationVsText={animationVsText?.text || ""}
        />
      </Container>

      <PercentageHeadline
        percentage={statBlock?.fields?.ratio || ""}
        headline={statBlock?.fields?.copy || ""}
      />
    </Container>
  );
};
