import { Suspense } from "react";
import { useNavigate } from "react-router";
import { Navigate } from "react-router-dom";

import { Container } from "@components/ui/Container";

import {
  TechniquePagesProvider,
  useTechniquePagesContext,
} from "@pages/collections/context/TechniquePage.context";
import { mapJsonToCommonSections } from "@pages/collections/utils/Page.utils";
import { processHtmlWithLinks } from "@pages/utils.ts";

import { BedroomBlock } from "../../BedroomBlock/BedroomBlock";
import {
  customBlockContentMapper,
  techniqueBlockCustomContentMapper,
  techniquePagesIconMapper,
  topBlockCustomContentMapper,
} from "../../mapper";
import { TechniquePageHeader } from "../../TechniquePageHeader/TechniquePageHeader";
import { TestimonialSection } from "../../TestimonialCarousel/TestimonialSection";
import { TopBlock } from "../../TopBlock/TopBlock";

const TechniquePageContent = () => {
  const { sections, techniquePageId } = useTechniquePagesContext();
  const navigate = useNavigate();

  if (!techniquePageId || !sections) return <Navigate to="/" />;

  const commonSections = mapJsonToCommonSections(sections);
  const TechniqueIcon =
    techniquePagesIconMapper[commonSections.heading.pageHeading.fields.icon];

  const processedBedroomBlock = {
    ...commonSections.bedroomBlockCommon.fields,
    columns: commonSections.bedroomBlockCommon.fields.columns.map((column) => ({
      ...column,
      items: column.items.map((item) => ({
        ...item,
        // Add a render function instead of modifying the original content
        renderCopy: () => processHtmlWithLinks(item.copy, navigate),
      })),
    })),
  };

  return (
    <Container fullWidth className="global-section-gap-y global-page-max-w">
      <Container
        className="mobile-override mt-5 sm:mt-7 md:8 lg:10"
        id="the-big-idea"
      >
        <TechniquePageHeader
          description={commonSections.heading.pageHeading.fields.subtitle}
          title={commonSections.heading.pageHeading.fields.title}
          icon={
            <TechniqueIcon className="w-[40px] h-[40px] md:w-[64px] md:h-[64px]" />
          }
        />
        <TopBlock {...commonSections.heading.topBlock.fields} />
      </Container>

      {topBlockCustomContentMapper[techniquePageId]}

      {/* Testimonials Section #1 */}
      <TestimonialSection
        index={0}
        quoteSliderData={commonSections.quoteSliderBanner || []}
        technique={techniquePageId}
      />

      {techniqueBlockCustomContentMapper[techniquePageId]}

      {/* Testimonials Section #2 */}
      <TestimonialSection
        index={1}
        quoteSliderData={commonSections.quoteSliderBanner || []}
        technique={techniquePageId}
      />

      {customBlockContentMapper[techniquePageId]}

      {/* Testimonials Section #3 */}
      <TestimonialSection
        index={2}
        quoteSliderData={commonSections.quoteSliderBanner || []}
        technique={techniquePageId}
      />
      <BedroomBlock {...processedBedroomBlock} />
    </Container>
  );
};

export const TechniquePage = () => {
  return (
    // TODO: Create a loading state UI
    <Suspense fallback={<div>Loading...</div>}>
      <TechniquePagesProvider>
        <TechniquePageContent />
      </TechniquePagesProvider>
    </Suspense>
  );
};
