import type { MouseEventHandler } from "react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Turnstile } from "@marsidev/react-turnstile";
import _ from "lodash";

import { ExclamationCircle } from "@components/atoms/svg/icons/ExclamationCircle";
import { Form, FormError, FormInput, FormSubmit } from "@components/forms.tsx";

import { doQuery } from "@lib/query";
import { defaultErrorMessages } from "@pages/Auth/LoginForm";

import { REDEEM_GIFT_CODE_MUTATION } from "./redeemQuery";

const redeemErrorMessages: Record<string, string> = {
  ...defaultErrorMessages,
  giftCode: "Gift Code Required",
};

const FormDefaults: Record<string, Record<string, string>> = {
  new: {
    title: "checkoutPage.create",
    link: "checkoutPage.switchToExisting",
  },
  existing: {
    title: "checkoutPage.existing",
    link: "checkoutPage.switchToCreate",
  },
};

const Redeem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formMode, setFormMode] = useState<string>("new");
  const [giftCode, setGiftCode] = useState<string>();
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [turnstileToken, setTurnstileToken] = useState<string>();
  const [searchParams] = useSearchParams();
  const { register, handleSubmit, getValues } = useForm();

  useEffect(() => {
    const code = searchParams.get("code");
    if (code) setGiftCode(code);
  }, [searchParams]);

  const onSubmit = useCallback(
    async (data: Record<string, string>) => {
      try {
        const variables: Record<string, any> = {
          email: data.email,
          giftCode: data.giftCode,
          turnstileToken,
        };

        if (formMode === "new")
          variables.registerUser = {
            email: data.email,
            password: data.password,
          };

        await doQuery({
          query: REDEEM_GIFT_CODE_MUTATION,
          variables,
        });
      } catch (error) {
        setErrors({ ...errors, submit: defaultErrorMessages.submit });
        console.error(error);
        return;
      }
      navigate("/");
    },
    [formMode, turnstileToken],
  );

  const handleChangeFormMode: MouseEventHandler = (event) => {
    event.preventDefault();

    setFormMode(formMode === "new" ? "existing" : "new");
  };

  return (
    <>
      <Turnstile
        siteKey={import.meta.env.VITE_CLOUDFLARE_SITEKEY!}
        options={{ size: "invisible" }}
        onSuccess={setTurnstileToken}
      />

      <div className="flex min-h-[640px] h-screen p-[64px_16px_0px] bg-[#faa116] bg-[radial-gradient(55.33%_73.36%_at_78.38%_81.7%,#d47446_39.19%,rgba(212,116,70,0)_100%),radial-gradient(25.74%_42.87%_at_44.63%_104.47%,#faa116_29%,rgba(250,161,22,0)_100%),radial-gradient(42.4%_62.84%_at_9%_87.26%,#f0cb9d_32.34%,rgba(240,203,157,0)_100%),radial-gradient(42%_48.62%_at_76.21%_27.6%,#a1baf9_44.36%,rgba(161,186,249,0)_100%),radial-gradient(38.12%_51.79%_at_88.47%_19.57%,#a1baf9_47.66%,rgba(161,186,249,0)_100%)]">
        <div className="flex w-full max-md:flex-col md:flex-row items-center justify-center">
          <div className="max-w-md md:w-1/2 px-2 pb-8 md:p-8 font-mabry-pro-regular text-18px">
            <div className="w-full pb-8 font-mabry-pro-bold text-32px tracking-tight">
              {t("giftsRedeem.head")}
            </div>
            {t("giftsRedeem.subhead.med")}
          </div>
          <div className="w-full md:w-1/2">
            <Form
              className="max-w-[450px]"
              onSubmit={handleSubmit(onSubmit, setErrors)}
            >
              <p className="w-full font-mabry-pro-bold text-28px md:text-32px tracking-tight leading-tight">
                {t(FormDefaults[formMode].title)}
              </p>
              <Link
                className="w-full hover:text-cta-hover text-cta text-14px font-mabry-pro-regular cursor-pointer text-left"
                onClick={handleChangeFormMode}
                to=""
              >
                {t(FormDefaults[formMode].link)}
              </Link>

              <FormError hidden={_.isEmpty(errors)}>
                <ul>
                  {/* need to translate error messages */}
                  {[
                    "submit",
                    "email",
                    "giftCode",
                    "password",
                    "confirmPassword",
                  ].map(
                    (f: string) =>
                      errors[f] && (
                        <li key={f} className="flex items-center">
                          <ExclamationCircle className="mr-4px inline min-h-14px min-w-14px" />
                          {errors[f].message || redeemErrorMessages[f]}
                        </li>
                      ),
                  )}
                </ul>
              </FormError>

              <FormInput
                className="mb-4 w-full"
                register={() => register("email", { required: true })}
                label={t("giftsRedeem.email")}
                error={errors.email}
                onChange={() => setErrors({})}
              />
              <FormInput
                className="mb-4 w-full"
                register={() => register("giftCode", { required: true })}
                label={t("giftsRedeem.giftCode.label")}
                defaultValue={giftCode}
                error={errors.giftCode}
                onChange={() => setErrors({})}
              />
              {formMode === "new" && (
                <>
                  <FormInput
                    className="mb-4 w-full"
                    register={() => register("password", { required: true })}
                    label={t("giftsRedeem.password.choose")}
                    error={errors.email}
                    type="password"
                    onChange={() => setErrors({})}
                  />
                  <FormInput
                    className="mb-4 w-full"
                    register={() =>
                      register("confirmPassword", {
                        required: true,
                        deps: "password",
                        validate: (confirmPassword) =>
                          getValues("password") === confirmPassword ||
                          defaultErrorMessages.confirmPassword,
                      })
                    }
                    label={t("giftsRedeem.password.confirm")}
                    error={errors.email}
                    type="password"
                    onChange={() => setErrors({})}
                  />
                </>
              )}
              <FormSubmit value={t("label.startExploring")} />
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Redeem;
