import React from "react";

import { cn } from "@lib/utils";

import { Typography } from "../Typography";
import VideoPoster from "../VideoPoster/VideoPoster";

import { useVideoPlayer } from "./VideoPlayer.hook";
import type { VideoPlayerProps } from "./VideoPlayer.types";
import ReactPlayerContainer from "./VideoReactPlayer";

export const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const {
    id,
    maxWidth,
    url,
    loop,
    explicit,
    videoDisplayStyle,
    poster,
    unmuteFlag,
    className,
    aspectRatio,
    height,
  } = props;

  const {
    muted,
    setMuted,
    controls,
    setControls,
    subtitlesEnabled,
    fileRef,
    playing,
    handleMuteChange,
    setProgress,
    setDuration,
    handleVideoEnd,
    handleSubtitleChange,
    showVideoPoster,
    duration,
    handlePosterClick,
    subtitles,
  } = useVideoPlayer(props);

  return (
    <div
      className={cn(
        "overflow-hidden relative md:rounded-[12px] aspect-video",
        aspectRatio,
        className,
      )}
      style={{ maxWidth: `${maxWidth}px` }}
    >
      <ReactPlayerContainer
        height={height}
        aspectRatio={aspectRatio}
        id={id}
        muted={muted}
        playerRef={fileRef}
        url={url}
        subtitles={subtitles}
        loop={loop}
        playing={playing}
        subtitlesEnabled={subtitlesEnabled}
        controls={controls}
        onClick={() => muted && setMuted(false)}
        onMuteToggle={handleMuteChange}
        onProgress={({ playedSeconds }: { playedSeconds: number }) =>
          setProgress(playedSeconds)
        }
        onDuration={(val: number) => setDuration(val)}
        onEnded={handleVideoEnd}
        style={videoDisplayStyle}
        onSubtitleChange={handleSubtitleChange}
      />

      {unmuteFlag && muted ? (
        <div
          className={
            "absolute top-[8px] left-[8px] sm:top-16px sm:left-[16px] !w-auto !h-auto bg-ui-pepper bg-opacity-90 rounded-lg py-[8px] px-[16px]"
          }
          onClick={() => {
            setMuted(false);
            setControls(true);
          }}
        >
          <Typography variant="caption" customColor="white">
            Tap to Unmute
          </Typography>
        </div>
      ) : null}

      {showVideoPoster && (
        <VideoPoster
          className="absolute top-0 left-0"
          videoDuration={
            duration
              ? new Date(duration * 1000).toISOString().substring(14, 19)
              : undefined
          }
          onClick={handlePosterClick}
          maxWidth={maxWidth}
          posterUrl={poster}
          explicit={explicit}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
