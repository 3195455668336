import React, { useRef } from "react";

import type { NavigationItem } from "./TechniquePageLayout.types";

import { useMobileNavigation } from "@/hooks/useMobileNavigation";
import { cn } from "@/lib/utils";

interface MenuItemProps {
  label: string;
  href: string;
  index: number;
  activeIndex: number;
  onClick: (href: string) => void;
}

interface MobileNavProps {
  navItems: NavigationItem[];
  currentPath?: string;
}

export function MobileNavigation({
  navItems,
  currentPath = "/",
}: MobileNavProps) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { activeIndex, showLeftShadow, showRightShadow, handleItemClick } =
    useMobileNavigation(navItems, currentPath, scrollContainerRef);

  return (
    <div className="w-full sticky top-[64px] z-30">
      {showLeftShadow && (
        <div className="absolute left-0 top-0 bottom-0 z-10 w-8 bg-gradient-to-r from-[#1e0e3b] to-transparent pointer-events-none" />
      )}

      <div
        ref={scrollContainerRef}
        className="flex overflow-x-auto scrollbar-hide py-4 bg-[#1e0e3b] text-white"
        style={{ scrollBehavior: "smooth" }}
      >
        <div className="flex px-4 min-w-full">
          {navItems.map((item, index) => (
            <div
              key={index}
              id={`nav-item-${index}`}
              className="flex-shrink-0 mr-8 last:mr-0 relative"
            >
              <MobileMenuItem
                label={item.title}
                href={item.href}
                onClick={(href) => handleItemClick(href, index)}
                index={index}
                activeIndex={activeIndex}
              />

              {/* Active indicator */}
              {index === activeIndex && (
                <div className="absolute bottom-[-16px] left-0 w-full h-1 bg-[#ff6b35]" />
              )}
            </div>
          ))}

          <div className="w-2 flex-shrink-0" />
        </div>
      </div>

      {/* Right shadow indicator */}
      {showRightShadow && (
        <div className="absolute right-0 top-0 bottom-0 z-10 w-8 bg-gradient-to-l from-[#1e0e3b] to-transparent pointer-events-none" />
      )}
    </div>
  );
}

const MobileMenuItem: React.FC<MenuItemProps> = ({
  label,
  href,
  onClick,
  index,
  activeIndex,
}) => {
  return (
    <a
      className={cn(
        "text-xl whitespace-nowrap transition-colors text-white font-mabry-pro-regular",
        index === activeIndex ? "font-mabry-pro-bold" : "",
      )}
      onClick={(e) => {
        e.preventDefault();
        window.history.pushState(null, "", href);
        // Dispatch hashchange event manually
        window.dispatchEvent(new HashChangeEvent("hashchange"));
        onClick(href);
      }}
      href={href}
    >
      <span>{label}</span>
    </a>
  );
};
