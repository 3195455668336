import { Container } from "@components/ui/Container";

import { TexturesPanel } from "./TexturesPanel";
import { WrappingGreatestHitsBlock } from "./WrappingGreatestHitsBlock";

export const WrappingCustomContent = () => {
  return (
    <Container fullWidth className="flex flex-col gap-8 md:gap-12 lg:gap-14">
      <WrappingGreatestHitsBlock />
      <TexturesPanel />
    </Container>
  );
};
