/**
 * Component utilities for working with nested component structures
 */

export const splitStringWithBasicQuote = (copy: string | undefined) => {
  if (!copy) return ["", ""];

  const [text, quote] = copy.split(/<span class="basic-quote">(.*?)<\/span>/);

  return [text, quote];
};

/**
 * Interface for generic component field structure
 */
interface ComponentFields {
  [key: string]: any;
}

/**
 * Interface for a generic component
 */
export interface Component<T = ComponentFields> {
  component?: string;
  type?: string;
  fields?: T;
  children?: Array<Component<T>>;
  [key: string]: any;
}

/**
 * Recursively finds all components with a specific name in a nested component structure
 *
 * @param data - The component or component tree to search through
 * @param componentName - The name of the component to find
 * @returns Array of components that match the specified name
 */
export const findComponentsByName = <T extends ComponentFields>(
  data: Component<T> | Array<Component<T>>,
  componentName: string,
): Array<Component<T>> => {
  const results: Array<Component<T>> = [];

  // Handle the case where data is an array
  if (Array.isArray(data)) {
    // Process each item in the array
    data.forEach((item) => {
      results.push(...findComponentsByName(item, componentName));
    });
    return results;
  }

  // Check if the current component matches the target name
  if (data.component === componentName) {
    results.push(data);
  }

  // Check fields for nested components
  if (data.fields) {
    // Process fields that might be components or arrays of components
    Object.keys(data.fields).forEach((key) => {
      const field = data.fields![key];

      if (typeof field === "object" && field !== null) {
        if (Array.isArray(field)) {
          field.forEach((item) => {
            if (typeof item === "object" && item !== null) {
              results.push(...findComponentsByName<T>(item, componentName));
            }
          });
        } else {
          results.push(...findComponentsByName<T>(field, componentName));
        }
      }
    });
  }

  // Check children for nested components
  if (data.children && Array.isArray(data.children)) {
    data.children.forEach((child) => {
      results.push(...findComponentsByName<T>(child, componentName));
    });
  }

  return results;
};

/**
 * Recursively finds all components with a specific property value in a nested component structure
 *
 * @param data - The component or component tree to search through
 * @param propertyPath - The path to the property to check (e.g., "fields.video.id")
 * @param propertyValue - The value to match against the property
 * @returns Array of components that match the specified property value
 */
export const findComponentsByProperty = <T extends ComponentFields>(
  data: Component<T> | Array<Component<T>>,
  propertyPath: string,
  propertyValue: any,
): Array<Component<T>> => {
  const results: Array<Component<T>> = [];
  const pathParts = propertyPath.split(".");

  // Handle the case where data is an array
  if (Array.isArray(data)) {
    // Process each item in the array
    data.forEach((item) => {
      results.push(
        ...findComponentsByProperty(item, propertyPath, propertyValue),
      );
    });
    return results;
  }

  // Check if the component has the specified property with the matching value
  const checkPropertyValue = (obj: any, parts: string[]): boolean => {
    if (parts.length === 0 || obj === undefined || obj === null) {
      return false;
    }

    if (parts.length === 1) {
      let jsonValue = obj[parts[0]];
      let valueToCompare = propertyValue;

      if (Array.isArray(propertyValue)) {
        valueToCompare = JSON.stringify(propertyValue);

        if (Array.isArray(jsonValue)) {
          return JSON.stringify(jsonValue) === valueToCompare;
        }
      }

      if (Array.isArray(jsonValue)) {
        return jsonValue.includes(valueToCompare);
      }

      return jsonValue === valueToCompare;
    }

    const [firstPart, ...restParts] = parts;
    const nestedObj = obj[firstPart];

    if (nestedObj === undefined || nestedObj === null) {
      return false;
    }

    return checkPropertyValue(nestedObj, restParts);
  };

  if (checkPropertyValue(data, pathParts)) {
    results.push(data);
  }

  // Check fields for nested components
  if (data.fields) {
    // Process fields that might be components or arrays of components
    Object.keys(data.fields).forEach((key) => {
      const field = data.fields![key];

      if (typeof field === "object" && field !== null) {
        if (Array.isArray(field)) {
          field.forEach((item) => {
            if (typeof item === "object" && item !== null) {
              results.push(
                ...findComponentsByProperty<T>(
                  item,
                  propertyPath,
                  propertyValue,
                ),
              );
            }
          });
        } else {
          results.push(
            ...findComponentsByProperty<T>(field, propertyPath, propertyValue),
          );
        }
      }
    });
  }

  // Check children for nested components
  if (data.children && Array.isArray(data.children)) {
    data.children.forEach((child) => {
      results.push(
        ...findComponentsByProperty<T>(child, propertyPath, propertyValue),
      );
    });
  }

  return results;
};

/**
 * Transforms all components of a specific type using a callback function
 *
 * @param data - The component or component tree to process
 * @param componentName - The name of the component to transform
 * @param callback - The transformation function to apply to each matching component
 * @returns The transformed component tree
 */
export const transformComponents = (
  data: Component | Array<Component>,
  componentName: string,
  callback: (component: Component) => Component,
): Component | Array<Component> => {
  // Handle the case where data is an array
  if (Array.isArray(data)) {
    return data.map((item) =>
      transformComponents(item, componentName, callback),
    );
  }

  // Create a copy of the data to avoid mutating the original
  const result = { ...data };

  // Apply the callback to matching components
  if (result.component === componentName) {
    return callback(result);
  }

  // Process fields for nested components
  if (result.fields) {
    result.fields = { ...result.fields };

    Object.keys(result.fields).forEach((key) => {
      const field = result.fields![key];

      if (typeof field === "object" && field !== null) {
        if (Array.isArray(field)) {
          result.fields![key] = field.map((item) => {
            if (typeof item === "object" && item !== null) {
              return transformComponents(item, componentName, callback);
            }
            return item;
          });
        } else {
          result.fields![key] = transformComponents(
            field,
            componentName,
            callback,
          );
        }
      }
    });
  }

  // Process children for nested components
  if (result.children && Array.isArray(result.children)) {
    result.children = result.children.map(
      (child) =>
        transformComponents(child, componentName, callback) as Component,
    );
  }

  return result;
};

/**
 * Finds a specific section/component by name and then searches for components by name within that section
 *
 * @param data - The component or component tree to search through
 * @param sectionName - The name of the section/component to search within
 * @param componentName - The name of the component to find within the section
 * @returns Array of components that match the specified name within the specified section
 */
export const findComponentsByNameInSection = <T extends ComponentFields>(
  data: Component<T> | Array<Component<T>>,
  sectionName: string,
  componentName: string,
): Array<Component<T>> => {
  // First find the section(s) with the specified name
  const sections = findComponentsByName(data, sectionName);
  const results: Array<Component<T>> = [];

  // Then find components with the specified name within each section
  sections.forEach((section) => {
    const componentsInSection = findComponentsByName(section, componentName);
    results.push(...componentsInSection);
  });

  return results;
};

/**
 * Finds components by name within a specific section at a given array index
 * Useful when you have multiple sections with the same name but want a specific one
 *
 * @param data - The component or component tree to search through (often with a sections array)
 * @param sectionsArrayPath - The path to the sections array (e.g., "sections")
 * @param sectionIndex - The index of the section in the sections array
 * @param sectionComponentName - The component name to identify the section (optional)
 * @param targetComponentName - The name of the component to find within the section
 * @returns Array of components that match the specified name within the specified section
 */
export const findComponentsInSectionByIndex = <T extends ComponentFields>(
  data: Component<T>,
  sectionsArrayPath: string,
  sectionIndex: number,
  sectionComponentName?: string,
  targetComponentName?: string,
): Array<Component<T>> => {
  // Navigate to the sections array using the path
  const pathParts = sectionsArrayPath.split(".");
  let currentObj = data;

  for (const part of pathParts) {
    if (currentObj && typeof currentObj === "object") {
      currentObj = currentObj[part];
    } else {
      return []; // Path doesn't exist
    }
  }

  // Ensure we have an array and the index is valid
  if (!Array.isArray(currentObj) || sectionIndex >= currentObj.length) {
    return [];
  }

  // Get the specified section
  const section = currentObj[sectionIndex];

  console.log(section, "currentObj [omgyes]");

  // If a section component name is provided, verify the section has that component name
  if (sectionComponentName && section.component !== sectionComponentName) {
    return [];
  }

  // If no target component name is provided, return the section itself
  if (!targetComponentName) {
    return [section];
  }

  // Find components within the section
  return findComponentsByName(section, targetComponentName);
};

/**
 * Example usage:
 * const componentData = { ... }; // Your component JSON
 *
 * // Find all video blocks
 * const videoBlocks = findComponentsByName(componentData, 'animationVideoBlock');
 *
 * // Find components with specific property value
 * const specificVideos = findComponentsByProperty(componentData, 'fields.video.id', '1');
 *
 * // Find all animation video blocks within the topAnimation section
 * const topAnimationVideos = findComponentsByNameInSection(componentData, 'topAnimation', 'animationVideoBlock');
 *
 * // Find components in a specific section by index
 * const specificSectionVideos = findComponentsInSectionByIndex(
 *   componentData,
 *   'sections',
 *   2, // third section (0-indexed)
 *   'topAnimation', // optional: verify section component name
 *   'animationVideoBlock' // optional: component name to find within section
 * );
 *
 * // Transform all animation video blocks to set autoplay to true
 * const transformedData = transformComponents(
 *   componentData,
 *   'animationVideoBlock',
 *   (component) => ({
 *     ...component,
 *     fields: {
 *       ...component.fields,
 *       video: {
 *         ...component.fields?.video,
 *         autoplay: true
 *       }
 *     }
 *   })
 * );
 */
