import React from "react";

export const Toolbar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="w-full h-[54px] min-h-[54px] flex px-2 justify-start border-b border-sky-700 bg-[#f7fcff]">
      {children}
    </div>
  );
};
