import { useEffect, useState } from "react";

import { screens } from "@root/tailwind.screens.config.ts";

const useTailwindTheme = () => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  useEffect(() => {
    const updateSize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", updateSize);
    setDimensions({ width: window.innerWidth, height: window.innerHeight });

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const getBreakpointValue = (value: string): number => {
    const strVal = screens[value];

    if (!strVal) {
      console.warn(`Breakpoint '${value}' not found in screens config`);
      return 0;
    }

    return Number(strVal.replace("px", ""));
  };

  const getCurrentBreakpoint = (): string | null => {
    let currentBreakpoint: string | null = null;
    let biggestBreakpointValue = 0;

    for (const breakpoint of Object.keys(screens)) {
      const breakpointValue = getBreakpointValue(breakpoint);

      if (
        breakpointValue > biggestBreakpointValue &&
        window.innerWidth >= breakpointValue
      ) {
        biggestBreakpointValue = breakpointValue;
        currentBreakpoint = breakpoint;
      }
    }

    return currentBreakpoint;
  };

  const is = (key: string): boolean => {
    if (!screens[key]) {
      console.warn(`Breakpoint '${key}' not found in screens config`);
      return false;
    }
    return getBreakpointValue(key) <= dimensions.width;
  };

  return {
    getCurrentBreakpoint,
    width: dimensions.width,
    isXs: is("xs"),
    isSs: is("ss"),
    isSm: is("sm"),
    isMd: is("md"),
    isLg: is("lg"),
    isXl: is("xl"),
    is2Xl: is("2xl"),
    is3Xl: is("3xl"),
  };
};

export default useTailwindTheme;
