import { useState } from "react";

import { Calendar } from "@components/ui/Calendar";

import { formatDateForDatePicker } from "@lib/utils.ts";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/Popover";

export function DatePicker({
  onSelect,
  selected,
  register,
  placeholder,
}: {
  selected: Date | undefined | null;
  onSelect: (date: Date | null | undefined) => void;
  register: () => Record<string, any>;
  placeholder?: string;
}) {
  const [date, setDate] = useState<Date | undefined | null>(selected);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelected = (d: Date | null | undefined) => {
    if (d) {
      setDate(d);
      onSelect && onSelect(d);
    }
    setIsOpen(false);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <div className="w-full relative flex items-center justify-center">
          <input
            type="text"
            {...register()}
            value={
              date ? formatDateForDatePicker(date) : "we have no date defined"
            }
            readOnly
            placeholder={placeholder}
            className="w-full block p-3 rounded-lg border-0 bg-[#F3F3F3] font-mabry-pro-regular text-16px leading-24px text-ui-coal px-3 py-2 pr-8 cursor-pointer"
          />

          <div className="absolute right-0 w-[40px] h-full content-center">
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.1875 13.25C6.1875 12.9393 6.43934 12.6875 6.75 12.6875H8.4375V11C8.4375 10.6893 8.68934 10.4375 9 10.4375C9.31066 10.4375 9.5625 10.6893 9.5625 11V12.6875H11.25C11.5607 12.6875 11.8125 12.9393 11.8125 13.25C11.8125 13.5607 11.5607 13.8125 11.25 13.8125H9.5625V15.5C9.5625 15.8107 9.31066 16.0625 9 16.0625C8.68934 16.0625 8.4375 15.8107 8.4375 15.5V13.8125H6.75C6.43934 13.8125 6.1875 13.5607 6.1875 13.25Z"
                fill="#545251"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0625 1.25C14.0625 0.93934 13.8107 0.6875 13.5 0.6875C13.1893 0.6875 12.9375 0.93934 12.9375 1.25V2.1875H5.0625V1.25C5.0625 0.93934 4.81066 0.6875 4.5 0.6875C4.18934 0.6875 3.9375 0.93934 3.9375 1.25V2.1875H1.5C0.775126 2.1875 0.1875 2.77513 0.1875 3.5V18.5C0.1875 19.2249 0.775126 19.8125 1.5 19.8125H16.5C17.2249 19.8125 17.8125 19.2249 17.8125 18.5V3.5C17.8125 2.77513 17.2249 2.1875 16.5 2.1875H14.0625V1.25ZM16.6875 6.6875V3.5C16.6875 3.39645 16.6036 3.3125 16.5 3.3125H14.0625V4.25C14.0625 4.56066 13.8107 4.8125 13.5 4.8125C13.1893 4.8125 12.9375 4.56066 12.9375 4.25V3.3125H5.0625V4.25C5.0625 4.56066 4.81066 4.8125 4.5 4.8125C4.18934 4.8125 3.9375 4.56066 3.9375 4.25V3.3125H1.5C1.39645 3.3125 1.3125 3.39645 1.3125 3.5V6.6875H16.6875ZM1.3125 7.8125H16.6875V18.5C16.6875 18.6036 16.6036 18.6875 16.5 18.6875H1.5C1.39645 18.6875 1.3125 18.6036 1.3125 18.5V7.8125Z"
                fill="#545251"
              />
            </svg>
          </div>
        </div>
      </PopoverTrigger>

      <PopoverContent className="w-auto p-0 z-40 bg-ui-sugar" align="end">
        <Calendar
          mode="single"
          selected={date || undefined}
          onSelect={handleSelected}
          showOutsideDays={false}
          fromDate={new Date()}
          formatters={{
            formatWeekdayName: (date) => {
              return date
                .toLocaleDateString("en-US", { weekday: "short" })
                .toUpperCase();
            },
          }}
        />
      </PopoverContent>
    </Popover>
  );
}
