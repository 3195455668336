import { useEffect, useMemo } from "react";

import { Container } from "@components/ui/Container";
import { Typography } from "@components/ui/Typography";
import VideoPlayer from "@components/ui/VideoPlayer/VideoPlayer";
import {
  useVideoContext,
  VideoPlayerProvider,
} from "@components/ui/VideoPlayer/VideoPlayer.context";
import VideoPoster from "@components/ui/VideoPoster/VideoPoster";

import useTailwindTheme from "@lib/hooks/useTailwindTheme";
import type {
  TechniquePagesCommonSections,
  VideoFields,
} from "@pages/collections/types/Page.types";

export type TopBlockType = {
  component: TechniquePagesCommonSections.topBlock;
  fields: TopBlockProps;
};

export type TopBlockProps = {
  heading: string;
  body: string;
  videos: {
    fields: Array<VideoFields>;
  };
};

const VideoSection = ({ videos, heading, body }: TopBlockProps) => {
  const { isXl } = useTailwindTheme();

  const {
    state: { activeVideoId },
    setVideo,
  } = useVideoContext();

  useEffect(() => {
    setVideo(videos.fields[0].video.id);
  }, [videos]);

  const activeVideo = useMemo(
    () =>
      videos.fields.find((video) => video.video.id === activeVideoId) ||
      videos.fields[0],
    [activeVideoId],
  );

  return (
    <Container
      fullWidth
      className="xl:grid xl:grid-cols-3 xl:gap-5 full-width-mobile-only"
    >
      <div className="xl:col-span-2">
        <VideoPlayer
          poster={`${import.meta.env.VITE_IMAGE_URL}${activeVideo.video.coverImage}`}
          id={activeVideo.video.id}
          url={`${import.meta.env.VITE_VIDEO_URL}${activeVideo.video.videoUrl}`}
          captionLocales={activeVideo.video.captionLocales}
          captionSource={activeVideo.video.captionSource}
        />
      </div>
      {isXl && (
        <Container className="flex flex-col" fullWidth>
          <Typography variant="paragraph-title">{heading}</Typography>
          <Typography variant="paragraph">{body}</Typography>
        </Container>
      )}
    </Container>
  );
};

// PerspectivesSection is the video playlist section if more than one top video
const PerspectiveSection = ({ videos }: TopBlockProps) => {
  const {
    setVideo,
    state: { activeVideoId },
  } = useVideoContext();

  return (
    <>
      <Typography className="mb-2 mt-4" variant="headline">
        Perspectives
      </Typography>
      <div className="flex gap-2 md:grid md:grid-cols-3 md:gap-4">
        {videos.fields.map(({ video, subContent }: VideoFields) => {
          return (
            <div className="flex flex-col gap-1 flex-1" key={video.id}>
              <VideoPoster
                rounded
                key={video.id}
                active={video.id === activeVideoId}
                onClick={() => {
                  setVideo(video.id);
                }}
                posterUrl={`${import.meta.env.VITE_IMAGE_URL}${video.coverImage}`}
              />
              <figcaption className="flex flex-col gap-1">
                <Typography variant="video-caption-title">
                  {subContent.name}
                </Typography>

                <Typography variant="video-caption-text">
                  {subContent.copy}
                </Typography>
              </figcaption>
            </div>
          );
        })}
      </div>
    </>
  );
};

export const TopBlock = (props: TopBlockProps) => {
  const { isXl } = useTailwindTheme();

  return (
    <VideoPlayerProvider>
      <Container fullWidth className="global-section-padding-x mobile-override">
        {props.videos.fields.length > 0 && <VideoSection {...props} />}

        {props.videos.fields.length > 1 && (
          <Container fullWidth className="px-4 sm:px-6 md:px-10 lg:px-14">
            <PerspectiveSection {...props} />
          </Container>
        )}

        {!isXl && (
          <Container className="flex flex-col mt-4" fullWidth>
            <Typography variant="paragraph-title">{props.heading}</Typography>
            <Typography variant="paragraph">{props.body}</Typography>
          </Container>
        )}
      </Container>
    </VideoPlayerProvider>
  );
};
