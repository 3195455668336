import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { Block, Inline } from "@contentful/rich-text-types";
import { BLOCKS } from "@contentful/rich-text-types";
import type { Document } from "@contentful/rich-text-types/dist/types/types";
import clsx from "clsx";
import _ from "lodash";

import { ArticleCarousel } from "@components/ArticleCarousel.tsx";
import AppPageHeader from "@components/common/AppPageHeader.tsx";
import PageFooter from "@components/common/PageFooter.tsx";

import { useContentful } from "@lib/contexts/ContentfulContext";
import useAnalytics from "@lib/hooks/useAnalytics.ts";
import useContentfulLive from "@lib/hooks/useContentfulLive.ts";
import useScrollDepthTracker from "@lib/hooks/useScrollDepthTracker.tsx";
import { AnalyticsEvent } from "@root/constants.ts";

type Image = {
  fields: {
    description: string;
    title: string;
    details: {
      image: {
        width: number;
        height: number;
      };
      size: number;
    };
    file: {
      contentType: string;
      fileName: string;
      url: string;
    };
  };
};

type DateNightData = {
  title: string;
  descriptionText: Document;
  locale: string;
  tags: string[];
  messagesQuotes: {
    fields: {
      quote: Document;
    };
  }[];
  image: Image;
  dateNightType: string;
  contentHeading: string;
  contentDescription: Document;
  contentItems: {
    fields: {
      heading: string;
      description: Document;
      image: Image;
    };
  }[];
  gameCardHeadline: string;
  gameCardDescriptionText: Document;
  forPartnersHeadline: string;
  forPartnersDescription: string;
  forCouplesHeadline: string;
  forCouplesDescription: string;
  forCouplesQuotes: {
    fields: {
      dateNight: string;
      headline: string;
      description: string;
      locale: string;
      tag: string;
    };
  }[];
  lowerCta: {
    fields: {
      heading: string;
      text: string;
    };
  };
  dateNightSuggestionUrl: string;
};

type TypeDateNight = {
  fields: DateNightData;
};

const DateNightPage = () => {
  const [copyActive, setCopyActive] = useState<number>();
  const params = useParams();
  const [publishedData, setPublishedData] = useState<TypeDateNight>();
  const [selectedQuoteCategory, setSelectedQuoteCategory] = useState<number>(0);
  const navigate = useNavigate();
  const { analytics } = useAnalytics();
  const { getEntry, getDateNightId } = useContentful();
  const couplesQuoteRef = useRef<{ setActiveItem: (item: number) => void }>();

  useScrollDepthTracker();

  useEffect(() => {
    analytics.page({ page: "date_night_page", props: params });
  }, []); //removing dependencies to ensure this only fires once when the component mounts

  useEffect(() => {
    const fetchPage = async (title: string) => {
      if (params.id) {
        const id = await getDateNightId(title);
        if (id) {
          getEntry(id).then((data: TypeDateNight) => {
            setPublishedData(data);
          });
        } else {
          navigate("/home");
        }
      }
    };
    params.id && fetchPage(params.id);
  }, [getEntry, params.id]);

  const { data: fullData } = useContentfulLive(publishedData);
  const data: DateNightData = fullData?.fields;

  if (!data) return <></>;

  return (
    <div className="date-night">
      <div className="date-night-header-background">
        <div
          id="top-bg-gradient"
          className={clsx(
            "",
            "w-[902px] h-[948px] absolute z-10",
            "left-[calc(100%-450px)] lg:left-[calc(50%)]",
            "top-[calc(0%-450px)]",
          )}
          style={{
            background:
              "radial-gradient(circle 320px at center center, rgba(70, 47, 69, 1.0), rgba(70, 47, 69, 0)), " +
              "radial-gradient(circle 411px at 450px center, rgba(62, 33, 73, 1), rgba(62, 33, 73, 0)), " +
              "radial-gradient(circle 411px at 450px center, rgba(45, 48, 81, 0.6), rgba(45, 48, 81, 0)), radial-gradient(circle 411px at 450px center, rgba(45, 48, 81, 1), rgba(45, 48, 81, 0)",
          }}
        />
      </div>

      <div className="date-night-header flex flex-col">
        <AppPageHeader
          showLogo={true}
          previousPage={"/home"}
          scrolledTitle={data.title}
        />
      </div>

      <div className={"date-night-content"}>
        <div className={"date-night-x-padding"}>
          <div className={"date-night-max768-container"}>
            <div className="flex flex-col md:flex-row gap-[32px] md:gap-0 self-center">
              <div
                id="header-container"
                className="basis-1/2 top-88px left-16px gap-32px flex flex-col md:flex-row justify-start items-start md:mr-[16px]"
              >
                <div
                  id="header"
                  className="w-[100%] flex flex-col gap-32px md:pr-16px"
                >
                  <div
                    id="header-container"
                    className="flex flex-col gap-16px md:pr-16px md:gap-16px"
                  >
                    <div id="headline" className="date-night-headline">
                      {data.title}
                    </div>
                    {documentToReactComponents(data.descriptionText, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (
                          _node: Block | Inline,
                          children: ReactNode,
                        ) => (
                          <div className={"date-night-body-block"}>
                            {children}
                          </div>
                        ),
                      },
                    })}
                  </div>
                  <div
                    id="tag-block"
                    className="flex flex-col self-stretch gap-12px"
                  >
                    <div className="date-night-tag-headline">
                      What you’ll need
                    </div>
                    <div className="flex gap-8px flex-row ">
                      {data.tags.map((tag, index) => {
                        return (
                          <div
                            key={index}
                            className="px-3 py-1 bg-light-lemon rounded gap-2"
                          >
                            <div className="date-night-tag-text">{tag}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="game-card"
                className="basis-1/2 flex flex-col self-stretch gap-0 rounded-lg bg-white"
              >
                <div
                  id="game-image"
                  className="h-[208px] sm:h-[354px] md:h-[205px] lg:h-[275px] flex relative w-full bg-dark-aubergine"
                >
                  <div
                    className={clsx(
                      "w-full h-full",
                      "rounded-t-lg",
                      "absolute",
                      "z-10",
                    )}
                    style={{
                      backgroundImage: `url(${data.image?.fields.file.url})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <div
                    className="date-night-game-card-headline self-end z-30 pl-16px pr-8px py-8px lg:py-16px"
                    style={{ textShadow: "0px 1px 0px #302627" }}
                  >
                    {data.gameCardHeadline}
                  </div>
                </div>
                <div
                  id="game-description"
                  className="flex flex-col gap-16px pt-16px pr-16px pb-8px pl-8px"
                >
                  {documentToReactComponents(data.gameCardDescriptionText, {
                    renderNode: {
                      [BLOCKS.UL_LIST]: (
                        _node: Block | Inline,
                        children: ReactNode,
                      ) => (
                        <div className={"flex flex-col gap-16px"}>
                          {children}
                        </div>
                      ),
                      [BLOCKS.LIST_ITEM]: (node: Block | Inline) => {
                        const transformedChildren = documentToReactComponents(
                          node as Document,
                          {
                            // renderMark: options.renderMark,
                            renderNode: {
                              [BLOCKS.PARAGRAPH]: (
                                _node: Block | Inline,
                                children: ReactNode,
                              ) => children,
                              [BLOCKS.UL_LIST]: (
                                _node: Block | Inline,
                                children: ReactNode,
                              ) => <div>{children}</div>,
                              [BLOCKS.LIST_ITEM]: (
                                _node: Block | Inline,
                                children: ReactNode,
                              ) => (
                                <div className={"flex flex-row"}>
                                  <div
                                    className={
                                      "h-24px w-24px p-8px gap-8px text-light-peach"
                                    }
                                  >
                                    <svg
                                      width="6"
                                      height="6"
                                      viewBox="0 0 6 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="3"
                                        cy="3"
                                        r="3"
                                        fill="#D88A67"
                                      />
                                    </svg>
                                  </div>
                                  <div className="date-night-game-card-description">
                                    {children}
                                  </div>
                                </div>
                              ),
                            },
                          },
                        );

                        return (
                          <div className={"border-b-1 pr-16px gap-4px"}>
                            <div className={"flex flex-row"}>
                              {/*<div className={'h-24px w-24px p-8px gap-8px text-light-peach'}>*/}
                              {/*  <svg width="6" height="6" viewBox="0 0 6 6" fill="none"*/}
                              {/*       xmlns="http://www.w3.org/2000/svg">*/}
                              {/*    <circle cx="3" cy="3" r="3" fill="#D88A67" />*/}
                              {/*  </svg>*/}
                              {/*</div>*/}
                              <div>{transformedChildren}</div>
                            </div>
                          </div>
                        );
                      },
                    },
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {data.contentHeading && (
          <div className={"date-night-x-padding"}>
            <div className={"date-night-max768-container"}>
              <div className="flex-col gap-24px flex">
                <div className="gap-24px flex flex-col justify-start items-start ">
                  <div id="content-container" className="flex flex-col gap-8px">
                    <div
                      id="content-headline"
                      className="grow shrink basis-0 date-night-section-headline"
                    >
                      {data.contentHeading}
                    </div>
                    <div
                      id="content-description"
                      className="grow shrink basis-0 date-night-body-text"
                    >
                      {documentToReactComponents(data.contentDescription, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (
                            _node: Block | Inline,
                            children: ReactNode,
                          ) => (
                            <div className={"content-block"}>{children}</div>
                          ),
                        },
                      })}
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row w-full flex-wrap gap-x-8px gap-y-24px ">
                    {data.contentItems.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            "flex flex-col gap-4px  sm:basis-1/2-gap-8 bg-white/5 rounded-xl  overflow-hidden"
                          }
                        >
                          {item.fields.image?.fields.file.url ? (
                            <div className={""}>
                              <img
                                src={item.fields.image.fields.file.url}
                                width={"100%"}
                              />
                            </div>
                          ) : null}
                          <div className={"flex flex-col gap-12px p-16px"}>
                            <div
                              className={
                                "date-night-content-section-item-heading"
                              }
                            >
                              {item.fields.heading}
                            </div>
                            <div
                              className={
                                "date-night-content-section-item-description flex flex-col gap-8px"
                              }
                            >
                              {documentToReactComponents(
                                item.fields.description,
                                {
                                  renderNode: {
                                    [BLOCKS.PARAGRAPH]: (
                                      _node: Block | Inline,
                                      children: ReactNode,
                                    ) => <div>{children}</div>,
                                  },
                                },
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={"date-night-x-padding"}>
          <div className={"date-night-max768-container"}>
            <div className="flex flex-col gap-24px">
              <div className="gap-24px flex flex-col justify-start items-start ">
                <div
                  id="for-couples-header-container"
                  className="flex flex-col gap-8px"
                >
                  <div
                    id="for-couples-headline"
                    className="grow shrink basis-0 date-night-section-headline"
                  >
                    {data.forCouplesHeadline}
                  </div>
                  <div
                    id="for-couples-body-block"
                    className="grow shrink basis-0 date-night-body-text"
                  >
                    {data.forCouplesDescription}
                  </div>
                </div>
              </div>

              <div
                id="for-couples-tag-container"
                className="flex flex-row flex-wrap gap-8px"
              >
                {_.uniq(
                  data.forCouplesQuotes.map((q) => q.fields.tag) as string[],
                ).map((tag, index) => {
                  return (
                    <button
                      key={index}
                      onClick={() => {
                        const firstItem =
                          data.forCouplesQuotes.findIndex(
                            (quote) => quote.fields.tag === tag,
                          ) || 0;
                        couplesQuoteRef.current?.setActiveItem(firstItem);
                      }}
                      className={clsx(
                        selectedQuoteCategory !== index
                          ? "date-night-body-text"
                          : "date-night-body-text-selected",
                        "bg-[rgba(255,255,255,.05)]",
                        // selectedQuoteCategory !== index ? 'hover:bg-white hover:text-ui-pepper': null,
                        "px-16px py-6px gap-8px rounded-3xl",
                      )}
                    >
                      {tag}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className={"date-night-carousel-container"}>
          <ArticleCarousel
            showing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
            dark={true}
            maxWidthClasses={"max-w-[280px]"}
            onChange={(currentItem) => {
              const activeTag = data.forCouplesQuotes[currentItem]?.fields.tag;
              const tags = _.uniq(
                data.forCouplesQuotes.map((q) => q.fields.tag) as string[],
              );
              setSelectedQuoteCategory(tags.findIndex((x) => x === activeTag));
            }}
            ref={couplesQuoteRef}
          >
            {data.forCouplesQuotes.map(
              ({ fields }: { fields: any }, index: number) => {
                return (
                  <div
                    key={index}
                    className={clsx("h-full  flex-col justify-start")}
                  >
                    <div
                      id={`user-quote-${index}`}
                      className={clsx(
                        "rounded-xl p-16px gap-8px ",
                        "h-full",
                        "bg-[rgba(255,255,255,0.05)]",
                        "place-content-between",
                      )}
                    >
                      <div
                        id="body-block"
                        className={"flex flex-col gap-8px pr-8px"}
                      >
                        <div
                          id="headline"
                          className={"date-night-body-headline"}
                        >
                          {fields.headline}
                        </div>
                        <div className={clsx("date-night-body-text")}>
                          {fields.description}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </ArticleCarousel>
        </div>

        <div className={"date-night-x-padding"}>
          <div className={"date-night-max768-container"}>
            <div className="flex flex-col">
              <div className="gap-24px flex flex-col justify-start items-start ">
                <div
                  id="for-couples-header-container"
                  className="flex flex-col gap-8px"
                >
                  <div
                    id="for-partners-headline"
                    className="grow shrink basis-0 date-night-section-headline"
                  >
                    {data.forPartnersHeadline}
                  </div>
                  <div
                    id="for-partners-body-block"
                    className="grow shrink basis-0 date-night-body-text"
                  >
                    {data.forPartnersDescription}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Ways to introduce it*/}
        <div className={"date-night-carousel-container z-50"}>
          <ArticleCarousel
            showing={{ xs: 1, sm: 2, md: 3, lg: 3 }}
            maxWidthClasses={"max-w-[280px]"}
          >
            {data.messagesQuotes.map((entry, index) => {
              const text = _.join(
                entry.fields.quote.content.map(
                  (entries) =>
                    _.join(entries.content.map((x) => _.get(x, "value"))),
                  " ",
                ),
                " ",
              );
              return (
                <div
                  key={index}
                  className={clsx("h-full flex flex-col justify-start")}
                >
                  <div
                    id={`partner-message-${index}`}
                    className={clsx(
                      "flex flex-col rounded-xl p-16px gap-32px",
                      "h-full",
                      "bg-[rgba(255,255,255,0.05)]",
                      "place-content-between",
                    )}
                  >
                    <div
                      id="body-block"
                      className={clsx("date-night-body-text pr-8px")}
                    >
                      {documentToReactComponents(entry.fields.quote, {
                        renderNode: {
                          [BLOCKS.PARAGRAPH]: (
                            _node: Block | Inline,
                            children: ReactNode,
                          ) => <div>{children}</div>,
                        },
                      })}
                    </div>
                    {copyActive === index ? (
                      <button
                        className={clsx(
                          "date-night-copy-text rounded-3xl",
                          "self-stretch border-[1px] border-ui-green bg-ui-green text-ui-sugar",
                        )}
                      >
                        Copied!
                      </button>
                    ) : (
                      <button
                        onClick={async () => {
                          analytics.track({
                            event: AnalyticsEvent.COPY_TEXT,
                            props: {
                              ...params,
                              text_copy_id: `${params.id}-${index}`,
                            },
                          });
                          setCopyActive(index);
                          await navigator.clipboard.writeText(text);
                          setTimeout(() => {
                            setCopyActive(undefined);
                          }, 2500);
                        }}
                        className={clsx(
                          "date-night-light-secondary-cta date-night-copy-text",
                          "self-stretch ",
                        )}
                      >
                        Copy Message
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </ArticleCarousel>
        </div>

        <div className={"date-night-x-padding z-40"}>
          <div className={"date-night-max768-container z-40"}>
            <div
              className="pb-[64px] 2xl:pb-[72px] flex flex-col gap-32px self-center z-40"
              style={{ clipPath: "border-box" }}
            >
              <div
                id="suggest-date-night z-40"
                className="gap-24px flex flex-col items-center"
              >
                <div
                  id="suggest-date-night-content"
                  className="flex flex-col gap-0 px-32px sm:px-28px md:px-76px lg:px-0 max-w-[560px] items-center z-40"
                >
                  <div
                    id="suggest-date-night-headline"
                    className={"flex flex-col items-center gap-16px w-full"}
                  >
                    <div
                      id="suggest-date-night-headline-icon"
                      className="date-night-section-headline text-center w-[48px] h-[48px] justify-self-center"
                    >
                      <svg
                        width="48"
                        height="48"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.6499 21.7499C7.6499 21.4185 7.91853 21.1499 8.2499 21.1499H15.7499C16.0813 21.1499 16.3499 21.4185 16.3499 21.7499C16.3499 22.0813 16.0813 22.3499 15.7499 22.3499H8.2499C7.91853 22.3499 7.6499 22.0813 7.6499 21.7499Z"
                          fill="#F7BC5E"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.9015 4.05614C14.7059 3.23673 13.2826 2.81463 11.8336 2.84973C8.09784 2.93591 5.07931 6.04993 5.10001 9.78877C5.10511 10.8295 5.34598 11.8559 5.80452 12.7902C6.26302 13.7243 6.92723 14.5425 7.74725 15.1831C8.09016 15.4502 8.36769 15.7919 8.55877 16.1823C8.74996 16.5729 8.84956 17.0019 8.85002 17.4368L8.85002 17.9999C8.85002 18.0397 8.86582 18.0778 8.89395 18.106C8.92208 18.1341 8.96023 18.1499 9.00002 18.1499H15C15.0398 18.1499 15.078 18.1341 15.1061 18.106C15.1342 18.0778 15.15 18.0397 15.15 17.9999V17.4374L15.15 17.4348C15.1519 16.9979 15.2532 16.5671 15.4463 16.1752C15.6391 15.7838 15.9184 15.4414 16.263 15.1738C17.4024 14.2788 18.2311 13.0479 18.6317 11.6555C19.0325 10.2626 18.9844 8.7788 18.4943 7.41473C18.0042 6.05066 17.0971 4.87555 15.9015 4.05614ZM11.8052 1.65006C13.506 1.60901 15.1765 2.10452 16.5799 3.0663C17.9834 4.02822 19.0483 5.40769 19.6236 7.00899C20.1989 8.61029 20.2554 10.3521 19.7849 11.9873C19.3145 13.6225 18.341 15.068 17.0027 16.1187L16.9999 16.121C16.7985 16.2771 16.6354 16.477 16.5228 16.7055C16.4103 16.9337 16.3513 17.1845 16.35 17.4389V17.9999C16.35 18.3579 16.2078 18.7013 15.9546 18.9545C15.7014 19.2077 15.3581 19.3499 15 19.3499H9.00002C8.64197 19.3499 8.2986 19.2077 8.04542 18.9545C7.79225 18.7013 7.65002 18.3579 7.65002 17.9999V17.4376C7.64969 17.1853 7.59187 16.9364 7.48096 16.7098C7.36998 16.4831 7.20877 16.2847 7.00958 16.1296L7.00877 16.129C6.04574 15.3767 5.26571 14.4159 4.72728 13.3189C4.1889 12.222 3.90607 11.0173 3.90002 9.79541M11.8052 1.65006C7.41301 1.75138 3.87593 5.40443 3.90002 9.79541L11.8052 1.65006Z"
                          fill="#F7BC5E"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.1583 5.1503C12.2134 4.82354 12.5229 4.60328 12.8497 4.65834C14.9772 5.01682 16.7312 6.77359 17.0916 8.89973C17.1469 9.22644 16.927 9.53618 16.6003 9.59156C16.2736 9.64694 15.9638 9.42698 15.9084 9.10027C15.6332 7.47641 14.2728 6.11505 12.6503 5.84166C12.3235 5.7866 12.1033 5.47707 12.1583 5.1503Z"
                          fill="#F7BC5E"
                        />
                      </svg>
                    </div>
                    <div
                      id="suggest-date-night-headline-text"
                      className="date-night-section-headline text-center w-full z-40"
                    >
                      {data.lowerCta.fields.heading}
                    </div>
                  </div>
                  <div
                    id="suggest-date-night-suggest"
                    className="date-night-body-text text-center z-40 pt-[16px] pb-[24px]"
                  >
                    {data.lowerCta.fields.text}
                  </div>
                  <button
                    onClick={() =>
                      window.open(data.dateNightSuggestionUrl, "_blank")
                    }
                    className={clsx(
                      "date-night-let-us-know date-night-light-primary-cta z-40 self-center",
                    )}
                  >
                    <div className={"date-night-let-us-know-text"}>
                      Let us know
                    </div>
                  </button>
                  <div className={"date-night-return-to-home-container"}>
                    <div
                      className={"date-night-return-to-home"}
                      onClick={() =>
                        navigate("/home", { preventScrollReset: false })
                      }
                    >
                      <div className={"h-full"}>
                        <svg
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.5889 13.0139C10.8037 12.8078 10.8037 12.4736 10.5889 12.2675L6.57782 8.41846L10.5889 4.56943C10.8037 4.36332 10.8037 4.02915 10.5889 3.82304C10.3741 3.61693 10.0259 3.61693 9.81109 3.82304L5.41109 8.04526C5.1963 8.25137 5.1963 8.58554 5.41109 8.79165L9.81109 13.0139C10.0259 13.22 10.3741 13.22 10.5889 13.0139Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      <div className={"h-[24px] mb-0.5"}>Return to home</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex self-start relative mt-[-64px] 2xl:mt-[-72px]">
          <div className="overflow-hidden absolute h-[816px] w-[100vw] top-[-822px] z-10 flex">
            <div className="w-[100vw] h-[822px] relative">
              <div
                id="bottom-bg-gradient"
                className={clsx(
                  "relative",
                  "h-[822px] w-[1440px] z-10",
                  "left-[calc(50%-720px)]",
                  "top-[calc(100%-411px)]",
                )}
                style={{
                  background:
                    "radial-gradient(ellipse 550px 320px at center center, rgba(70, 47, 69, 1.0), rgba(70, 47, 69, 0)), " +
                    "radial-gradient(ellipse 550px 411px at center center, rgba(62, 33, 73, 1), rgba(62, 33, 73, 0)), " +
                    "radial-gradient(ellipse 668px 411px at center center, rgba(45, 48, 81, 0.6), rgba(45, 48, 81, 0)), radial-gradient(ellipse 668px 411px at center center, rgba(45, 48, 81, 1), rgba(45, 48, 81, 0)",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="date-night-footer">
        <PageFooter bgColor="bg-aubergine" textColor="text-[#ffffff]" />
      </div>
    </div>
  );
};

export default DateNightPage;
