import { useContentfulLiveUpdates } from "@contentful/live-preview/react";

const useContentfulLive = (publishedData: any) => {
  const data = useContentfulLiveUpdates(publishedData);

  if (import.meta.env.VITE_APP_STAGE === "Prod") return { data: publishedData };

  return {
    data,
  };
};

export default useContentfulLive;
