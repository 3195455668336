import { Outlet } from "react-router-dom";

import AppPageHeader from "@components/common/AppPageHeader.tsx";

import useTailwindTheme from "@lib/hooks/useTailwindTheme.ts";

import type { NavigationItem } from "./components/TechniquePageLayout.types";
import { TechniquePageLayoutFooter } from "./components/TechniquePageLayoutFooter";
import {
  Sidebar,
  SidebarProvider,
} from "./components/TechniquePageLayoutSidebar";
import { MobileNavigation } from "./components/TechniquePageMobileNavigation";

// TODO: Extract this to somewhere else, or get it from the API
const AllowedLanguages = [
  { text: "English", url: "#" },
  { text: "French", url: "#" },
  { text: "Spanish", url: "#" },
  { text: "German", url: "#" },
  { text: "Italian", url: "#" },
  { text: "Portuguese", url: "#" },
];

const navItems: NavigationItem[] = [
  {
    title: "The big idea",
    href: "#the-big-idea",
  },
  {
    title: "Techniques",
    href: "#techniques",
  },
  {
    title: "Bringing it to the bedroom",
    href: "#bringing-it-to-the-bedroom",
  },
];

export function TechniquePageLayout() {
  const { isLg } = useTailwindTheme();

  return (
    <>
      <SidebarProvider defaultOpen>
        <div className="flex min-h-screen w-screen flex-col">
          <AppPageHeader
            showLogo
            previousPage={"/home"}
            scrolledTitle={"Your Collections"}
            variant="techniquePage"
          />
          <div className="lg:flex lg:flex-1 mt-64px">
            {isLg && <Sidebar navItems={navItems} />}
            {!isLg && <MobileNavigation navItems={navItems} />}
            <main className="flex-1 bg-[#F7F4F1] overflow-auto">
              <Outlet />
            </main>
          </div>
        </div>
      </SidebarProvider>
      <TechniquePageLayoutFooter languageLinks={AllowedLanguages} />
    </>
  );
}
