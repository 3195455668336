import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import Menu from "@components/common/menu/Menu.tsx";

import { useScreen } from "@lib/contexts/ScreenProvider.tsx";

const whiteOmgyes = (
  <svg
    width="98"
    height="16"
    viewBox="0 0 98 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.376 15.9655C48.2226 15.9655 50.0356 14.6995 51.4676 12.034V15.7448H52.7957V7.90509H43.5572V9.32786H50.971C50.8209 11.5694 48.8347 14.6124 45.6531 14.5543C41.7903 14.5543 39.4922 11.8482 39.4922 7.9864C39.4922 4.12463 42.1887 1.41268 45.4742 1.41268C48.569 1.41268 50.3994 2.76576 51.2482 5.76226L52.6802 5.228C51.9526 1.94694 49.4813 0.00154066 45.5261 0.00154066C41.2649 -0.0855669 37.8235 3.5323 37.887 7.99801C37.8235 12.5683 41.2013 16.0758 45.3933 15.9887L45.376 15.9655ZM35.7506 15.768V0.198982H32.904L27.13 14.653L21.3559 0.198982H18.5093V15.7448H20.0567V0.953908L25.9925 15.7448H28.2444L34.1859 0.953908V15.7448L35.7506 15.768ZM8.53749 14.5659C4.98067 14.5659 2.28996 11.9701 2.28996 8.01543C2.28996 4.06075 4.89406 1.42431 8.53749 1.42431C12.1809 1.42431 14.7677 3.99687 14.7677 8.00382C14.7677 12.0108 12.1405 14.5717 8.52017 14.5717L8.53749 14.5659ZM8.52017 0.00735351C6.94476 -0.00645484 5.40078 0.450758 4.08418 1.32099C2.76758 2.19121 1.73772 3.43521 1.1253 4.8951C0.51288 6.355 0.345507 7.96498 0.644424 9.52071C0.94334 11.0764 1.69507 12.5078 2.80422 13.6331C3.91336 14.7584 5.3299 15.5269 6.87408 15.8412C8.41826 16.1554 10.0205 16.0012 11.4773 15.398C12.9342 14.7949 14.1801 13.7701 15.0568 12.4536C15.9336 11.1371 16.4017 9.58833 16.4018 8.00382C16.4251 6.95317 16.2378 5.90855 15.8508 4.93234C15.4639 3.95612 14.8853 3.06832 14.1497 2.32196C13.4141 1.57561 12.5364 0.985982 11.5692 0.588301C10.602 0.19062 9.565 -0.00695282 8.52017 0.00735351Z"
      fill="white"
    />
    <path
      d="M90.8859 2.66758C92.6474 2.66758 93.7699 3.55678 94.2534 5.33517L96.8265 3.82413C95.9688 1.35414 93.7871 0 91.0701 0C87.7487 0 85.5037 1.93531 85.5037 4.31231C85.5037 6.99733 87.3285 8.62461 90.9608 9.17673C93.6029 9.60099 94.4837 10.2461 94.4837 11.3968C94.4837 12.5475 93.2748 13.2682 91.4213 13.2682C89.159 13.2682 87.5472 12.0012 86.9313 9.60099L84.2949 11.269C84.7522 12.8058 85.7652 14.1127 87.1328 14.9303C88.4281 15.644 89.8824 16.0121 91.358 15.9997C95.0996 15.9997 97.5 14.0005 97.5 11.2864C97.5 8.57231 95.8249 7.35766 92.0602 6.637C89.8613 6.21856 88.474 5.57346 88.474 4.31231C88.474 3.35338 89.418 2.64435 90.8974 2.64435L90.8859 2.66758ZM70.7501 0.197597V15.7556H82.5449V12.9776H73.6916V9.13024H81.6354V6.35222H73.6916V2.9756H82.2974V0.197597H70.7501ZM58.0055 0.197597H54.4826L60.3829 9.57774V15.7556H63.3532V9.60099L69.2304 0.220849H65.9263L61.8565 6.79973L58.0055 0.197597Z"
      fill="white"
    />
  </svg>
);
const blackOmgyes = (
  <svg
    width="98"
    height="16"
    viewBox="0 0 98 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.376 15.9655C48.2226 15.9655 50.0356 14.6995 51.4676 12.034V15.7448H52.7957V7.90509H43.5572V9.32786H50.971C50.8209 11.5694 48.8347 14.6124 45.6531 14.5543C41.7903 14.5543 39.4922 11.8482 39.4922 7.9864C39.4922 4.12463 42.1887 1.41268 45.4742 1.41268C48.569 1.41268 50.3994 2.76576 51.2482 5.76226L52.6802 5.228C51.9526 1.94694 49.4813 0.00154066 45.5261 0.00154066C41.2649 -0.0855669 37.8235 3.5323 37.887 7.99801C37.8235 12.5683 41.2013 16.0758 45.3933 15.9887L45.376 15.9655ZM35.7506 15.768V0.198982H32.904L27.13 14.653L21.3559 0.198982H18.5093V15.7448H20.0567V0.953908L25.9925 15.7448H28.2444L34.1859 0.953908V15.7448L35.7506 15.768ZM8.53749 14.5659C4.98067 14.5659 2.28996 11.9701 2.28996 8.01543C2.28996 4.06075 4.89406 1.42431 8.53749 1.42431C12.1809 1.42431 14.7677 3.99687 14.7677 8.00382C14.7677 12.0108 12.1405 14.5717 8.52017 14.5717L8.53749 14.5659ZM8.52017 0.00735351C6.94476 -0.00645484 5.40078 0.450758 4.08418 1.32099C2.76758 2.19121 1.73772 3.43521 1.1253 4.8951C0.51288 6.355 0.345507 7.96498 0.644424 9.52071C0.94334 11.0764 1.69507 12.5078 2.80422 13.6331C3.91336 14.7584 5.3299 15.5269 6.87408 15.8412C8.41826 16.1554 10.0205 16.0012 11.4773 15.398C12.9342 14.7949 14.1801 13.7701 15.0568 12.4536C15.9336 11.1371 16.4017 9.58833 16.4018 8.00382C16.4251 6.95317 16.2378 5.90855 15.8508 4.93234C15.4639 3.95612 14.8853 3.06832 14.1497 2.32196C13.4141 1.57561 12.5364 0.985982 11.5692 0.588301C10.602 0.19062 9.565 -0.00695282 8.52017 0.00735351Z"
      fill="#292625"
    />
    <path
      d="M90.8859 2.66758C92.6474 2.66758 93.7699 3.55678 94.2534 5.33517L96.8265 3.82413C95.9688 1.35414 93.7871 0 91.0701 0C87.7487 0 85.5037 1.93531 85.5037 4.31231C85.5037 6.99733 87.3285 8.62461 90.9608 9.17673C93.6029 9.60099 94.4837 10.2461 94.4837 11.3968C94.4837 12.5475 93.2748 13.2682 91.4213 13.2682C89.159 13.2682 87.5472 12.0012 86.9313 9.60099L84.2949 11.269C84.7522 12.8058 85.7652 14.1127 87.1328 14.9303C88.4281 15.644 89.8824 16.0121 91.358 15.9997C95.0996 15.9997 97.5 14.0005 97.5 11.2864C97.5 8.57231 95.8249 7.35766 92.0602 6.637C89.8613 6.21856 88.474 5.57346 88.474 4.31231C88.474 3.35338 89.418 2.64435 90.8974 2.64435L90.8859 2.66758ZM70.7501 0.197597V15.7556H82.5449V12.9776H73.6916V9.13024H81.6354V6.35222H73.6916V2.9756H82.2974V0.197597H70.7501ZM58.0055 0.197597H54.4826L60.3829 9.57774V15.7556H63.3532V9.60099L69.2304 0.220849H65.9263L61.8565 6.79973L58.0055 0.197597Z"
      fill="#292625"
    />
  </svg>
);

export default function AppPageHeader({
  previousPage,
  showLogo,
  showLogoLeft,
  scrolledTitle,
  variant,
}: {
  showLogo?: boolean;
  showLogoLeft?: boolean;
  previousPage?: string | number;
  scrolledTitle?: string;
  variant?: string;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [primary, setPrimary] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuShowPreviousIcon, setMenuShowPreviousIcon] =
    useState<boolean>(false);
  const [resetMenu, setResetMenu] = useState<Date>(new Date());
  const techPageVariant = variant === "techniquePage";

  const { width } = useScreen();

  const maxTitleLength = width / 7 - 26;
  const shortenedScrolledTitle =
    (scrolledTitle?.length || 0) > maxTitleLength
      ? `${scrolledTitle?.slice(0, maxTitleLength)}...`
      : scrolledTitle;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setPrimary(false);
      } else {
        setPrimary(true);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleHamburgerMenu = () => {
    setMenuOpen((v) => {
      if (v) setMenuShowPreviousIcon(false);
      return !v;
    });
  };

  return (
    <header className={clsx("header")}>
      {menuOpen && (
        <div
          className="bg-black bg-opacity-90 fixed z-5 w-full h-full"
          onClick={() => setMenuOpen(false)}
        />
      )}
      <div
        id="menu"
        className={clsx(
          "h-64px py-16px px-16px sm:px-24px md:px-40px w-[100vw] flex justify-center z-50 self-center ",
          ((!primary && !techPageVariant) || menuOpen) &&
            "bg-white border-b-[1px] border-ui-garlic drop-shadow-navbar",
          !primary && !techPageVariant ? "text-ui-pepper" : "text-white",
          "transition-colors ease-out duration-500",
          "relative",
          techPageVariant && !menuOpen && "!bg-dark-aubergine",
        )}
      >
        <div className="z-50 w-full lg:w-[1280px] flex justify-between gap-24px">
          {previousPage || menuShowPreviousIcon ? (
            <div
              id="backArrow"
              className={clsx(
                "flex-grow basis-0 hover:cursor-pointer",
                menuShowPreviousIcon ? "text-ui-pepper" : "",
              )}
              onClick={() =>
                menuShowPreviousIcon
                  ? setResetMenu(new Date())
                  : navigate(previousPage as number)
              }
            >
              <div className={"flex flex-row items-center"}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.1778 25.1908C21.6074 24.7786 21.6074 24.1103 21.1778 23.6981L13.1556 16L21.1778 8.30195C21.6074 7.88973 21.6074 7.22138 21.1778 6.80917C20.7482 6.39694 20.0518 6.39694 19.6222 6.80917L10.8222 15.2536C10.3926 15.6658 10.3926 16.3342 10.8222 16.7464L19.6222 25.1908C20.0518 25.6031 20.7482 25.6031 21.1778 25.1908Z"
                    fill="currentColor"
                  />
                </svg>
                {!menuOpen && previousPage === "/home" && !techPageVariant ? (
                  <div className={"page-header-back-text"}>Home</div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : showLogoLeft ? null : (
            <div
              className={clsx(
                showLogoLeft ? "w-0" : "flex-grow w-32px basis-0",
              )}
            />
          )}
          {showLogo || showLogoLeft ? (
            primary || menuOpen ? (
              <div
                className={clsx("self-center h-16px hover:cursor-pointer")}
                onClick={() => navigate("/home")}
              >
                {menuOpen && !techPageVariant
                  ? blackOmgyes
                  : menuOpen && techPageVariant
                    ? blackOmgyes
                    : whiteOmgyes}
              </div>
            ) : (
              <div className={clsx("self-center scrolled-header-title")}>
                {!primary
                  ? techPageVariant
                    ? whiteOmgyes // Always use white logo for technique pages when scrolled
                    : ["/home", "/gift"].includes(location.pathname)
                      ? blackOmgyes
                      : shortenedScrolledTitle
                  : null}
              </div>
            )
          ) : (
            <div className={"self-center scrolled-header-title"}>
              {!primary && shortenedScrolledTitle}
            </div>
          )}
          <div
            id="hamburger"
            className="menu-hamburger flex-grow basis-0 flex justify-end"
            onClick={toggleHamburgerMenu}
          >
            {menuOpen ? (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.05103 7.05103C7.4524 6.64966 8.10315 6.64966 8.50453 7.05103L16 14.5465L23.4955 7.05103C23.8968 6.64966 24.5476 6.64966 24.949 7.05103C25.3503 7.4524 25.3503 8.10315 24.949 8.50453L17.4535 16L24.949 23.4955C25.3503 23.8968 25.3503 24.5476 24.949 24.949C24.5476 25.3503 23.8968 25.3503 23.4955 24.949L16 17.4535L8.50453 24.949C8.10315 25.3503 7.4524 25.3503 7.05103 24.949C6.64966 24.5476 6.64966 23.8968 7.05103 23.4955L14.5465 16L7.05103 8.50453C6.64966 8.10315 6.64966 7.4524 7.05103 7.05103Z"
                  fill="#292625"
                />
              </svg>
            ) : (
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 8C4 7.44772 4.44772 7 5 7H27C27.5523 7 28 7.44772 28 8C28 8.55228 27.5523 9 27 9H5C4.44772 9 4 8.55228 4 8Z"
                  fill="currentColor"
                />
                <path
                  d="M4 16C4 15.4477 4.44772 15 5 15H27C27.5523 15 28 15.4477 28 16C28 16.5523 27.5523 17 27 17H5C4.44772 17 4 16.5523 4 16Z"
                  fill="currentColor"
                />
                <path
                  d="M4 24C4 23.4477 4.44772 23 5 23H27C27.5523 23 28 23.4477 28 24C28 24.5523 27.5523 25 27 25H5C4.44772 25 4 24.5523 4 24Z"
                  fill="currentColor"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      {menuOpen && (
        <Menu
          setMenuShowPreviousIcon={setMenuShowPreviousIcon}
          resetMenu={resetMenu}
          closeMenu={() => setMenuOpen(false)}
        />
      )}
    </header>
  );
}
