import type { PropsWithChildren } from "react";
import { useIsMobile } from "@hooks/useMobile";

import { Card } from "@components/ui/Card";
import { Typography } from "@components/ui/Typography";

import { cn } from "@lib/utils";

export const ContentCard = ({
  children,
  heading,
  copy,
  className,
}: PropsWithChildren<{
  heading: string;
  copy: string;
  className?: string;
}>) => {
  const isMobile = useIsMobile();
  return (
    <Card
      className={cn(
        "flex bg-[#EFECE7] flex-col py-6 px-4 gap-6 sm:grid sm:grid-cols-2 sm:gap-4 sm:py-7 sm:px-5 lg:py-8 lg:px-6 w-full sm:rounded-xl",
        className,
      )}
      fullWidth={isMobile}
    >
      <div className="flex flex-col gap-1">
        <Typography variant="technique-title">{heading}</Typography>

        {/* // TODO: there is a link in the middle of the string */}
        <Typography>
          <span
            dangerouslySetInnerHTML={{
              __html: copy,
            }}
          />
        </Typography>
      </div>

      {children}
    </Card>
  );
};
