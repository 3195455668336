import type { BedroomBlockType } from "../components/BedroomBlock/BedroomBlock.types";
import type { TopBlockType } from "../components/TopBlock/TopBlock";
import {
  type PageHeadingType,
  type QuoteSliderBannerType,
  type Sections,
  TechniquePagesCommonSections,
  type TechniquePageSections,
} from "../types/Page.types";

export const scrollToAnchor = (href: string) => {
  const element = document.querySelector(href);
  if (element) {
    const offset = 100; // Adjust this value as necessary
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export const mapJsonToCommonSections = (sections: Sections) => {
  const groupedSections = sections.reduce((acc, section) => {
    if (section.component === TechniquePagesCommonSections.pageHeading) {
      acc.heading = { ...acc.heading, pageHeading: section as PageHeadingType };
    }

    if (section.component === TechniquePagesCommonSections.topBlock) {
      acc.heading = { ...acc.heading, topBlock: section as TopBlockType };
    }

    if (section.component === TechniquePagesCommonSections.quoteSliderBanner) {
      acc[TechniquePagesCommonSections.quoteSliderBanner] = [
        ...(acc[TechniquePagesCommonSections.quoteSliderBanner] || []),
        section as QuoteSliderBannerType,
      ];
    }

    if (section.component === TechniquePagesCommonSections.bedroomBlockCommon) {
      acc[TechniquePagesCommonSections.bedroomBlockCommon] =
        section as BedroomBlockType;
    }

    return acc;
  }, {} as TechniquePageSections);
  return groupedSections;
};
