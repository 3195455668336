import { Typography } from "@components/ui/Typography/Typography";

import type { TechniquePageLayoutFooterProps } from "./TechniquePageLayout.types";

export const TechniquePageLayoutFooter = ({
  languageLinks,
}: TechniquePageLayoutFooterProps) => {
  return (
    <footer className="text-white bg-[#1B0C32] w-full h-[50px] text-center inline-flex gap-2 items-center justify-center md:gap-8">
      {languageLinks.map((link) => (
        <Typography
          as="a"
          href={link.url}
          className="text-white sm:text-xs md:text-base"
          key={`${link.text}-footer-language-link`}
        >
          {link.text}
        </Typography>
      ))}
    </footer>
  );
};
