import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { authenticationQueryKey } from "./authentication-query-keys";

export const useQueryAuthenticateUserOnLegacy = ({
  redirectUri,
  idToken,
}: {
  redirectUri: string;
  idToken?: string;
}) =>
  useQuery({
    enabled: !!idToken,
    queryKey: authenticationQueryKey.authenticatedUserOnLegacy(),
    queryFn: () =>
      axios.get(
        `${import.meta.env.VITE_OMGYES_SERVER_URL}/collections-api/authenticate`,
        {
          params: { redirect_uri: redirectUri, token: idToken },
        },
      ),
  });
