import { Typography } from "@components/ui/Typography";

import { cn } from "@lib/utils";

import { useIsMobile } from "@/hooks/useMobile";

interface SectionHeadingProps {
  title: string;
}

export default function SectionHeading({ title }: SectionHeadingProps) {
  const isMobile = useIsMobile();

  return (
    <div
      className={cn(
        "w-full border-b border-[#545251] pb-2 md:pb-4 content-gap-lg",
        isMobile && "w-auto",
      )}
    >
      <Typography variant="section-title">
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </Typography>
    </div>
  );
}
