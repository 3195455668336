import type { MenuItemProps } from "./TechniquePageLayout.types";
import { TriangleIcon } from "./TriangleIcon";

import { SidebarMenuButton, SidebarMenuItem } from "@/components/ui/Sidebar";

export function MenuItem({ title, href, onClick, isActive }: MenuItemProps) {
  return (
    <SidebarMenuItem>
      <SidebarMenuButton
        asChild
        isActive={isActive}
        className="cursor-pointer text-[20px] hover:bg-[#1B0C32] transition-all duration-200 group text-white hover:text-white w-full h-full min-h-20 active:bg-[#1B0C32] data-[active=true]:bg-[#1B0C32] data-[active=true]:text-white"
      >
        <a
          onClick={(e) => {
            e.preventDefault();
            window.history.pushState(null, "", href);
            // Dispatch hashchange event manually
            window.dispatchEvent(new HashChangeEvent("hashchange"));
            onClick(href);
          }}
          href={href}
          className={`flex items-center px-4 relative justify-between font-mabry-pro-regular bg-inherit text-white ${isActive ? "!font-mabry-pro-bold" : ""}`}
        >
          <span>{title}</span>
          <TriangleIcon isActive={isActive} />
        </a>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
}
