import { gql } from "graphql-request";

export const CHECKOUT_MUTATION = gql`
  mutation checkout(
    $paymentMethodNonce: String!
    $deviceData: String!
    $productPricingId: String!
    $registerUser: RegisterUserInput
    $giftCodeDetails: [GiftCodeDetails!]
    $email: AWSEmail
    $turnstileToken: String!
  ) {
    checkout(
      paymentMethodNonce: $paymentMethodNonce
      deviceData: $deviceData
      productPricingId: $productPricingId
      registerUser: $registerUser
      giftCodeDetails: $giftCodeDetails
      email: $email
      turnstileToken: $turnstileToken
    ) {
      id
      amount
      currency
      timestamp
      createdAt
      updatedAt
    }
  }
`;
