import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import {
  authenticationErrorMessageMap,
  AuthenticationErrors,
  useAuthentication,
} from "@lib/authentication";
import { defaultErrorMessages } from "@pages/Auth/LoginForm.tsx";
import { ExclamationCircle } from "@svg/icons/ExclamationCircle.tsx";

import {
  Form,
  FormError,
  FormInput,
  FormSubmit,
  FormSuccess,
} from "./PasswordForm";

export default function ChangePassword() {
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, formState, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const [errors, setErrors] = useState<{
    [key: string]: { [key: string]: string };
  }>({});
  const [success, setSuccess] = useState<string | null>(null);
  const { updatePassword } = useAuthentication();
  const { errors: formErrors } = formState;

  const validator = {
    hint: "password.reset.currentPassword",
    field: "password",
    message: "password.reset.incorrectCurrentPassword",
    title: "header.menu.changePassword",
  };

  const onSubmit = async (data: Record<string, string>) => {
    try {
      await updatePassword({
        newPassword: data.newPassword,
        oldPassword: data.password,
      });
      setSuccess("Your password has been updated!");
      setErrors({});
      reset();
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        if (error.message === AuthenticationErrors.INVALID_PASSWORD) {
          setErrors((prev) => ({
            ...prev,
            newPassword: {
              message:
                authenticationErrorMessageMap[
                  error.message as AuthenticationErrors
                ],
            },
          }));
          return;
        }
        if (error.message === AuthenticationErrors.LIMIT_EXCEEDED_EXCEPTION) {
          setErrors((prev) => ({
            ...prev,
            server: {
              message:
                authenticationErrorMessageMap[
                  error.message as AuthenticationErrors
                ],
            },
          }));
          return;
        }
        if (error.message === AuthenticationErrors.INVALID_CREDENTIALS) {
          setErrors({
            password: { message: "Your password is incorrect." },
          });
          return;
        }

        if (error.message === "User is not authenticated") {
          setErrors({
            password: { message: "Your password is incorrect." },
          });
          return;
        }
      }
      // setErrors(errors => ({ ...errors, submit: _error }));
    }
  };

  return (
    <div className={"menu-action-content"}>
      <div className={"menu-action-content-heading"}>
        <div className={"menu-action-content-headline"}>
          {t(validator.title)}
        </div>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormError
            $hidden={_.isEmpty(
              _.omit(errors, [
                "confirmPassword",
                "newPassword",
                "code",
                "password",
              ]),
            )}
          >
            <ul>
              {["submit", "server"].map(
                (f: string) =>
                  errors[f] && (
                    <li key={f}>
                      <ExclamationCircle className="mr-4px inline min-h-14px min-w-14px" />
                      {errors[f].message || t(defaultErrorMessages[f])}
                    </li>
                  ),
              )}
            </ul>
          </FormError>
          <FormSuccess $hidden={!success}>{success}</FormSuccess>
          <div className="my-32px w-full">
            <FormInput
              register={() => register("password", { required: true })}
              className="mb-4 w-full"
              label={t(validator.hint!)}
              error={errors.password}
              type={"password"}
              onChange={() => {
                setErrors({});
                setSuccess(null);
              }}
              defaultValue={""}
            />
            <FormInput
              register={() => register("newPassword", { required: true })}
              className="mb-4 w-full"
              label={t("password.reset.newPassword")}
              error={errors.newPassword}
              type="password"
              onChange={() => {
                setErrors({});
                setSuccess(null);
              }}
            />
            <FormInput
              className=" mb-4 w-full"
              label={t("password.reset.confirmPassword")}
              error={errors.confirmPassword || formErrors.confirmPassword}
              type="password"
              onChange={() => {
                setErrors({});
                setSuccess(null);
              }}
              hint={
                "Passwords must be from 6 to 30 characters long and may include letters, numbers, dashes, and underscores."
              }
              register={() =>
                register("confirmPassword", {
                  required: true,
                  deps: ["newPassword"],
                  validate: (confirmPassword) =>
                    getValues("newPassword") === confirmPassword ||
                    // {
                    // newPassword:
                    t(defaultErrorMessages.confirmPassword),
                  // },
                })
              }
            />
          </div>
          <div className={"w-[152px] mr-auto"}>
            <FormSubmit value={"Save Changes"} />
          </div>
        </Form>
      </div>
    </div>
  );
}
