import { request } from "graphql-request";
import _ from "lodash";

import { parseAuthResult } from "./authentication/amplify/amplify.helpers";
import { Auth } from "./authentication";

interface QueryRequest {
  query: string;
  variables?: Record<string, any>;
  transformer?: ((_: Record<string, any>) => any) | string;
}

type QueryResult = Record<string, any>;

export const doQuery = async ({
  query,
  variables,
  transformer,
}: QueryRequest): Promise<any> => {
  const { accessToken } = parseAuthResult(
    await Auth.currentAuthenticatedUser(),
  );

  const result = await request<QueryResult>(
    import.meta.env.VITE_API_URL,
    query,
    variables,
    {
      Authorization: `Bearer ${accessToken?.jwt}`,
    },
  );
  switch (typeof transformer) {
    case "string":
      return _.get(result, transformer);
    case "function":
      return transformer(result);
  }
  return result;
};

export const doQueryWithApiKey = async ({
  query,
  variables,
  transformer,
}: QueryRequest): Promise<any> => {
  const result = await request<QueryResult>(
    import.meta.env.VITE_API_URL,
    query,
    variables,
    {
      "x-api-key": import.meta.env.VITE_API_KEY,
    },
  );
  switch (typeof transformer) {
    case "string":
      return _.get(result, transformer);
    case "function":
      return transformer(result);
  }
  return result;
};
