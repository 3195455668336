import React, { useEffect, useState } from "react";
import clsx from "clsx";

import Icon from "@atoms/icon/Icon.tsx";

import IconPosterPlay from "../atoms/svg/v2/IconPosterPlay.tsx";

export interface IVideoControls {
  className?: string;
  duration?: number;
  time?: number;
  playing?: boolean;
  muted?: boolean;
  volume?: number;
  seeking?: boolean;
  fullscreen?: boolean;
  autoHide?: boolean;
  onPlayChange?: (v: boolean) => void;
}

const VideoControls: React.FC<IVideoControls> = ({
  className,
  playing: PropPlaying = false,
  seeking: PropSeeking = false,
  onPlayChange,
}) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [seeking, setSeeking] = useState<boolean>(false);
  // const [ currTime, setCurrTime ] = useState(0);

  useEffect(() => setPlaying(PropPlaying), [PropPlaying]);

  useEffect(() => {
    if (PropSeeking !== undefined || PropSeeking === seeking) {
      return;
    }
    setSeeking(PropSeeking);
  }, [seeking, PropSeeking]);

  /**
   * Generic toggle handler since most of our buttons
   * just swap between 2 different values.
   */
  const handleToggleChange = (
    active: boolean,
    stateHandler: (v: boolean) => void,
    propHandler: (v: boolean) => void,
  ) => {
    stateHandler(active);
    propHandler(active);
  };

  return (
    // -- Video Wrapper --
    <div
      className={clsx(
        "relative",
        "flex flex-col justify-between",
        "origin-top",
        "overflow-hidden",
        className,
      )}
    >
      <div
        className={clsx(
          "overflow-hidden",
          "w-full h-full absolute",
          "transition-all duration-200 delay-300 ease-out",
          "flex flex-row justify-center items-center",
          "text-white",
        )}
        onClick={() => handleToggleChange(!playing, setPlaying, onPlayChange!)}
      >
        <Icon
          size="xxl"
          transparent={true}
          padded={false}
          color={"coal"}
          icon={IconPosterPlay}
          className={clsx(
            "drop-shadow",
            "opacity-80 group-hover:opacity-100",
            playing ? "hidden" : "visible",
          )}
        />
      </div>
    </div>
  );
};

export default VideoControls;
