import type { ComponentType, ReactNode } from "react";

import { LeveringIcon } from "../toy-techniques/levering/components/LeveringIcon";
import { TechniquePage } from "../types/Page.types";

import { ConcealingTechniqueSectionBlockTwo } from "./Custom/Concealing/ConcealingTechniqueSectionBlockTwo";
import { ConcealingTechniqueSectionWrapper } from "./Custom/Concealing/ConcealingTechniqueSectionWrapper";
import { ErrandsBlock } from "./Custom/Concealing/ErrandsBlock";
import { LeveringTechniqueSection } from "./Custom/Levering/LeveringTechniqueSection";
import { TopBlockVsAnimation } from "./Custom/Levering/TopBlockVsAnimation";
import { LessButMoreBlock } from "./Custom/ModePlay/LassButMoreBlock/LessButMoreBlock";
import { ModePlayTechniqueSection } from "./Custom/ModePlay/ModePlayTechniqueSection/ModePlayTechniqueSection";
import { FabricBetween } from "./Custom/Wrapping/FabricBetween";
import { WrappingCustomContent } from "./Custom/Wrapping/WrappingCustomContent";
import { WrappingTechniqueSection } from "./Custom/Wrapping/WrappingTechniqueSection";
// TODO: Add the correct icons for each technique page
export const techniquePagesIconMapper: Record<
  TechniquePage,
  ComponentType<any>
> = {
  chilling: LeveringIcon,
  compressing: LeveringIcon,
  concealing: LeveringIcon,
  grinding: LeveringIcon,
  girthing: LeveringIcon,
  levering: LeveringIcon,
  "mode-play": LeveringIcon,
  outstroking: LeveringIcon,
  rippling: LeveringIcon,
  streaming: LeveringIcon,
  transferring: LeveringIcon,
  wrapping: LeveringIcon,
};

export const topBlockCustomContentMapper: Record<TechniquePage, ReactNode> = {
  [TechniquePage.chilling]: <div>Custom top content for chilling</div>,
  [TechniquePage.compressing]: <div>Custom top content for compressing</div>,
  [TechniquePage.concealing]: <ErrandsBlock />,
  [TechniquePage.grinding]: <div>Custom top content for grinding</div>,
  [TechniquePage.girthing]: <div>Custom top content for girthing</div>,
  [TechniquePage.levering]: <TopBlockVsAnimation />,
  [TechniquePage["mode-play"]]: <LessButMoreBlock />,
  [TechniquePage.outstroking]: <div>Custom top content for outstroking</div>,
  [TechniquePage.rippling]: <div>Custom top content for rippling</div>,
  [TechniquePage.streaming]: <div>Custom top content for streaming</div>,
  [TechniquePage.transferring]: <div>Custom top content for transferring</div>,
  [TechniquePage.wrapping]: <FabricBetween />,
};

export const techniqueBlockCustomContentMapper: Record<
  TechniquePage,
  ReactNode
> = {
  [TechniquePage.chilling]: (
    <div>Custom technique block content for chilling</div>
  ),
  [TechniquePage.compressing]: (
    <div>Custom technique block content for compressing</div>
  ),
  [TechniquePage.concealing]: <ConcealingTechniqueSectionWrapper />,
  [TechniquePage.grinding]: (
    <div>Custom technique block content for grinding</div>
  ),
  [TechniquePage.girthing]: (
    <div>Custom technique block content for girthing</div>
  ),
  [TechniquePage.levering]: <LeveringTechniqueSection />,
  [TechniquePage["mode-play"]]: <ModePlayTechniqueSection />,
  [TechniquePage.outstroking]: (
    <div>Custom technique block content for outstroking</div>
  ),
  [TechniquePage.rippling]: (
    <div>Custom technique block content for rippling</div>
  ),
  [TechniquePage.streaming]: (
    <div>Custom technique block content for streaming</div>
  ),
  [TechniquePage.transferring]: (
    <div>Custom technique block content for transferring</div>
  ),
  [TechniquePage.wrapping]: <WrappingTechniqueSection />,
};

export const customBlockContentMapper: Partial<
  Record<TechniquePage, ReactNode>
> = {
  [TechniquePage.chilling]: <div>Custom block content for chilling</div>,
  [TechniquePage.compressing]: <div>Custom block content for compressing</div>,
  [TechniquePage.concealing]: <ConcealingTechniqueSectionBlockTwo />,
  [TechniquePage.grinding]: <div>Custom block content for grinding</div>,
  [TechniquePage.girthing]: <div>Custom block content for girthing</div>,
  [TechniquePage["mode-play"]]: <div>Custom block content for mode-play</div>,
  [TechniquePage.outstroking]: <div>Custom block content for outstroking</div>,
  [TechniquePage.rippling]: <div>Custom block content for rippling</div>,
  [TechniquePage.streaming]: <div>Custom block content for streaming</div>,
  [TechniquePage.transferring]: (
    <div>Custom block content for transferring</div>
  ),
  [TechniquePage.wrapping]: <WrappingCustomContent />,
};
