import React from "react";
import { ArrowLeft, ArrowRight } from "lucide-react";

import useTailwindTheme from "@lib/hooks/useTailwindTheme.ts";
import { cn } from "@lib/utils";

import { Card } from "@/components/ui/Card";
import { Typography } from "@/components/ui/Typography/Typography";

interface NavigationCardProps {
  direction: "prev" | "next";
  title: string;
  description: string;
  icon: React.ReactNode;
  onClick?: () => void;
}

export const NavigationCard: React.FC<NavigationCardProps> = ({
  direction,
  title,
  description,
  icon,
  onClick,
}) => {
  const { isXs } = useTailwindTheme();

  return (
    <Card
      onClick={onClick}
      className="cursor-pointer group relative h-full bg-[#FFD87C] transition-colors flex items-center justify-center lg:flex-col flex-col-reverse min-h-[200px] p-2 md:p-0"
    >
      {direction === "prev" && (
        <div
          className={cn(
            "sm:absolute sm:top-2 sm:left-2 relative w-full sm:w-auto flex justify-start",
            isXs && "mt-2",
          )}
        >
          <div
            className={cn(
              "flex items-center gap-1 border-none hover:text-gray-700 bg-white rounded-md px-2 py-1",
              isXs && "w-full justify-center",
            )}
          >
            <ArrowLeft className="w-4 h-4 text-ui-coal" />
            <Typography variant="bottom-nav-tag">Previous</Typography>
          </div>
        </div>
      )}

      {direction === "next" && (
        <div
          className={cn(
            "sm:absolute sm:top-2 sm:right-2 relative w-full sm:w-auto flex justify-end",
            isXs && "mt-auto",
          )}
        >
          <div
            className={cn(
              "flex items-center gap-1 border-none text-gray-600 hover:text-gray-700 self-end bg-white rounded-md px-2 py-1",
              isXs && "w-full justify-center",
            )}
          >
            <Typography variant="bottom-nav-tag">Next</Typography>

            <ArrowRight className="w-4 h-4 text-ui-coal" />
          </div>
        </div>
      )}

      <div className="text-center px-4 md:px-2 ">
        <div className="md:w-[70px] md:h-[70px] w-[64px] h-[64px] mx-auto sm:mb-4 sm:mt-8 flex items-center justify-center">
          {React.cloneElement(icon as React.ReactElement, {
            className:
              "md:w-[70px] md:h-[70px] w-[64px] h-[64px] flex items-center justify-center sm:my-auto",
          })}
        </div>

        <Typography variant="bottom-nav-title">{title}</Typography>

        <Typography variant="bottom-nav-text">{description}</Typography>
      </div>
    </Card>
  );
};
