import { Amplify } from "aws-amplify";
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  signUp,
  updatePassword,
} from "aws-amplify/auth";

import {
  buildTokenInfo,
  buildUserPayload,
  safeParseAuthOperation,
} from "./amplify.helpers";
import type {
  GetAuthMethodParameters,
  IsomorphicAuthObject,
} from "./amplify.types";

class IsomorphicAuth implements IsomorphicAuthObject {
  constructor() {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
          userPoolClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
        },
      },
    });
  }

  async updatePassword(...params: GetAuthMethodParameters<"updatePassword">) {
    return safeParseAuthOperation(async () => await updatePassword(...params));
  }

  configure(...params: GetAuthMethodParameters<"configure">) {
    return Amplify.configure(...params);
  }

  async confirmSignIn(...params: GetAuthMethodParameters<"confirmSignIn">) {
    return safeParseAuthOperation(async () => await confirmSignIn(...params));
  }

  async currentAuthenticatedUser() {
    return safeParseAuthOperation(async () => {
      const user = await getCurrentUser();
      const { tokens } = await fetchAuthSession();
      const idToken = tokens?.idToken;
      const accessToken = tokens?.accessToken;

      return {
        authenticateUser: buildUserPayload({ user, tokens }),
        idToken: buildTokenInfo(idToken),
        accessToken: buildTokenInfo(accessToken),
      };
    });
  }

  async signIn(...params: GetAuthMethodParameters<"signIn">) {
    return safeParseAuthOperation(async () => await signIn(...params));
  }

  async signUp(...params: GetAuthMethodParameters<"signUp">) {
    return safeParseAuthOperation(async () => await signUp(...params));
  }

  async signOut() {
    return safeParseAuthOperation(async () => await signOut());
  }

  async resetPassword(...params: GetAuthMethodParameters<"resetPassword">) {
    return safeParseAuthOperation(async () => await resetPassword(...params));
  }

  async confirmResetPassword(
    ...params: GetAuthMethodParameters<"confirmResetPassword">
  ) {
    return safeParseAuthOperation(
      async () => await confirmResetPassword(...params),
    );
  }
}

export const Auth = new IsomorphicAuth();
