import { useEffect, useRef, useState } from "react";

import { useIsMobile } from "./useMobile";

// TODO: Should we extract this somewhere else?
import type { NavigationItem } from "@/pages/collections/components/layouts/components/TechniquePageLayout.types";

export function useActiveSection(navItems: NavigationItem[]) {
  const [activeItemId, setActiveItemId] = useState<string>("");
  const isMobile = useIsMobile();
  const observerRef = useRef<IntersectionObserver | null>(null);
  const observedElementsRef = useRef<Set<string>>(new Set());

  // Setup and manage the IntersectionObserver
  useEffect(() => {
    const rootMargin = isMobile ? "-50px 0px -60% 0px" : "-100px 0px -70% 0px";

    // Cleanup previous observer if it exists
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    // Create a new IntersectionObserver
    observerRef.current = new IntersectionObserver(
      (entries) => {
        const visibleEntry = entries.find((entry) => entry.isIntersecting);

        if (visibleEntry) {
          setActiveItemId(visibleEntry.target.id);
        }
      },
      {
        rootMargin,
        threshold: 0.1,
      },
    );

    // Function to observe nav elements
    const observeNavElements = () => {
      navItems.forEach((item) => {
        // Extract the ID from the href (without the #)
        const id = item.href.substring(1);

        // Skip if we've already observed this element
        if (observedElementsRef.current.has(id)) return;

        const element = document.getElementById(id);
        if (element && observerRef.current) {
          observerRef.current.observe(element);
          observedElementsRef.current.add(id);
        }
      });
    };

    // Initial observation attempt
    observeNavElements();

    // Set up MutationObserver to detect when the DOM changes
    const mutationObserver = new MutationObserver((mutations) => {
      // Only look for newly added nodes
      const hasAddedNodes = mutations.some(
        (mutation) =>
          mutation.type === "childList" && mutation.addedNodes.length > 0,
      );

      if (hasAddedNodes) {
        observeNavElements();
      }
    });

    // Start observing changes to the document body
    mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      mutationObserver.disconnect();
      observedElementsRef.current.clear();
    };
  }, [navItems, isMobile]);

  return activeItemId;
}
