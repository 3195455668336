import * as React from "react";

import { Typography } from "@components/ui/Typography";

interface TechniquePageHeaderProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

export const TechniquePageHeader = ({
  title,
  description,
  icon,
}: TechniquePageHeaderProps) => {
  return (
    <div className="flex items-center flex-row gap-2 md:gap-4 mb-[16px] md:mb-[24px] global-section-padding-x ">
      {icon}

      <div className="flex flex-col gap-1">
        <Typography variant="page-title">{title}</Typography>
        <Typography variant="page-subtitle">{description}</Typography>
      </div>
    </div>
  );
};
