import React, { useEffect, useRef } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios, { type AxiosError } from "axios";
import axiosRetry from "axios-retry";

import LoadingInterstitial from "@components/LoadingInterstitial";

import {
  useAuthenticatedSession,
  useAuthenticatedUser,
  useAuthentication,
} from "@lib/authentication";
import useAnalytics from "@lib/hooks/useAnalytics";
import LoginForm, { LoginFormModes } from "@pages/Auth/LoginForm";
import PasswordForm, { PasswordFormMode } from "@pages/Auth/PasswordForm";
import { AnalyticsEvent } from "@root/constants";

import { FormContainer } from "./components/FormContainer";

axiosRetry(axios, {
  retries: 5, // Number of retries
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    // Retry on network errors and 4xx responses
    const status = error?.response?.status || 0;
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || status >= 400;
  },
});

const AuthPage = ({ children }: { children?: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading: isLoadingAuthenticatedUser } =
    useAuthenticatedUser();
  const { idToken } = useAuthenticatedSession();
  const formRef = useRef<HTMLFormElement>(null);
  const tokenFieldRef = useRef<HTMLInputElement>(null);
  const { analytics } = useAnalytics();
  const params = useParams();
  const { signOut } = useAuthentication();

  useEffect(() => {
    if (location.pathname === "/logout") {
      signOut().then(() => {
        localStorage.removeItem("si");
        localStorage.removeItem("ck");
        if (import.meta.env.VITE_ENV !== "local") {
          window.location.href = `${import.meta.env.VITE_OMGYES_SERVER_URL}/collections/logout`;
        } else {
          navigate("/");
        }
      });
    }
  }, [location, navigate, signOut]);

  useEffect(() => {
    const pageName = location.pathname.replace(/^\//, ""); // Removes the leading slash
    analytics.page({ page: `${pageName}_page`, props: params });
  }, []);

  const onSuccess = async () => {
    const searchParams = new URLSearchParams(location.search);
    const redirectUri = `${
      import.meta.env.VITE_OMGYES_SERVER_URL
    }/members/home?${searchParams.toString()}`;

    if (
      import.meta.env.VITE_ENV !== "local" &&
      isAuthenticated &&
      tokenFieldRef?.current
    ) {
      // Always log into legacy stack
      tokenFieldRef.current!.value = idToken || "";

      try {
        await axios.get(
          import.meta.env.VITE_OMGYES_SERVER_URL +
            "/collections-api/authenticate",
          {
            params: {
              redirect_uri: redirectUri,
              token: idToken,
            },
          },
        );
      } catch (e) {
        const axiosError = e as AxiosError;
        const errorDetails = {
          code: axiosError.code,
          message: axiosError.message,
          name: axiosError.name,
          status: axiosError.status,
          headers: axiosError.config?.headers,
          data: axiosError.config?.data,
          config: axiosError.config,
          affectedComponent: "AuthPage",
        };

        analytics.track({
          event: AnalyticsEvent.AUTH_ERROR,
          props: { ...params, ...errorDetails },
        });

        console.error("Error authenticating user on collections ", e);
        navigate("/logout");
      }
    }
  };

  if (location.pathname === "/logout" || isLoadingAuthenticatedUser)
    return <LoadingInterstitial />;

  if (
    isAuthenticated &&
    !["/logout", "/change-password"].includes(location.pathname)
  ) {
    return (
      <Navigate to={`/home${location.search}`} state={{ fromLogin: true }} />
    );
  }
  const authMode = location.pathname.slice(1);

  if (LoginFormModes.includes(authMode)) {
    const loginFormMode = authMode as "login" | "forgot-password";
    if (localStorage.getItem("si")) {
      return (
        <>
          <LoadingInterstitial />
          <LoginForm onSuccess={onSuccess} mode={loginFormMode} />
        </>
      );
    }
    return (
      <FormContainer>
        <LoginForm onSuccess={onSuccess} mode={loginFormMode} />
        <form
          className="hidden"
          ref={formRef}
          action={
            import.meta.env.VITE_OMGYES_SERVER_URL +
            "/collections-api/authenticate"
          }
          method="GET"
        >
          <input ref={tokenFieldRef} name="token" type="hidden" />
        </form>
      </FormContainer>
    );
  }

  if (PasswordFormMode.includes(authMode)) {
    const passwordFormMode = authMode as "reset-password" | "change-password";
    const searchParams = new URLSearchParams(location.search);
    return (
      <FormContainer>
        <PasswordForm
          mode={passwordFormMode}
          email={searchParams.get("email")}
          code={searchParams.get("code")}
          callback={onSuccess}
        />
      </FormContainer>
    );
  }

  return (
    <div className="min-h-screen flex p-16 sm:px-30 bg-auth-bg bg-auth-gradient">
      {children}
    </div>
  );
};

export default AuthPage;
