import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import { DynamicIcon, type IconName } from "lucide-react/dynamic";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "cursor-pointer font-mabry-pro-regular inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 rounded-full disabled:text-ui-coal text-white",
  {
    variants: {
      variant: {
        default: "font-medium bg-cta hover:bg-cta-hover disabled:bg-ui-garlic",
        secondary:
          "bg-white text-cta border-cta border hover:border-cta-hover hover:text-cta-hover disabled:border-ui-coal",
        ghost: "text-cta hover:text-cta-hover hover:bg-cta-opacity-3",
        link: "text-cta hover:bg-transparent hover:text-cta-hover hover:underline bg-transparent",
        icon: "text-cta hover:text-cta-hover hover:bg-cta-opacity-3",
      },
      size: {
        default: "px-4 py-2",
        sm: "px-2 py-1 text-xs",
        lg: "h-[54px] px-10 font-bold text-xl",
        icon: "h-[32px] w-[32px]",
        link: "px-0",
        full: "h-10 px-6 w-full",
      },
    },
    defaultVariants: {
      size: "default",
      variant: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  startIcon?: IconName;
  endIcon?: IconName;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      isLoading = false,
      children,
      startIcon,
      endIcon,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    const content = (
      <>
        {isLoading && <Loader2 className="animate-spin absolute" />}
        <div
          className={`flex gap-2 items-center ${isLoading ? "opacity-0" : "opacity-100"}`}
        >
          {startIcon && <DynamicIcon name={startIcon} />}
          {children}
          {endIcon && <DynamicIcon name={endIcon} />}
        </div>
      </>
    );

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
        disabled={isLoading || props.disabled}
      >
        {asChild ? children : content}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
