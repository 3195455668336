import type { RefObject } from "react";
import { useEffect, useState } from "react";

import { useActiveSection } from "@/hooks/useActiveSection";
import type { NavigationItem } from "@/pages/collections/components/layouts/components/TechniquePageLayout.types";
import { scrollToAnchor } from "@/pages/collections/utils/Page.utils";

export function useMobileNavigation(
  navItems: NavigationItem[],
  currentPath: string = "/",
  scrollContainerRef: RefObject<HTMLDivElement>,
) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const activeItemId = useActiveSection(navItems);

  const checkShadows = () => {
    if (!scrollContainerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
    setShowLeftShadow(scrollLeft > 1);
    setShowRightShadow(scrollLeft < scrollWidth - clientWidth - 1);
  };

  // Set active index based on current path and vertical scroll position
  useEffect(() => {
    // First try to set based on active section from scroll position
    if (activeItemId) {
      const sectionHref = `#${activeItemId}`;
      const index = navItems.findIndex((item) => item.href === sectionHref);
      if (index !== -1) {
        setActiveIndex(index);
        return;
      }
    }

    // Fallback to current path if no section is active
    const index = navItems.findIndex((item) => item.href === currentPath);
    if (index !== -1) {
      setActiveIndex(index);
    }
  }, [currentPath, navItems, activeItemId]);

  // Scroll to active item
  useEffect(() => {
    // Navbar scroll to active item, since it is horizontally based
    const timeoutId = setTimeout(() => {
      const activeItem = document.getElementById(`nav-item-${activeIndex}`);
      if (activeItem && scrollContainerRef.current) {
        const containerWidth = scrollContainerRef.current.offsetWidth;
        const itemLeft = activeItem.offsetLeft;
        const itemWidth = activeItem.offsetWidth;

        // Center the active item
        scrollContainerRef.current.scrollLeft =
          itemLeft - containerWidth / 2 + itemWidth / 2;

        // Check shadows after scrolling
        checkShadows();
      }
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [activeIndex]);

  // Check for shadows on mount and scroll
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", checkShadows);
      // Initial check
      checkShadows();

      return () => container.removeEventListener("scroll", checkShadows);
    }
  }, []);

  // Handle window resize
  useEffect(() => {
    window.addEventListener("resize", checkShadows);
    return () => window.removeEventListener("resize", checkShadows);
  }, []);

  const handleItemClick = (href: string, index: number) => {
    setActiveIndex(index);
    scrollToAnchor(href);
  };

  return {
    activeIndex,
    showLeftShadow,
    showRightShadow,
    handleItemClick,
  };
}
