import { useCallback, useMemo, useRef } from "react";
import { TfiPlus } from "react-icons/tfi";
import { simpleAlert, simplePrompt } from "react-simple-dialogs";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
ModuleRegistry.registerModules([AllCommunityModule]);

import type { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useRecoilState } from "recoil";

import type { Product } from "@pages/admin/ProductCatalog/store.ts";
import {
  productSummaries,
  selectedProductState,
} from "@pages/admin/ProductCatalog/store.ts";

import { Button } from "./components/Button";
import { Toolbar } from "./components/Toolbar";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export default function ProductsList() {
  const gridRef = useRef(null);
  const [products, setProducts] = useRecoilState(productSummaries);
  const [productId, setProductId] = useRecoilState(selectedProductState);

  const addNewProduct = useCallback(async () => {
    const newProductId = await simplePrompt({
      title: "Create new product",
      message: "Enter a unique slug for the new product",
      confirmLabel: "Create",
      cancelLabel: "Cancel",
      inputLabel: "Slug",
    });
    if (!newProductId) {
      return;
    }

    if (products.find(({ id }) => id === newProductId)) {
      await simpleAlert({
        message: "Product slug must be unique",
        closeLabel: "Close",
        title: "Error: Create new product",
      });
      return;
    }

    setProducts((products) => [
      ...products,
      {
        id: newProductId,
        pricings: [],
        localizations: [],
        entitlements: [],
      },
    ]);
    setProductId(newProductId);
  }, [products]);

  const columnDefs: ColDef<Product>[] = useMemo(
    () => [
      {
        headerName: "Product",
        field: "id",
      },
    ],
    [],
  );

  return (
    <div className="ag-theme-quartz w-full h-full">
      <Toolbar>
        <Button onClick={addNewProduct}>
          <TfiPlus />
        </Button>
      </Toolbar>
      <AgGridReact<Product>
        ref={gridRef}
        // @ts-ignore
        columnDefs={columnDefs}
        rowData={products}
        rowSelection="single"
        autoSizeStrategy={{ type: "fitCellContents" }}
        onSelectionChanged={({ api }) => {
          setProductId(api.getSelectedRows()[0]?.id);
        }}
        onRowDataUpdated={({ api }) =>
          api.forEachNode((node) =>
            node.setSelected(node.data?.id === productId),
          )
        }
      />
    </div>
  );
}
