import { Container } from "@components/ui/Container";
import { Quote } from "@components/ui/Quote";
import { Typography } from "@components/ui/Typography";
import VideoPlayer from "@components/ui/VideoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "@components/ui/VideoPlayer/VideoPlayer.context";

import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type {
  AnimationVideoBlockFields,
  CopyBlockFields,
} from "@pages/collections/types/Page.types";

import { WrappingSections } from "./Wrapping.types";

import {
  findComponentsByName,
  findComponentsByNameInSection,
  findComponentsByProperty,
  splitStringWithBasicQuote,
} from "@/utils/componentUtils";

export const FabricBetween = () => {
  const { sections } = useTechniquePagesContext();

  const fabricBetweenSection = findComponentsByName(
    sections,
    WrappingSections.fabricBetween,
  );

  const [fabricBetween] = findComponentsByNameInSection<CopyBlockFields>(
    fabricBetweenSection,
    WrappingSections.fabricBetween,
    "copyBlock",
  );

  const [text, quote] = splitStringWithBasicQuote(fabricBetween.fields?.copy);

  const animationVideoBlock =
    findComponentsByNameInSection<AnimationVideoBlockFields>(
      fabricBetweenSection,
      WrappingSections.fabricBetween,
      "animationVideoBlock",
    );

  const animationsTitle = findComponentsByProperty<{ text: string }>(
    fabricBetweenSection,
    "cssClass",
    "animation-title",
  );

  const sectionFinalCopy = findComponentsByProperty<{ text: string }>(
    fabricBetweenSection,
    "cssClass",
    "copy",
  );

  return (
    <VideoPlayerProvider>
      <Container
        fullWidth
        className="flex flex-col px-4 sm:px-6 md:px-10 lg:px-14 gap-7 xl:flex-row"
      >
        <Container className="flex flex-col gap-1" fullWidth>
          <Typography variant="paragraph-title">
            {fabricBetween.fields?.heading}
          </Typography>
          <Typography variant="paragraph">
            <span
              dangerouslySetInnerHTML={{
                __html: text || "",
              }}
            />
          </Typography>

          <Quote className="mt-1" text={quote || ""} />
        </Container>

        <Container
          className="flex flex-col py-6 sm:py-7 sm:px-5 md:px-6 md:py-8 sm:bg-[#EFECE7] sm:rounded-xl"
          fullWidth
        >
          <Container
            className="flex flex-col sm:flex-row gap-5 sm:gap-6"
            fullWidth
          >
            <Container className="flex flex-col gap-2" fullWidth>
              <Typography variant="animation-title" className="text-center">
                {animationsTitle[0].text}
              </Typography>
              <VideoPlayer
                autoPlay
                muted
                height="auto"
                aspectRatio="aspect-auto"
                loop={animationVideoBlock[0].fields?.video.loop}
                id={animationVideoBlock[0].fields?.video.id || ""}
                url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlock[0].fields?.video.url}`}
              />
            </Container>
            <Container
              className="flex flex-col-reverse sm:flex-col gap-2"
              fullWidth
            >
              <Typography variant="animation-title" className="text-center">
                {animationsTitle[1].text}
              </Typography>
              <VideoPlayer
                autoPlay
                muted
                height="auto"
                aspectRatio="aspect-auto"
                loop={animationVideoBlock[1].fields?.video.loop}
                id={animationVideoBlock[1].fields?.video.id || ""}
                url={`${import.meta.env.VITE_VIDEO_URL}${animationVideoBlock[1].fields?.video.url}`}
              />
            </Container>
          </Container>

          <Typography variant="body" className="text-center mt-5">
            {sectionFinalCopy[0].text}
          </Typography>
        </Container>
      </Container>
    </VideoPlayerProvider>
  );
};
