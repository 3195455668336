import { useEffect, useMemo, useState } from "react";
import { AccordionContent, AccordionTrigger } from "@radix-ui/react-accordion";

import { Accordion, AccordionItem } from "@components/ui/Accordion";
import { Container } from "@components/ui/Container";
import { Typography } from "@components/ui/Typography";
import VideoPlayer from "@components/ui/VideoPlayer/VideoPlayer";
import { VideoPlayerProvider } from "@components/ui/VideoPlayer/VideoPlayer.context";

import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";
import type {
  AnimationVideoBlockFields,
  BasicVideoBlockFields,
  CopyBlockFields,
} from "@pages/collections/types/Page.types";

import SectionHeading from "../../SectionHeading/SectionHeading";
import { VideoWithCaption } from "../../VideoWithCaption";

import pauseIcon from "./PauseIcon.svg";
import playIcon from "./PlayIcon.svg";

import {
  findComponentsByName,
  findComponentsByNameInSection,
  findComponentsByProperty,
} from "@/utils/componentUtils";

export const WhatToUse = () => {
  const { sections } = useTechniquePagesContext();
  const [openItem, setOpenItem] = useState<string>("");

  const [whatToUseTitle] = findComponentsByProperty<{ text: string }>(
    sections,
    "cssClass",
    "section-header__techniques",
  );

  const [whatToUseIntro] = findComponentsByNameInSection<CopyBlockFields>(
    sections,
    "whatToUse",
    "copyBlock",
  );

  const whatToUseCopyBlocks = useMemo(
    () =>
      findComponentsByName<CopyBlockFields>(
        findComponentsByProperty<CopyBlockFields>(
          sections,
          "cssClass",
          "menu-container",
        ),
        "copyBlock",
      ).filter((block) => block.fields?.copy !== ""),
    [sections],
  );

  const animationVideos =
    findComponentsByNameInSection<AnimationVideoBlockFields>(
      sections,
      "whatToUse",
      "animationVideoBlock",
    );

  const activeItemIndex = useMemo(
    () =>
      whatToUseCopyBlocks.findIndex(
        (copyBlock) => copyBlock.fields?.heading === openItem,
      ),
    [whatToUseCopyBlocks, openItem],
  );

  const basicVideoBlocks = findComponentsByNameInSection<BasicVideoBlockFields>(
    sections,
    "whatToUse",
    "basicVideoBlock",
  );

  useEffect(() => {
    setOpenItem(whatToUseCopyBlocks[0].fields?.heading || "");
  }, [whatToUseCopyBlocks]);

  return (
    <VideoPlayerProvider>
      <Container fullWidth>
        <div className="global-section-padding-x">
          <SectionHeading title={whatToUseTitle?.text} />
        </div>
        <div className="bg-[#EFECE7] flex flex-col gap-5 py-7 lg:py-8 sm:mx-5 sm:rounded-xl md:mx-10">
          <div className="w-full global-section-padding-x md:px-5">
            <Typography>
              <span
                dangerouslySetInnerHTML={{
                  __html: whatToUseIntro?.fields?.copy || "",
                }}
              />
            </Typography>
          </div>

          <div className="flex flex-col gap-5 md:grid md:grid-cols-2 md:gap-5 py-2 global-section-padding-x md:px-5">
            <Accordion
              type="single"
              className="w-full"
              defaultValue={whatToUseCopyBlocks[0].fields?.heading}
              onValueChange={(value) => setOpenItem(value)}
              value={openItem}
            >
              <div className="flex flex-col gap-3">
                {whatToUseCopyBlocks.map((block, index) => (
                  <AccordionItem
                    className="p-4 data-[state=open]:bg-[#F7F7F7] rounded-xl flex flex-row items-center gap-4"
                    key={index}
                    value={block.fields?.heading || ""}
                  >
                    <img
                      className="self-start"
                      src={
                        openItem === block.fields?.heading
                          ? pauseIcon
                          : playIcon
                      }
                      alt="Concealing Icon"
                    />
                    <div>
                      <AccordionTrigger className="w-full text-left">
                        <Typography variant="technique-title">
                          {block.fields?.heading}
                        </Typography>
                      </AccordionTrigger>
                      <AccordionContent className="mt-1">
                        <Typography>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: block.fields?.copy || "",
                            }}
                          />
                        </Typography>
                      </AccordionContent>
                    </div>
                  </AccordionItem>
                ))}
              </div>
            </Accordion>

            <VideoPlayer
              aspectRatio="aspect-auto"
              controls={false}
              autoPlay
              isAnimation
              id={animationVideos[activeItemIndex]?.fields?.video.id || ""}
              url={`${import.meta.env.VITE_VIDEO_URL}${animationVideos[activeItemIndex]?.fields?.video.url}`}
              loop={animationVideos[activeItemIndex]?.fields?.video.loop}
            />
          </div>

          <div className="gap-5 flex flex-col global-section-padding-x sm:grid sm:grid-cols-2 sm:gap-4 md:px-5">
            {basicVideoBlocks.map((basicVideoBlock) => (
              <VideoWithCaption
                key={basicVideoBlock.fields?.video.id}
                video={basicVideoBlock}
              />
            ))}
          </div>
        </div>
      </Container>
    </VideoPlayerProvider>
  );
};
