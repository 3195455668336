import { useState } from "react";

import { Typography } from "@components/ui/Typography";

import { cn } from "@lib/utils";
import { useTechniquePagesContext } from "@pages/collections/context/TechniquePage.context";

type BaseComponent = {
  type?: string;
  cssClass?: string[];
  children?: BaseComponent[];
  text?: string;
  lazyBgImage?: string;
};

type MappedTextureImage = {
  src: string;
  title: string;
  description: string;
};

export const TexturesPanel = () => {
  const { sections } = useTechniquePagesContext();
  const [activeTexture, setActiveTexture] = useState<string | null>(null);

  const handleSetActiveTexture = (title: string) => {
    if (activeTexture === title) {
      return setActiveTexture(null);
    }
    setActiveTexture(title);
  };

  const textures = sections.find(
    (section) => section.component === "greatestHitsBlock",
  )?.fields;

  if (!textures) return null;

  const [heading, subheading] = textures.children[0].children[1].children;

  const images = textures.children[0].children[1].children[2];

  const mappedImages: MappedTextureImage[] = images.children.map(
    (image: BaseComponent) => {
      return {
        src: image.lazyBgImage,
        title: image.children?.[0].children?.[0].text,
        description: image.children?.[0].children?.[1].text,
      };
    },
  );

  return (
    <div className="px-4 sm:px-6 md:px-10 lg:px-14">
      <div className="px-6 flex flex-col gap-4 mb-8 text-center">
        <Typography variant="texture-panel-section-title">
          {heading.text}
        </Typography>
        <Typography variant="texture-panel-section-headline">
          {subheading.text}
        </Typography>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {mappedImages.map((image) => (
          <div
            className="relative"
            key={image.title}
            onClick={() => handleSetActiveTexture(image.title)}
          >
            <div
              style={{
                backgroundImage: `url(${import.meta.env.VITE_IMAGE_URL}${image.src})`,
              }}
              className={cn(
                "bg-cover bg-center h-[200px]",
                activeTexture === image.title &&
                  "bg-blend-overlay bg-[#f6f3f0]/50 blur-[10px]",
              )}
            />
            {activeTexture === image.title && (
              <div className="flex flex-col justify-center items-center h-full absolute top-0 left-[50%] translate-x-[-50%] max-w-[200px]">
                <Typography
                  variant="texture-panel-item-title"
                  className="text-center mb-4"
                >
                  {image.title}
                </Typography>
                <Typography
                  variant="texture-panel-item-description"
                  className="text-center mb-4"
                  customColor="#292625"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: image.description }}
                  />
                </Typography>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
