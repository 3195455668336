import { useState } from "react";
import { Play, X } from "lucide-react";

import { cn } from "@lib/utils";

import { Button } from "../Button";
import { Tag } from "../Tag";
import { ExplicitTagIcon } from "../Tag/ExplicitTagIcon";
import { Typography } from "../Typography";

interface VideoPosterProps {
  onClick: () => void;
  videoDuration?: number | string;
  posterUrl?: string;
  explicit?: boolean;
  active?: boolean;
  maxWidth?: number;
  className?: string;
  rounded?: boolean;
}

const VideoPoster = ({
  posterUrl,
  onClick,
  explicit,
  maxWidth,
  videoDuration,
  active = false,
  className,
  rounded,
}: VideoPosterProps) => {
  const [showExplicitWarning, setShowExplicitWarning] = useState<boolean>();

  const handleClick = () => {
    if (explicit && !showExplicitWarning) {
      return setShowExplicitWarning(true);
    }

    onClick();
  };

  return (
    <div
      className={cn(
        `aspect-video overflow-hidden ${maxWidth && `max-w-[${maxWidth}px]`}  w-full ${active && "border-[3px] border-[#CE5317]"} `,
        rounded && "rounded-xl",
        className,
      )}
    >
      <div
        onClick={handleClick}
        style={{ backgroundImage: `url(${posterUrl})` }}
        className={cn(
          `bg-cover bg-no-repeat relative h-full w-full ${!showExplicitWarning && "lg:hover:scale-[1.03] transition-transform duration-300 ease-out"}`,
        )}
      >
        {!showExplicitWarning ? (
          <>
            <div className="absolute top-3 right-3">
              {explicit && <Tag variant="explicit">Explicit</Tag>}
            </div>

            <div className="absolute left-4 bottom-4 flex items-center gap-3 opacity-80">
              <button>
                <div className="h-10 w-10 flex items-center justify-center rounded-full bg-white/90 text-gray-900 group-hover:bg-white">
                  <Play className="h-4 w-4" />
                </div>
              </button>

              {active ? (
                <Typography variant="headline" customColor="white">
                  Now playing
                </Typography>
              ) : (
                <Typography variant="caption" customColor="white">
                  {videoDuration}
                </Typography>
              )}
            </div>
          </>
        ) : (
          <div className="h-full w-full bg-white">
            <Button
              variant="ghost"
              size="icon"
              className="absolute top-0 right-12px"
              onClick={() => setShowExplicitWarning(false)}
            >
              <X />
            </Button>

            <div className="h-full flex flex-col justify-center items-center py-1 px-10">
              <div className="flex flex-col items-center my-auto">
                <Typography variant="paragraph" customColor="text-white">
                  Heads up! This is a demonstration video, so it has explicit
                  content. All of these videos have an &quot;explicit&quot; tag
                  or are marked with this symbol:
                </Typography>
                <ExplicitTagIcon className="w-6 h-6" />
              </div>
              <Button onClick={onClick}>Ok, let&apos;s go!</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoPoster;
