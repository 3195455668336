import { useIsMobile } from "@hooks/useMobile.tsx";

import AppPageHeader from "@components/common/AppPageHeader.tsx";
import PageFooter from "@components/common/PageFooter.tsx";
import SimulationComponent from "@components/SimulationComponent.tsx";

const SimulationPage = () => {
  const isMobile = useIsMobile();

  return (
    <div className="simulation-page">
      <AppPageHeader showLogo={true} previousPage={"/home"} />

      <SimulationComponent
        simulationId="amber"
        height={isMobile ? "400px" : "600px"}
        className="min-h-[400px] md:min-h-[600px]"
      />

      <PageFooter />
    </div>
  );
};

export default SimulationPage;
