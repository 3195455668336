import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import { useGetTechniquePageContent } from "../queries/useGetTechniquePageContent";
import type { Sections, TechniquePage } from "../types/Page.types";

type TechniquePagesContextType = {
  sections: Sections;
  techniquePageId?: TechniquePage;
};

const TechniquePagesContext = createContext<TechniquePagesContextType | null>(
  null,
);

export const TechniquePagesProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{ id: TechniquePage }>();

  const {
    data: { sections },
  } = useGetTechniquePageContent({
    id,
    // TODO: Should get it dynamically
    locale: "en",
  });

  return (
    <TechniquePagesContext.Provider value={{ sections, techniquePageId: id }}>
      {children}
    </TechniquePagesContext.Provider>
  );
};

export const useTechniquePagesContext = () => {
  const data = useContext(TechniquePagesContext);

  if (!data) {
    throw new Error(
      "useTechniquePagesContext must be used within TechniquePagesProvider",
    );
  }

  return data;
};
