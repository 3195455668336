import React, { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { CookiesService } from "@services/CookiesService";
import type { CookieSetOptions } from "universal-cookie";
import { v4 as uuid } from "uuid";

import { useAuthenticatedUser } from "@lib/authentication";
import { useLocale } from "@lib/contexts/LocaleContext";
import useAnalytics from "@lib/hooks/useAnalytics";
import { doQueryWithApiKey } from "@lib/query";
import { AnalyticsEvent } from "@root/constants";

import { CREATE_EVENT } from "../../layouts/PageLayout/PageLayout.mutation";

export const NMH_LAUNCH_DATE = new Date("2024-12-12");

interface NmhContextType {
  hasNmhAccess: boolean;
  isLoading: boolean;
}

const NmhContext = createContext<NmhContextType | undefined>(undefined);

export const NmhProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [hasNmhAccess, setHasNmhAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [, setCookie] = useCookies();
  const { authenticatedUser } = useAuthenticatedUser();
  const { analytics } = useAnalytics();
  const locale = useLocale();

  useEffect(() => {
    let isMounted = true;

    const sendEvent = async (event: string, id: string, email: string) => {
      try {
        await doQueryWithApiKey({
          query: CREATE_EVENT,
          variables: {
            data: {
              id: uuid(),
              event,
              user: {
                connectOrCreate: {
                  where: { id },
                  create: { id, email },
                },
              },
            },
          },
        });
      } catch (error) {
        console.error("Error sending event:", error);
      }
    };

    const initialize = async () => {
      try {
        // Check URL parameter first
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("ug") === "nmh") {
          if (isMounted) {
            setHasNmhAccess(true);
            setIsLoading(false);
          }
          return;
        }

        // If no user, no access
        if (!authenticatedUser) {
          if (isMounted) {
            setHasNmhAccess(false);
            setIsLoading(false);
          }
          return;
        }

        // Check account creation date and entitlements
        const dateCreated =
          authenticatedUser.customOmgYesProperties.dateCreated;
        const dateAccountCreated = dateCreated ? new Date(dateCreated) : null;
        const preNmhAccount =
          dateAccountCreated && dateAccountCreated < NMH_LAUNCH_DATE;
        const entitlements =
          authenticatedUser.customOmgYesProperties?.entitlements || [];
        const ownsEverything = entitlements.length >= 3;

        // Check locale and entitlements
        if (!ownsEverything || !locale?.startsWith("en")) {
          if (isMounted) {
            setHasNmhAccess(false);
            setIsLoading(false);
          }
          return;
        }

        // Check NMH access events
        const events = authenticatedUser.customOmgYesProperties.events || [];
        const firstNmhAccess = events.find(
          (x) => x.event === "ACCESS_NMH",
        )?.createdAt;
        const controlNmhExperiment = events.find(
          (x) => x.event === "CONTROL_NMH_EXPERIMENT",
        )?.createdAt;

        const hasSeenNmh = Boolean(firstNmhAccess);
        const isNewUser = !preNmhAccount && !controlNmhExperiment;

        // Set cookie if needed
        if ((hasSeenNmh || isNewUser) && isMounted) {
          CookiesService.setNmhPromoCookie(
            (name: string, value: string, options?: CookieSetOptions) =>
              setCookie(name as any, value, options),
          );
        }

        // Track first access if needed
        if (
          controlNmhExperiment &&
          !firstNmhAccess &&
          authenticatedUser.email
        ) {
          await sendEvent(
            "ACCESS_NMH",
            authenticatedUser.sub || "",
            authenticatedUser.email,
          );

          await analytics.track({
            event: AnalyticsEvent.FIRST_NMH_ACCESS,
            props: {
              email: authenticatedUser.email,
              first_access_date: Date.now().toString(),
              pre_nmh_account: String(preNmhAccount),
              locale,
            },
          });
        }

        // Set final state
        if (isMounted) {
          setHasNmhAccess(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error initializing NMH access:", error);
        if (isMounted) {
          setHasNmhAccess(false);
          setIsLoading(false);
        }
      }
    };

    initialize();

    return () => {
      isMounted = false;
    };
  }, [authenticatedUser, locale]);

  const value = { hasNmhAccess, isLoading };
  return <NmhContext.Provider value={value}>{children}</NmhContext.Provider>;
};

export const useNmh = () => {
  const context = useContext(NmhContext);
  if (context === undefined) {
    throw new Error("useNmh must be used within a NmhProvider");
  }
  return context;
};
