import React from "react";

import { cn } from "@/lib/utils";

export const Card = ({
  children,
  className,
  onClick,
  fullWidth,
}: {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={cn(
        "rounded-lg bg-card",
        className,
        fullWidth && "w-full rounded-none",
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
