import type { RefObject } from "react";
import React from "react";
import clsx from "clsx";

import type { ContainerProps } from "@atoms/container/Container";
import Container from "@atoms/container/Container";

export interface IAspectRatioContainer {
  aspectW: number;
  aspectH: number;
  maxWidth?: number;
  containerRef?: RefObject<HTMLDivElement>;
}

/**
 * Wrapper for a container that maintians a specific aspect ratio.
 *
 * For details on how it works, see:
 *  - @see {@link https://dabblet.com/gist/2590942}
 *  - @see {@link https://stackoverflow.com/questions/1495407/maintain-the-aspect-ratio-of-a-div-with-css}
 *  - @see {@link https://github.com/tailwindlabs/tailwindcss-aspect-ratio}
 *
 * @param {number} [aspectW]
 *  - Width of aspect ratio
 * @param {number} [aspectH]
 *  - Height of Aspect Ratio
 * @param {React.ReactNode} children
 *  - Children to be contained
 * @param {number} [maxWidth]
 *  - Optional max width of container (default: 100%)
 *
 * @returns {React.FC<IAspectRatioContainer>}
 */
const AspectRatioContainer: React.FC<
  IAspectRatioContainer & ContainerProps
> = ({
  aspectW,
  aspectH,
  maxWidth,
  children,
  containerRef,
  className,
  style,
  ...props
}) => (
  <Container
    className={clsx("w-full", className)}
    style={{ maxWidth: maxWidth && `${maxWidth}px`, ...style }}
    ref={containerRef}
    {...props}
  >
    {/* Actual box to set the aspect ratio of the content */}
    <div
      className={clsx("h-full w-full", "aspect-container")}
      style={{ paddingBottom: `${(aspectH / aspectW) * 100}%` }}
    >
      {/* Content to be contained */}
      {children}
    </div>
  </Container>
);

export default AspectRatioContainer;
